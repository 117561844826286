import { ComponentType, FC, ReactNode, useMemo, useState } from "react";
import {
  Avatar,
  Button,
  Col,
  DatePicker,
  Form,
  Input,
  Popover,
  Row,
  Select,
  SelectProps,
  Switch,
  Tag,
  Typography,
  Upload,
  message,
} from "antd";
import {
  EmailIcon,
  FaxIcon,
  GroupIcon,
  IdCardIcon,
  LocationIcon,
  ManagerIcon,
  OutBoxIcon,
  QuickLinkIcon,
  StarIcon,
  TelephoneIcon,
  WebIcon,
} from "../../../assets/images/icons";
import { ClinicData } from "../../../common/interfaces/ClinicData";
import { HomeTab } from "../../../common/enums";
import {
  FormatAddress,
  FormatDisplayDate,
  ParseDayJsDate,
  FormatMask,
} from "../../../common/utilities/format";
import { DownOutlined } from "@ant-design/icons";
import type { RcFile } from "antd/es/upload/interface";
import { useGetGroupSchemaExtensionIdQuery } from "../../../services/groups";
import {
  useCreateClinicMutation,
  useDeleteClinicMutation,
  useUpdateClinicMutation,
} from "../../../services/clinics";
import dayjs from "dayjs";
import { MaskedInput } from "antd-mask-input";
import {
  getInitials,
  handleImageUpload,
  mergeFormsAndNestAdditionalData,
  parseOnlyBase64ImageData,
} from "../../../common/utilities/helper";
import "./ClinicDetails.style.scss";
import { FormInstance, Rule } from "antd/es/form";
import {
  faxPatternRegex,
  npiPatternRegex,
  phonePatternRegex,
  tinPatternRegex,
  urlPatternRegex,
} from "../../../common/utilities/validator";
import { useDebounced } from "../../../hooks/useDebounced";
import { useSearchProvidersQuery } from "../../../services/providers";
import {
  useGetCitiesQuery,
  useGetCountriesQuery,
  useGetStatesQuery,
  useGetZipsQuery,
} from "../../../services/address";
import {
  FormInputField,
  getInitialValue,
  getKey,
} from "../../../common/utilities/formUtils";
import { useGetSpecialitiesQuery } from "../../../services/specialities";
import useRolesPermissionsAccess from "../../../hooks/useRolesPermissionsAccess";
import { useIsAuthenticated } from "@azure/msal-react";
import useResponsive from "../../../hooks/useResponsive";
import { useGetQuickLinksQuery } from "../../../services/quickLinks";
import { QuickLinksData } from "../../../common/interfaces/QuickLinksData";

const { Title, Paragraph, Text } = Typography;

const ENTER_ADDRESS = "Enter Address";
const SEE_DETAILS = "See Details";

type ClinicDetailsFormData = Partial<{
  photo: string;
  additionalData: Partial<{
    Specialities: string;
    Since: dayjs.Dayjs;
    Location: string;
    OfficeManager: string;
    Email: string;
    Phone: string;
    Address: string;
    City: string;
    Zip: string;
    State: string;
    Country: string;
    NPI: string;
    TIN: string;
    Fax: string;
    OfficialWeb: string;
    QuickLinks: string;
  }>;
}>;

interface ClinicDetailsProps {
  clinicData?: ClinicData;
  setActiveTab?: (key: string) => void;
  closeClinicDetailModal: () => void;
  setSelectedClinic?: (clinic: ClinicData) => void;
  isCreate?: boolean;
  refreshClinicList: () => void;
}

const SPECIALTIES = "Specialties";
const QUICK_LINKS = "Quick Links";

const RESET = "Reset";
const CLEAR = "Clear";

type AddressFormData = Partial<{
  additionalData: Partial<{
    Address: string;
    City: string;
    Zip: string;
    State: string;
    Country: string;
    Location: string;
  }>;
}>;

const ClinicDetails: FC<ClinicDetailsProps> = ({
  clinicData,
  setActiveTab,
  closeClinicDetailModal,
  setSelectedClinic,
  isCreate = false,
  refreshClinicList,
}) => {
  const isAuthenticated = useIsAuthenticated();
  const [clinicForm] = Form.useForm<ClinicDetailsFormData>();
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [addressInfo, setAddressInfo] = useState<AddressFormData>({
    additionalData: {
      Address: clinicData?.address,
      City: clinicData?.city,
      Zip: clinicData?.zip,
      State: clinicData?.state,
      Country: clinicData?.country,
      Location: clinicData?.location,
    },
  });

  const { data: groupSchemaExtensionData } = useGetGroupSchemaExtensionIdQuery(
    undefined,
    { skip: !isCreate && !isEdit }
  );
  const groupSchemaExtensionId = useMemo(
    () => groupSchemaExtensionData?.data || "",
    [groupSchemaExtensionData]
  );

  const { isAdmin, isCoipaStaff, isCoipaFinance, hasDeleteClinic } =
    useRolesPermissionsAccess();

  const [createClinicApi, { isLoading: createLoading }] =
    useCreateClinicMutation();
  const [updateClinicApi, { isLoading: updateLoading }] =
    useUpdateClinicMutation();

  const createClinic = (): void => {
    clinicForm
      .validateFields()
      .then((values: any) => {
        const merged = mergeFormsAndNestAdditionalData(
          values,
          groupSchemaExtensionId,
          addressInfo
        );
        const updatedClinicData = {
          ...merged,
          photo: parseOnlyBase64ImageData(values.photo),
          groupTypes: ["Unified"],
          mailEnabled: true,
          additionalData: {
            ...merged.additionalData,
            [groupSchemaExtensionId]: {
              ...merged.additionalData[groupSchemaExtensionId],
              Specialities: values.additionalData.Specialities.join(","),
              QuickLinks: values.additionalData.QuickLinks?.join(","),
            },
          },
        };
        createClinicApi(updatedClinicData)
          .unwrap()
          .then((res) => {
            if (res.success) {
              message.success("Clinic created successfully");
              closeClinicDetailModal();
              refreshClinicList();
            } else {
              message.error(res.message);
            }
          })
          .catch((error) =>
            message.error(error.data.message || error.data.title)
          );
      })
      .catch((info) => {
        console.error("Clinic Create Validate Failed:", info);
      });
  };

  const updateClinic = (): void => {
    clinicForm
      .validateFields()
      .then((values: any) => {
        const merged = mergeFormsAndNestAdditionalData(
          values,
          groupSchemaExtensionId,
          addressInfo
        );

        const updatedClinicData = {
          ...merged,
          groupId: clinicData?.key,
          photo: parseOnlyBase64ImageData(values.photo),
          additionalData: {
            ...merged.additionalData,
            [groupSchemaExtensionId]: {
              ...merged.additionalData[groupSchemaExtensionId],
              Specialities: values.additionalData.Specialities.join(","),
              QuickLinks: values.additionalData.QuickLinks?.join(","),
            },
          },
        };
        updateClinicApi(updatedClinicData)
          .unwrap()
          .then((res) => {
            if (res.success) {
              message.success("Clinic updated successfully");
              closeClinicDetailModal();
              refreshClinicList();
            } else {
              message.error(res.message);
            }
          })
          .catch((error) =>
            message.error(error.data.message || error.data.title)
          );
      })
      .catch((info) => {
        console.error("Clinic Update Validate Failed:", info);
      });
  };

  return (
    <div className="clinic_details">
      <Form form={clinicForm} onFinish={isCreate ? createClinic : updateClinic}>
        <Row>
          <Col span={24} className="border-bottom">
            <ClinicDetailsHeader
              isCreate={isCreate}
              clinicData={clinicData}
              updateAddressInfoFormData={setAddressInfo}
              initialAddressInfo={addressInfo}
              isLoadingDataNecessaryForSubmit={createLoading || updateLoading}
              setIsEdit={setIsEdit}
              isEdit={isEdit}
              clinicForm={clinicForm}
            />
          </Col>
          <Col span={24}>
            <Row>
              <ClinicDetailsRow
                name={["additionalData", "OfficialWeb"]}
                label="Official Web"
                Icon={WebIcon}
                isCreate={isCreate}
                isEdit={isEdit}
                textContent={clinicData?.officialWeb}
                rules={[
                  {
                    pattern: urlPatternRegex,
                    message: "Enter valid URL",
                    validateTrigger: "onClick",
                  },
                ]}
                customContent={
                  clinicData?.officialWeb ? (
                    <Button
                      type="link"
                      size="large"
                      className="px-2"
                      onClick={() => {
                        window.open(`${clinicData?.officialWeb}`, "_blank");
                      }}
                    >
                      {clinicData?.officialWeb}
                    </Button>
                  ) : (
                    "-"
                  )
                }
              />
              <ClinicDetailsRow
                name={["additionalData", "QuickLinks"]}
                label="Quick Links"
                Icon={QuickLinkIcon}
                isCreate={isCreate}
                isEdit={isEdit}
                textContent={clinicData?.quickLinks}
                customContent={
                  clinicData?.quickLinkList.length
                    ? clinicData?.quickLinkList.map(
                        (quickLink: QuickLinksData) => (
                          <Button
                            type="link"
                            size="large"
                            key={quickLink.id}
                            className="px-2"
                            onClick={() => {
                              window.open(`${quickLink.url}`, "_blank");
                            }}
                          >
                            {quickLink.name}
                          </Button>
                        )
                      )
                    : "-"
                }
              />
              {(isAdmin || isCoipaStaff || isCoipaFinance) && (
                <>
                  <ClinicDetailsRow
                    name={["additionalData", "NPI"]}
                    label="NPI"
                    Icon={IdCardIcon}
                    textContent={FormatMask(clinicData?.npi)}
                    isCreate={isCreate}
                    isEdit={isEdit}
                    mask="000-000-0000"
                    rules={[
                      {
                        pattern: npiPatternRegex,
                        message: "NPI Number must be a 10-digit number",
                        validateTrigger: "onClick",
                      },
                    ]}
                  />
                  <ClinicDetailsRow
                    name={["additionalData", "TIN"]}
                    label="TIN"
                    Icon={IdCardIcon}
                    textContent={FormatMask(clinicData?.tin)}
                    isCreate={isCreate}
                    isEdit={isEdit}
                    mask="000-000-000"
                    rules={[
                      {
                        pattern: tinPatternRegex,
                        message: "TIN Number must be a 9-digit number",
                        validateTrigger: "onClick",
                      },
                    ]}
                  />
                  {(isAdmin || isCoipaFinance) && (
                    <ClinicDetailsRow
                      name={["additionalData", "TaxonomyCode"]}
                      label="Taxonomy Code"
                      Icon={IdCardIcon}
                      textContent={FormatMask(clinicData?.taxonomyCode)}
                      isCreate={isCreate}
                      isEdit={isEdit}
                    />
                  )}
                </>
              )}
              <ClinicDetailsRow
                name={["additionalData", "Specialities"]}
                label="Specialties"
                Icon={StarIcon}
                isCreate={isCreate}
                isEdit={isEdit}
                textContent={clinicData?.specialities}
                rules={[
                  {
                    required: true,
                    message: "Please enter Specialties",
                  },
                ]}
                customContent={clinicData?.specialities
                  ?.split(/[,;]/)
                  .filter((specialty) => specialty.trim())
                  .map((specialty: string) => (
                    <Tag
                      key={specialty}
                      className="clinic_details_content_info_text_tag"
                      bordered={false}
                    >
                      {specialty?.trim()}
                    </Tag>
                  ))}
              />
              {isAuthenticated && (
                <>
                  {clinicData && (
                    <ClinicDetailsRow
                      label="Providers"
                      Icon={GroupIcon}
                      customContent={
                        <Button
                          className="clinic_details_content_info_text_number fw-bold mb-0"
                          type="link"
                          onClick={() => {
                            setSelectedClinic?.(clinicData);
                            closeClinicDetailModal();
                            setActiveTab?.(HomeTab.Providers);
                          }}
                        >
                          {clinicData?.providers}
                        </Button>
                      }
                    />
                  )}
                  <ClinicDetailsRow
                    name={["additionalData", "OfficeManager"]}
                    label="Office Manager"
                    Icon={ManagerIcon}
                    customContent={
                      <OfficeManager
                        name={["additionalData", "OfficeManager"]}
                        isCreate={isCreate}
                        textContent={clinicData?.officeManager}
                        isEdit={isEdit}
                        clinicForm={clinicForm}
                      />
                    }
                  />
                </>
              )}
              <ClinicDetailsRow
                name={["additionalData", "Email"]}
                label="Official E-mail"
                Icon={EmailIcon}
                textContent={clinicData?.email}
                isCreate={isCreate}
                isEdit={isEdit}
                rules={[
                  {
                    type: "email",
                    message: "Please enter a valid Official E-mail",
                  },
                ]}
              />
              <ClinicDetailsRow
                name={["additionalData", "Phone"]}
                label="Phone"
                Icon={TelephoneIcon}
                isCreate={isCreate}
                textContent={clinicData?.phone}
                isEdit={isEdit}
                mask="(000)-000-0000"
                rules={[
                  {
                    pattern: phonePatternRegex,
                    message: "Enter valid Phone",
                    validateTrigger: "onClick",
                  },
                ]}
              />
              <ClinicDetailsRow
                name={["additionalData", "Fax"]}
                label="Fax"
                Icon={FaxIcon}
                isCreate={isCreate}
                textContent={clinicData?.fax}
                isEdit={isEdit}
                mask="(000) 000-0000"
                rules={[
                  {
                    pattern: faxPatternRegex,
                    message: "Enter valid Fax",
                    validateTrigger: "onClick",
                  },
                ]}
              />
              <ClinicDetailsRow
                name={["additionalData", "ReferralFax"]}
                label="Referral Fax"
                Icon={FaxIcon}
                isCreate={isCreate}
                textContent={clinicData?.referralFax}
                isEdit={isEdit}
                mask="(000) 000-0000"
                rules={[
                  {
                    pattern: faxPatternRegex,
                    message: "Enter valid Referral Fax",
                    validateTrigger: "onClick",
                  },
                ]}
                last={true}
              />
            </Row>
          </Col>
          {hasDeleteClinic && isEdit && (
            <Col span={24}>
              <ClinicDetailDeleteRow
                clinicId={clinicData?.key!}
                refreshClinicList={refreshClinicList}
              />
            </Col>
          )}
        </Row>
      </Form>
    </div>
  );
};

type ClinicDetailsHeaderProps = {
  clinicData?: ClinicData;
  isCreate?: boolean;
  setIsEdit: (val: boolean) => void;
  isEdit: boolean;
  updateAddressInfoFormData: (data: AddressFormData) => void;
  initialAddressInfo: AddressFormData;
  isLoadingDataNecessaryForSubmit: boolean;
  clinicForm: FormInstance;
};

const ClinicDetailsHeader: FC<ClinicDetailsHeaderProps> = ({
  clinicData,
  isCreate,
  setIsEdit,
  isEdit,
  updateAddressInfoFormData,
  initialAddressInfo,
  isLoadingDataNecessaryForSubmit,
  clinicForm,
}) => {
  const { isMobile } = useResponsive();
  const { isAdmin, isCoipaFinance, hasAddClinic, hasEditClinic } =
    useRolesPermissionsAccess();

  const address = FormatAddress({
    address: clinicData?.address,
    city: clinicData?.city,
    state: clinicData?.state,
    postalCode: clinicData?.zip,
    country: clinicData?.country,
  });

  const handleSwitchChange = (value: boolean) => {
    clinicForm.resetFields();
    setIsEdit(value);
  };

  const onDiscardChanges = () => {
    clinicForm.resetFields();
    setIsEdit(false);
  };
  return (
    <Row
      className="clinic_details_header"
      align="middle"
      gutter={[12, { xs: 10, sm: 10 }]}
    >
      <Col
        xs={24}
        md={6}
        className="clinic_details_header_image_wrapper text-center"
      >
        <>
          <Row justify="center">
            {isCreate || isEdit ? (
              <Col xs={14} sm={8} md={24}>
                <Form.Item
                  name="photo"
                  initialValue={isEdit ? clinicData?.imageUrl : undefined}
                >
                  <PictureInput />
                </Form.Item>
              </Col>
            ) : (
              <Col xs={14} sm={8} md={24}>
                <Avatar
                  shape="square"
                  size="large"
                  src={clinicData?.imageUrl}
                  className="clinic_details_header_image_wrapper_image"
                >
                  {getInitials(clinicData?.name)}
                </Avatar>
              </Col>
            )}
          </Row>
        </>
        {isCreate || isEdit ? (
          <Form.Item
            name={["additionalData", "Since"]}
            initialValue={
              clinicData?.since && ParseDayJsDate(clinicData?.since)
            }
          >
            <DatePicker
              className="clinic_details_header_image_wrapper_datepicker"
              placeholder="Since"
              size="large"
            />
          </Form.Item>
        ) : (
          clinicData?.since && (
            <Text className="clinic_details_header_image_wrapper_date w-100 text-md-center">
              Since {FormatDisplayDate(clinicData?.since)}
            </Text>
          )
        )}
      </Col>
      <Col xs={24} md={12} className="clinic_details_header_personal_info">
        {isCreate || isEdit ? (
          <Form.Item
            name="displayName"
            className="w-100 mb-2"
            initialValue={isEdit ? clinicData?.name : undefined}
            rules={[
              {
                required: true,
                message: "Please enter Clinic Name",
              },
            ]}
          >
            <Input size="large" placeholder="Enter Clinic Name" />
          </Form.Item>
        ) : (
          <Title
            level={isMobile ? 3 : 2}
            className="mb-2 mt-0 clinic_details_header_personal_info_name"
          >
            {clinicData?.name}
          </Title>
        )}

        {isCreate || isEdit ? (
          <AddressPopover
            updateFormData={updateAddressInfoFormData}
            initialData={initialAddressInfo}
            isEdit={isEdit}
          />
        ) : (
          address && (
            <Button
              type="link"
              className="d-flex align-items-center mb-2 gap-1 px-0"
              onClick={() => {
                window.open(
                  `https://www.google.com/maps/place/${address}`,
                  "_blank"
                );
              }}
            >
              <LocationIcon className="clinic_details_header_personal_info_location_icon" />
              <Paragraph className="mb-0 clinic_details_header_personal_info_address">
                {address}
              </Paragraph>
            </Button>
          )
        )}
      </Col>
      {(isAdmin || isCoipaFinance) && (hasAddClinic || hasEditClinic) && (
        <>
          {isCreate ? (
            <Col xs={24} md={6}>
              <Button
                size="large"
                htmlType="submit"
                className="w-100"
                type="primary"
                loading={isLoadingDataNecessaryForSubmit}
              >
                Create Clinic
              </Button>
            </Col>
          ) : (
            <Col xs={24} md={6}>
              <Row gutter={[{ sm: 10 }, 12]} className="text-center">
                <Col span={24}>
                  <Paragraph className="mb-0">
                    Edit Mode
                    <Switch
                      checked={isEdit}
                      className="ms-2"
                      onChange={handleSwitchChange}
                    />
                  </Paragraph>
                </Col>
                <Col xs={24} sm={12} md={24}>
                  <Button
                    size="large"
                    disabled={!isEdit}
                    type="primary"
                    className="w-100"
                    htmlType="submit"
                    loading={isLoadingDataNecessaryForSubmit}
                  >
                    Save Changes
                  </Button>
                </Col>
                <Col xs={24} sm={12} md={24}>
                  <Button
                    size="large"
                    disabled={!isEdit}
                    type="default"
                    htmlType="reset"
                    onClick={onDiscardChanges}
                    className="w-100"
                  >
                    Discard Changes
                  </Button>
                </Col>
              </Row>
            </Col>
          )}
        </>
      )}
    </Row>
  );
};

type ClincDetailRowProps = {
  first?: boolean;
  name?: string | string[] | undefined;
  label: string;
  Icon: ComponentType<{ className: string }>;
  textContent?: string;
  customContent?: ReactNode;
  isCreate?: boolean;
  isEdit?: boolean;
  mask?: string;
  rules?: Rule[];
  last?: boolean;
};

const ClinicDetailsRow: FC<ClincDetailRowProps> = ({
  name,
  label,
  textContent,
  customContent,
  Icon,
  first,
  isCreate,
  isEdit,
  mask,
  rules,
  last,
}) => {
  const { data: Specialities } = useGetSpecialitiesQuery(undefined, {
    skip: !isCreate && !isEdit,
  });

  const { data: QuickLinks } = useGetQuickLinksQuery(undefined, {
    skip: !isCreate && !isEdit,
  });

  const specialitiesOptions = useMemo<SelectProps["options"]>(
    () =>
      Specialities?.data.map((specialty) => ({
        label: specialty.name,
        value: specialty.name,
      })),
    [Specialities]
  );

  const quickLinksOptions = useMemo<SelectProps["options"]>(
    () =>
      QuickLinks?.data.map((quickLink) => ({
        label: quickLink.name,
        value: quickLink.name,
      })),
    [QuickLinks]
  );

  return (
    <Col span={24}>
      <Row
        align="middle"
        className={`clinic_details_content ${
          first ? "border-top border-bottom" : last ? "" : "border-bottom"
        }`}
      >
        <Col xs={24} md={7} className="clinic_details_content_icon_wrapper">
          <Icon className="clinic_details_content_icon_wrapper_icon" />
          <Text className="clinic_details_content_icon_wrapper_icon_name">
            {label}
          </Text>
        </Col>
        <Col xs={24} md={17} className="clinic_details_content_info">
          {isCreate || isEdit ? (
            <Form.Item
              name={name}
              initialValue={
                isEdit
                  ? label === SPECIALTIES || label === QUICK_LINKS
                    ? textContent?.split(",")
                    : textContent
                  : undefined
              }
              rules={rules}
            >
              {label === SPECIALTIES ? (
                <Select
                  size="large"
                  placeholder="Select Specialties"
                  options={specialitiesOptions}
                  mode="multiple"
                />
              ) : label === QUICK_LINKS ? (
                <Select
                  size="large"
                  placeholder="Select Quick Links"
                  options={quickLinksOptions}
                  mode="multiple"
                />
              ) : mask ? (
                <MaskedInput size="large" mask={mask} />
              ) : (
                <Input size="large" placeholder={`Enter ${label}`} />
              )}
            </Form.Item>
          ) : customContent ? (
            customContent
          ) : (
            <Text className="clinic_details_content_info_text">
              {textContent || "-"}
            </Text>
          )}
        </Col>
      </Row>
    </Col>
  );
};

type AddressPopoverProps = {
  updateFormData: (data: AddressFormData) => void;
  isEdit?: boolean;
  initialData: AddressFormData;
};

const AddressPopover: FC<AddressPopoverProps> = ({
  updateFormData,
  isEdit,
  initialData,
}) => {
  const [addressForm] = Form.useForm<AddressFormData>();
  const [popoverVisible, setPopoverVisible] = useState<boolean>(false);
  const [addressLabel, setAddressLabel] = useState<string>(
    isEdit ? SEE_DETAILS : ENTER_ADDRESS
  );
  const { data: countries, isSuccess: gotCountries } =
    useGetCountriesQuery(undefined);
  const { data: states, isSuccess: gotStates } = useGetStatesQuery(undefined);
  const { data: cities, isSuccess: gotCities } = useGetCitiesQuery(undefined);
  const { data: zips, isSuccess: gotZips } = useGetZipsQuery(undefined);

  const countryOptions = useMemo<SelectProps["options"]>(
    () =>
      countries?.data.map((country) => ({
        label: country.name,
        value: country.name,
      })),
    [countries]
  );

  const stateOptions = useMemo<SelectProps["options"]>(
    () =>
      states?.data.map((state) => ({ label: state.name, value: state.name })),
    [states]
  );

  const cityOptions = useMemo<SelectProps["options"]>(
    () => cities?.data.map((city) => ({ label: city.name, value: city.name })),
    [cities]
  );

  const zipOptions = useMemo<SelectProps["options"]>(
    () => zips?.data.map((zip) => ({ label: zip.zipCode, value: zip.zipCode })),
    [zips]
  );

  const inputFields: FormInputField[] = [
    {
      name: ["additionalData", "Address"],
      placeholder: "Street Address",
    },
    {
      name: ["additionalData", "City"],
      placeholder: "City",
      options: cityOptions,
      disabled: !gotCities,
    },
    {
      name: ["additionalData", "Zip"],
      placeholder: "ZIP or Postal code",
      options: zipOptions,
      disabled: !gotZips,
    },
    {
      name: ["additionalData", "State"],
      placeholder: "State or Province",
      options: stateOptions,
      disabled: !gotStates,
    },
    {
      name: ["additionalData", "Country"],
      placeholder: "Country or Region",
      options: countryOptions,
      disabled: !gotCountries,
    },
    {
      name: ["additionalData", "Location"],
      placeholder: "Location (Google maps link)",
    },
  ];

  const handleVisibleChange = (visible: boolean) => {
    setPopoverVisible(visible);
  };

  const handleClear = () => {
    setPopoverVisible(false);
    addressForm.resetFields();
    setAddressLabel(isEdit ? SEE_DETAILS : ENTER_ADDRESS);
  };

  const handleApply = () => {
    addressForm.validateFields().then((values) => {
      updateFormData(values);
      setAddressLabel(SEE_DETAILS);
      setPopoverVisible(false);
    });
  };

  return (
    <div className="d-flex w-100">
      <LocationIcon className="clinic_details_header_personal_info_location_icon me-2" />
      <Popover
        trigger="click"
        content={
          <Form form={addressForm}>
            <Row gutter={[0, 12]}>
              {inputFields.map((field) => (
                <Col key={getKey(field)} span={24}>
                  <Title className="mb-0" level={5}>
                    {field.placeholder}
                  </Title>
                  <Form.Item
                    name={field.name}
                    initialValue={
                      isEdit ? getInitialValue(initialData, field) : undefined
                    }
                    rules={field.rules}
                  >
                    {field.options ? (
                      <Select
                        size="large"
                        showSearch
                        placeholder={`Select ${field.placeholder}`}
                        options={field.options}
                        disabled={field.disabled}
                      />
                    ) : (
                      <Input size="large" placeholder={field.placeholder} />
                    )}
                  </Form.Item>
                </Col>
              ))}
              <Col className="text-center" span={24}>
                <Button
                  size="large"
                  type="primary"
                  onClick={handleApply}
                  className="me-2"
                >
                  Apply
                </Button>
                <Button size="large" type="default" onClick={handleClear}>
                  {isEdit ? RESET : CLEAR}
                </Button>
              </Col>
            </Row>
          </Form>
        }
        overlayClassName="clinic_details_header_personal_info_popover"
        open={popoverVisible}
        arrow={false}
        onOpenChange={handleVisibleChange}
      >
        <Button
          size="large"
          type="text"
          className="clinic_details_header_personal_info_enter_address_btn"
          onClick={() => setPopoverVisible(!popoverVisible)}
        >
          {addressLabel}
          <DownOutlined className="ms-auto" />
        </Button>
      </Popover>
    </div>
  );
};

type ClinicDetailDeleteRowProps = {
  clinicId: string;
  refreshClinicList: () => void;
};

const ClinicDetailDeleteRow: FC<ClinicDetailDeleteRowProps> = ({
  clinicId,
  refreshClinicList,
}) => {
  const [confirmDelete, setConfirmDelete] = useState(false);

  const handleDeleteClinic = () => {
    setConfirmDelete(true);
  };

  const handleDiscardDelete = () => {
    setConfirmDelete(false);
  };

  const [deleteClinic, { isLoading }] = useDeleteClinicMutation();

  const handleConfirmDelete = () => {
    try {
      deleteClinic(clinicId)
        .unwrap()
        .then((res) => {
          if (res.success) {
            message.success("Clinic deleted successfully!");
            refreshClinicList();
          } else {
            message.error(res.message);
          }
        })
        .catch((error) => {
          message.error(error.data.message || error.data.title);
        });
    } catch (error) {
      console.error("An unexpected error occurred:", error);
    }
  };

  return (
    <Row align="middle" className="clinic_details_delete_section">
      <Col xs={24} md={confirmDelete ? 12 : 19}>
        <Paragraph className="mb-2">
          {confirmDelete ? (
            <span>
              Are you sure you want to delete this clinic? Please press{" "}
              <strong>Confirm Delete</strong> to Proceed.
            </span>
          ) : (
            <span>
              Please be aware that after deletion, all providers under this
              clinic will need to be associated with a new clinic and primary
              group name.
            </span>
          )}
        </Paragraph>
      </Col>
      {!confirmDelete ? (
        <Col xs={24} md={5} className="text-end">
          <Button
            size="large"
            className="delete-button"
            onClick={handleDeleteClinic}
          >
            Delete Clinic
          </Button>
        </Col>
      ) : (
        <>
          <Col xs={12} md={6} className="text-center">
            <Button
              size="large"
              className="discard-delete-button"
              onClick={handleDiscardDelete}
            >
              Discard Delete
            </Button>
          </Col>
          <Col xs={12} md={6} className="text-end">
            <Button
              size="large"
              className="delete-button"
              onClick={handleConfirmDelete}
              loading={isLoading}
            >
              Confirm Delete
            </Button>
          </Col>
        </>
      )}
    </Row>
  );
};

type PictureInputProps = {
  value?: string;
  onChange?: (value: string) => void;
};

const PictureInput: FC<PictureInputProps> = ({ value, onChange }) => {
  const beforeUpload = async (file: RcFile): Promise<boolean> => {
    try {
      const imageUrl = await handleImageUpload(file);
      onChange?.(imageUrl);
    } catch (error) {
      console.error("File read error:", error);
    }
    return false;
  };

  return (
    <Upload
      listType="picture-card"
      className="clinic_details_header_upload"
      showUploadList={false}
      beforeUpload={beforeUpload}
    >
      {value ? (
        <Avatar
          shape="square"
          size="large"
          src={value}
          className="clinic_details_header_image_wrapper_image"
        ></Avatar>
      ) : (
        <OutBoxIcon className="clinic_details_header_image_wrapper_icon" />
      )}
    </Upload>
  );
};

type OfficeManagerProps = {
  name?: string | string[] | undefined;
  textContent?: string;
  isCreate?: boolean;
  isEdit?: boolean;
  rules?: Rule[];
  clinicForm: FormInstance;
};

const OfficeManager: FC<OfficeManagerProps> = ({
  name,
  textContent,
  isCreate,
  isEdit,
  rules,
  clinicForm,
}) => {
  const [searchDisable, setSearchDisable] = useState<boolean>(false);
  const [manualDisable, setManualDisable] = useState<boolean>(false);
  const [searchValue, setSearchValue] = useState<string>();
  const searchProvidersdebounced = useDebounced(200, setSearchValue);
  const { data, isLoading } = useSearchProvidersQuery(searchValue, {
    skip: !searchValue,
  });
  const [value, setValue] = useState<string>();

  const handleSearch = (newValue: string) => {
    setManualDisable(newValue !== "");
    const cleaned = newValue.trim();
    if (cleaned) {
      searchProvidersdebounced(cleaned);
    }
  };

  const handleChange = (newValue: string) => {
    setManualDisable(newValue !== undefined);
    setValue(newValue);
    clinicForm.setFieldValue(name, newValue);
  };

  return isCreate || isEdit ? (
    <Form.Item
      name={name}
      initialValue={isEdit ? textContent : undefined}
      rules={rules}
    >
      <Row align="middle" justify="space-between">
        <Col xs={24} sm={11} md={11}>
          <Select
            className="w-100"
            popupClassName="clinic_details_popup"
            showSearch
            value={value}
            size="large"
            suffixIcon={null}
            allowClear={true}
            placeholder="Search"
            defaultActiveFirstOption={false}
            filterOption={false}
            onSearch={handleSearch}
            onChange={handleChange}
            optionFilterProp="children"
            notFoundContent={isLoading ? "Loading..." : "No Results Found"}
            options={(data?.data || []).map((d) => ({
              value: d,
              label: d,
            }))}
            disabled={searchDisable}
          />
        </Col>
        <Col xs={24} sm={2} md={2} className="text-center">
          or
        </Col>
        <Col xs={24} sm={11} md={11}>
          <Input
            size="large"
            placeholder="Type in manually"
            disabled={manualDisable}
            onKeyUp={(e) => setSearchDisable(e.currentTarget.value !== "")}
            defaultValue={isEdit ? textContent : ""}
          />
        </Col>
      </Row>
    </Form.Item>
  ) : (
    <Text className="clinic_details_content_info_text">
      {textContent || "-"}
    </Text>
  );
};

export default ClinicDetails;
