import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import { AddressData } from "../interfaces/AddressData";
dayjs.extend(utc);

const DATE_FORMAT_DEFAULT = "MM/DD/YYYY";
const TIME_FORMAT = "HH:mm";
const MERIDIEM_TIME_FORMAT = "HH:mm A";

export const FormatDisplayDateTime = (
  value: string | Date | undefined,
  hasMeridiem: boolean | undefined = false
): string | undefined => {
  if (!value) return undefined;
  const dateTime = dayjs(value);
  return `${dateTime.toDate().toLocaleDateString()} ${dateTime.format(
    hasMeridiem ? MERIDIEM_TIME_FORMAT : TIME_FORMAT
  )}`;
};

export const FormatDisplayDate = (
  value: string | Date | undefined
): string | undefined => {
  if (!value) return undefined;
  const dateTime = dayjs(value);
  return `${dateTime.toDate().toLocaleDateString()}`;
};

export const FormatAddress = (obj: AddressData): string => {
  const address = Object.values(obj)
    .filter((val) => val !== "" && val !== null && val !== undefined)
    .join(", ");

  return address;
};

export const ParseDayJsDate = (
  value: string | Date | undefined
): dayjs.Dayjs | undefined => {
  if (!value) return undefined;
  const defaultFormatted = dayjs(value, DATE_FORMAT_DEFAULT, true);
  if (defaultFormatted.isValid()) return defaultFormatted;
  return dayjs(value);
};

export const FormatMask = (value: string | undefined) => {
  const stringWithoutParentheses = value?.replace(/[()]/g, "");
  return stringWithoutParentheses;
};

export const RemoveSpaceFromString = (str: string) => {
  const removeSpaceFromString = str.replace(/\s+/g, "");
  return removeSpaceFromString;
};
