import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { getAuthToken } from "../common/settings/config/AzureAuthenticationConfig";
import { CredentialsDto } from "../common/interfaces/CredentialsDto";

export const credentialsApi = createApi({
  reducerPath: "credentialsApi",
  baseQuery: fetchBaseQuery({
    baseUrl: "/api/credentials",
    prepareHeaders: async (headers) => {
      const token = await getAuthToken();
      headers.set("Authorization", `Bearer ${token}`);
      return headers;
    },
  }),
  endpoints: (builder) => ({
    getCredentials: builder.query<ResponseDto<CredentialsDto[]>, undefined>({
      query: () => `/`,
    }),
  }),
});

type ResponseDto<T> = {
  success: boolean;
  data: T;
  message?: string | undefined;
};

export const { useGetCredentialsQuery } = credentialsApi;
