import { Layout } from "antd";
import { FC } from "react";
import UseContent from "../../settings/content/UseContent";
import "./MainFooter.style.scss";

const { Footer } = Layout;

const MainFooter: FC = () => {
  const { copyright } = UseContent();

  return <Footer className="footer">{copyright}</Footer>;
};

export default MainFooter;
