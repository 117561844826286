import { LoadingOutlined } from "@ant-design/icons";
import {
  Button,
  Checkbox,
  Col,
  Input,
  List,
  Row,
  Spin,
  Typography,
} from "antd";
import { FC, useEffect, useMemo, useState } from "react";
import "./ModifyGroupAndRole.style.scss";
import { useGetGroupsQuery } from "../../../../services/groups";

const { Title } = Typography;

type ModifyGroupAndRoleProps = {
  closeModal: () => void;
  getGroupsData: (selectedGroups: string[], removedGroups: string[]) => void;
  groupIds?: string;
};

const ModifyGroupAndRole: FC<ModifyGroupAndRoleProps> = ({
  closeModal,
  getGroupsData,
  groupIds,
}) => {
  const initiallySelectedGroups = useMemo(
    () => (groupIds ? groupIds.split(",") : []),
    [groupIds]
  );

  const [selectedGroups, setSelectedGroups] = useState<string[]>(
    initiallySelectedGroups
  );
  const [removedGroups, setRemovedGroups] = useState<string[]>([]);

  useEffect(() => {
    setSelectedGroups(initiallySelectedGroups);
  }, [initiallySelectedGroups]);

  const handleCheckboxChange = (value: string) => {
    setSelectedGroups((prevSelected) => {
      if (prevSelected.includes(value)) {
        setRemovedGroups((prevDeselected) => {
          return prevDeselected.includes(value) ||
            !initiallySelectedGroups.includes(value)
            ? prevDeselected
            : [...prevDeselected, value];
        });
        return prevSelected.filter((id) => id !== value);
      } else {
        setRemovedGroups((prevDeselected) =>
          prevDeselected.filter((id) => id !== value)
        );
        return [...prevSelected, value];
      }
    });
  };

  const handleApply = () => {
    getGroupsData(selectedGroups, removedGroups);
    closeModal();
  };

  return (
    <Row className="groupandrole">
      <Col span={24}>
        <Groups
          selectedGroups={selectedGroups}
          handleCheckboxChange={handleCheckboxChange}
        />
      </Col>
      <Col className="d-flex align-item-center justify-content-center" xs={24}>
        <Row gutter={[18, 0]} align="bottom" justify="center">
          <Col>
            <Button
              size="large"
              type="default"
              onClick={closeModal}
              className="my-2 w-100"
            >
              Cancel
            </Button>
          </Col>
          <Col>
            <Button
              size="large"
              type="primary"
              className="my-2 w-100"
              onClick={handleApply}
            >
              Apply
            </Button>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

type GroupsProps = {
  selectedGroups: string[];
  handleCheckboxChange: (val: string) => void;
};

const Groups: FC<GroupsProps> = ({ selectedGroups, handleCheckboxChange }) => {
  const { data, isLoading } = useGetGroupsQuery(undefined);

  const groups = useMemo(
    () =>
      data?.data.map((group) => ({
        label: group.name,
        value: group.id,
      })),
    [data]
  );

  const [searchedGroups, setSearchedGroups] = useState(groups);

  const onSearchChange = (value: string) => {
    setSearchedGroups(
      groups?.filter((g) =>
        g.label?.toLowerCase().includes(value.toLowerCase())
      )
    );
  };

  return (
    <>
      <Col span={24}>
        <Title level={5}>Groups:</Title>
      </Col>
      <Col span={24}>
        <Input
          placeholder="Search"
          allowClear
          size="large"
          onChange={(e) => onSearchChange(e.target.value)}
          className="mb-2"
        />
      </Col>
      <Col span={24}>
        <Spin
          size="large"
          tip="Loading..."
          spinning={isLoading}
          indicator={<LoadingOutlined />}
        >
          <List
            className="groupandrole_groups_list"
            dataSource={searchedGroups ?? groups}
            renderItem={(item) => (
              <List.Item className="d-flex align-items-center groupandrole_groups_list_item">
                <Checkbox
                  className="fields_checkbox"
                  checked={selectedGroups.includes(item.value as string)}
                  onChange={() => handleCheckboxChange(item.value as string)}
                >
                  {item.label}
                </Checkbox>
              </List.Item>
            )}
          />
        </Spin>
      </Col>
    </>
  );
};

export default ModifyGroupAndRole;
