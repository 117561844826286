import { FC, useCallback, useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Tabs } from "antd";
import Providers from "../providers/Providers";
import Clinics from "../clinics/Clinics";
import Reports from "../reports/Reports";
import useResponsive from "../../hooks/useResponsive";
import { HomeTab } from "../../common/enums";
import { ClinicData } from "../../common/interfaces/ClinicData";
import "./Home.style.scss";
import useRolesPermissionsAccess from "../../hooks/useRolesPermissionsAccess";
import { useIsAuthenticated } from "@azure/msal-react";
import Users from "../users/Users";

const Home: FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { isMobile } = useResponsive();
  const isAuthenticated = useIsAuthenticated();
  const { isAdmin, isCoipaFinance, hasViewReports } =
    useRolesPermissionsAccess();

  const getInitialActiveTab = (): string => {
    const urlParams = new URLSearchParams(location.search);
    return (
      urlParams.get("activeTab") ||
      (isAuthenticated ? HomeTab.AllUsers : HomeTab.Providers)
    );
  };

  const [activeTabKey, setActiveTabKey] = useState<string>(getInitialActiveTab);
  const [selectedClinic, setSelectedClinic] = useState<ClinicData>();
  const clearSelectedClinic = useCallback(
    () => setSelectedClinic(undefined),
    [setSelectedClinic]
  );

  const tabItems = useMemo(() => {
    return [
      {
        key: HomeTab.AllUsers,
        label: HomeTab.AllUsers,
        children: <Users />,
        isVisible: isAuthenticated,
      },
      {
        key: HomeTab.Providers,
        label: HomeTab.Providers,
        children: (
          <Providers
            selectedClinic={selectedClinic}
            clearSelectedClinic={clearSelectedClinic}
          />
        ),
        isVisible: true,
      },
      {
        key: HomeTab.Clinics,
        label: HomeTab.Clinics,
        children: (
          <Clinics
            setActiveTab={setActiveTabKey}
            setSelectedClinic={setSelectedClinic}
          />
        ),
        isVisible: true,
      },
      {
        key: HomeTab.Reports,
        label: HomeTab.Reports,
        children: <Reports />,
        isVisible: (isAdmin || isCoipaFinance) && hasViewReports,
      },
    ];
  }, [
    isAuthenticated,
    selectedClinic,
    clearSelectedClinic,
    isAdmin,
    isCoipaFinance,
    hasViewReports,
  ]);

  useEffect(() => {
    navigate(`?activeTab=${activeTabKey}`);
  }, [activeTabKey, navigate]);

  return (
    <div className="home_content">
      <Tabs
        className="home_content_tabs"
        activeKey={activeTabKey}
        onChange={setActiveTabKey}
      >
        {tabItems
          .filter(
            (tab) => tab.isVisible && (!isMobile || tab.key !== HomeTab.Reports)
          )
          .map((tab) => (
            <Tabs.TabPane key={tab.key} tab={tab.label}>
              {activeTabKey === tab.key && tab.children}
            </Tabs.TabPane>
          ))}
      </Tabs>
    </div>
  );
};

export default Home;
