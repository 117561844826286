const passwordPatternRegex: RegExp =
  /^(?:(?=.*[a-z])(?=.*[A-Z])(?=.*\d)|(?=.*[a-z])(?=.*[A-Z])(?=.*[-@#$%^&*_!+=\\[\]{}|:',.?/`~"();])|(?=.*[a-z])(?=.*\d)(?=.*[-@#$%^&*_!+=\\[\]{}|:',.?/`~"();])|(?=.*[A-Z])(?=.*\d)(?=.*[-@#$%^&*_!+=\\[\]{}|:',.?/`~"();]))[-A-Za-z0-9@#$%^&*_!+=\\[\]{}|:',.?/`~"();]{8,16}$/;
const npiPatternRegex: RegExp = /^\d{3}-\d{3}-\d{4}$/;
const tinPatternRegex: RegExp = /^\d{3}-\d{3}-\d{3}$/;
const phonePatternRegex: RegExp = /^\(\d{3}\)-\d{3}-\d{4}$/;
const faxPatternRegex: RegExp = /^\(\d{3}\) \d{3}-\d{4}$/;
const namePatternRegex: RegExp = /^[a-zA-Z1-9\s-]*$/;
const spacePatternRegex: RegExp = /^[^\s]+$/;
const authPhonePatternRegex: RegExp = /^[0-9+ ]+$/;
const urlPatternRegex = /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi;

const namePatternMesssage: string =
  "Special characters and symbols are not allowed";
const authPhonePatternMesssage: string =
  "Phone number cannot contain letters or special characters";

const makeValidator =
  (regex: RegExp, defaultMessage: string) => (label: any, value: string) => {
    if (!value) {
      return Promise.reject(new Error(`${label.field} is required`));
    }

    if (!regex.test(value)) {
      return Promise.reject(new Error(defaultMessage));
    }

    return Promise.resolve();
  };

export {
  npiPatternRegex,
  tinPatternRegex,
  phonePatternRegex,
  faxPatternRegex,
  passwordPatternRegex,
  namePatternRegex,
  spacePatternRegex,
  namePatternMesssage,
  authPhonePatternRegex,
  authPhonePatternMesssage,
  urlPatternRegex
};
