import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { getAuthToken } from "../common/settings/config/AzureAuthenticationConfig";
import { UserRolesAndPermissionsData } from "../common/interfaces/UserRolesAndPermissionsData";
import { RoleDto } from "../common/interfaces/RoleDto";

export const roleGroupsApi = createApi({
  reducerPath: "rolesapi",
  baseQuery: fetchBaseQuery({
    baseUrl: "/api/rolegroups",
    prepareHeaders: async (headers) => {
      const token = await getAuthToken();
      headers.set("Authorization", `Bearer ${token}`);
      return headers;
    },
  }),
  endpoints: (builder) => ({
    getUserRolesAndPermissions: builder.query<ResponseDto<UserRolesAndPermissionsData>, undefined>({
      query: () => `/rolesandpermissions`,
    }),
    getUserRoles: builder.query<ResponseDto<RoleDto[]> , string>({
      query: (groupIds) => `/roles?groupIds=${groupIds}`,
    }),
  }),
});

type ResponseDto<T> = {
  success: boolean;
  data: T;
  message?: string | undefined;
};

export const { useGetUserRolesAndPermissionsQuery,  useGetUserRolesQuery } = roleGroupsApi;
