import { passwordPatternRegex } from "./validator";

export const randomPasswordGenerator = (length: number = 10): string => {
  length = Math.max(1, length);

  const uppercaseLetters: string = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
  const lowercaseLetters: string = "abcdefghijklmnopqrstuvwxyz";
  const digits: string = "0123456789";

  const allCharacters: string = uppercaseLetters + lowercaseLetters + digits;

  const passwordArray: string[] = [
    getRandomCharacter(uppercaseLetters),
    getRandomCharacter(lowercaseLetters),
    getRandomCharacter(digits),
  ];

  for (let i = 0; i < length - 3; i++) {
    passwordArray.push(getRandomCharacter(allCharacters));
  }

  passwordArray.sort(() => Math.random() - 0.5);

  const passwordStr: string = passwordArray.join("");

  const pattern: RegExp = passwordPatternRegex;

  if (!pattern.test(passwordStr)) {
    return randomPasswordGenerator(length);
  }

  return passwordStr;
};

const getRandomCharacter = (characterSet: string): string => {
  const randomIndex: number = Math.floor(Math.random() * characterSet.length);
  return characterSet.charAt(randomIndex);
};
