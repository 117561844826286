import { FC, useEffect, useState } from "react";
import { Button, Checkbox, Col, Row, Select, Typography } from "antd";
import "./HeaderCellDropdownFilter.style.scss";
import { CheckboxChangeEvent } from 'antd/lib/checkbox';

const { Title } = Typography;

export type CommonDropdownFilterProps = {
  apply: (selectedOptions: string[]) => void;
  applied: string[] | undefined;
};

export type SelectOption = {
  key: string;
  value?: string;
};

type HeaderCellDropdownFilterProps = CommonDropdownFilterProps & {
  options: SelectOption[];
  title: string;
  disabled: boolean;
};

export const HeaderCellDropdownFilter: FC<HeaderCellDropdownFilterProps> = ({
  options,
  applied,
  apply,
  title,
  disabled,
}) => {
  const [selectedValues, setSelectedValues] = useState<string[]>(applied ?? []);
  const [dirty, setDirty] = useState(false); // dirty = select values are (potentially) different from applied values
  const [open, setOpen] = useState(false);

  useEffect(() => {
    // if the applied values change, update the selected values to match,
    // unless the selected values are dirty
    if (!dirty) {
      setSelectedValues(applied ?? []);
    }
  }, [dirty, applied]);

  useEffect(() => {
    // if the dropdown is closed while dirty,
    // reset it to match the applied values
    if (!open && dirty) {
      setSelectedValues(applied ?? []);
      setDirty(false);
    }
  }, [open, dirty, applied]);

  const handleChange = (value: string[]) => {
    setSelectedValues(value);
    setDirty(true);
  };
  const handleApply = () => {
    apply(selectedValues);
    setDirty(false);
  };
  const handleClear = () => {
    setSelectedValues([]);
    apply([]);
    setDirty(false);
  };

  const onCheckboxChange = (e:CheckboxChangeEvent, value: string) => {
    if (e.target.checked) {
      handleChange([...selectedValues, value]);
    } else {
      handleChange(selectedValues.filter(item => item !== value));
    }
  };

  return (
    <Row className="header_cell_filter">
      <Col span={24}>
        <Title level={4}>{title}</Title>
        <Select
          className="w-100"
          popupClassName="header_cell_filter_popup"
          size="large"
          placeholder="View All"
          mode="multiple"
          maxTagCount={0}
          value={selectedValues}
          onChange={handleChange}
          disabled={disabled}
          onDropdownVisibleChange={setOpen}
          dropdownRender={(menu) => (
            <>
              {menu}
              {options.length > 0 && (
                <div className="d-flex justify-content-around my-1 mt-3">
                  <Button
                    type="default"
                    onClick={handleClear}
                    size="large"
                    className="w-100 me-1"
                  >
                    Clear
                  </Button>
                  <Button
                    type="primary"
                    onClick={handleApply}
                    size="large"
                    className="w-100 ms-1"
                  >
                    Apply
                  </Button>
                </div>
              )}
            </>
          )}
        >
          {options.map((item, index) => (
            <Select.Option key={`${item.key}_${index}`} value={item.value ?? item.key}>
               <Checkbox
                className="me-2"
                checked={selectedValues.includes(item.value ?? item.key)}
                onChange={(e) => onCheckboxChange(e, item.value ?? item.key)}
                />
              {item.value ?? item.key}
            </Select.Option>
          ))}
        </Select>
      </Col>
    </Row>
  );
};
