import { PayloadAction, createSlice } from "@reduxjs/toolkit";

type ClinicFilters = {
  nameFilter?: string;
};

export interface ClinicState {
  filters: ClinicFilters;
}

const initialState: ClinicState = {
  filters: {},
};

export const clinicsSlice = createSlice({
  name: "clinics",
  initialState,
  reducers: {
    setNameFilter: (state, action: PayloadAction<string | undefined>) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.filters.nameFilter = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setNameFilter } = clinicsSlice.actions;

export default clinicsSlice.reducer;
