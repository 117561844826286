import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { getAuthToken } from "../common/settings/config/AzureAuthenticationConfig";
import { CountryDto } from "../common/interfaces/CountryDto";
import { CityDto } from "../common/interfaces/CityDto";
import { StateDto } from "../common/interfaces/StateDto";
import { ZipDto } from "../common/interfaces/ZipDto";

export const addressApi = createApi({
  reducerPath: "addressApi",
  baseQuery: fetchBaseQuery({
    baseUrl: "/api/address",
    prepareHeaders: async (headers) => {
      const token = await getAuthToken();
      headers.set("Authorization", `Bearer ${token}`);
      return headers;
    },
  }),

  endpoints: (builder) => ({
    getCities: builder.query<ResponseDto<CityDto[]>, undefined>({
      query: () => `/cities`,
    }),
    getStates: builder.query<ResponseDto<StateDto[]>, undefined>({
      query: () => `/states`,
    }),
    getZips: builder.query<ResponseDto<ZipDto[]>, undefined>({
      query: () => `/zips`,
    }),
    getCountries: builder.query<ResponseDto<CountryDto[]>, undefined>({
      query: () => `/countries`,
    }),
  }),
});

type ResponseDto<T> = {
  success: boolean;
  data: T;
  message?: string | undefined;
};

export const {
  useGetCitiesQuery,
  useGetCountriesQuery,
  useGetZipsQuery,
  useGetStatesQuery,
} = addressApi;
