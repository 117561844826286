import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { getAuthToken } from "../common/settings/config/AzureAuthenticationConfig";
import { QuickLinksData } from "../common/interfaces/QuickLinksData";

export const quickLinksApi = createApi({
  reducerPath: "quicklinksApi",
  baseQuery: fetchBaseQuery({
    baseUrl: "/api/quicklinks",
    prepareHeaders: async (headers) => {
      const token = await getAuthToken();
      headers.set("Authorization", `Bearer ${token}`);
      return headers;
    },
  }),
  endpoints: (builder) => ({
    createQuickLink: builder.mutation<ResponseDto<number>, QuickLinksData>({
      query: (args) => ({
        url: `/`,
        method: "POST",
        body: args,
      }),
    }),
    updateQuickLink: builder.mutation<ResponseDto<boolean>, QuickLinksData[]>({
      query: (data) => ({
        url: ``,
        method: "PATCH",
        body: data,
      }),
    }),
    deleteQuickLink: builder.mutation<ResponseDto<boolean>, number>({
      query: (quickLinkId) => ({
        url: `/${quickLinkId}`,
        method: "DELETE",
      }),
    }),
    getQuickLinks: builder.query<ResponseDto<QuickLinksData[]>, undefined>({
      query: () => `/`,
    }),
  }),
});

type ResponseDto<T> = {
  success: boolean;
  data: T;
  message?: string | undefined;
};

export const {
  useCreateQuickLinkMutation,
  useUpdateQuickLinkMutation,
  useDeleteQuickLinkMutation,
  useGetQuickLinksQuery,
} = quickLinksApi;
