import React, { FC } from "react";
import { Col, List, Row, Spin, Typography } from "antd";
import { AdminDashboardConstants } from "./AdminDashboard.constant";
import { Link, useLocation } from "react-router-dom";
import { LoadingOutlined } from "@ant-design/icons";
import "./AdminDashboardLayout.style.scss";

const { Title } = Typography;

type ContainerProps = {
  children: React.ReactNode;
};

export const AdminDashboardLayout: FC<ContainerProps> = (
  props: ContainerProps
) => {
  const location = useLocation();
  return (
    <div className="page_layout admin_dashboard">
      <Row gutter={[0, 24]} className="">
        <Col xs={24} md={8} lg={6} xxl={4}>
          <div className="admin_dashboard_box_border">
            <div className="admin_dashboard_box_left_menu">
              <Row>
                <Col xs={24}>
                  <Title level={3} className="admin_dashboard_box_title">
                    {AdminDashboardConstants.Title}
                  </Title>
                </Col>
              </Row>
              <Row>
                <Col xs={24}>
                  <Spin
                    size="small"
                    tip="Loading..."
                    spinning={false}
                    indicator={<LoadingOutlined />}
                  >
                    <List
                      size="small"
                      itemLayout="horizontal"
                      dataSource={AdminDashboardConstants.MenuList}
                      renderItem={(item) => (
                        <List.Item className="list_item">
                          <List.Item.Meta
                            title={
                              <Link
                                to={item.path}
                                className={`list_item_link ${
                                  location.pathname === item.path
                                    ? "active"
                                    : ""
                                }`}
                              >
                                {item.title}
                              </Link>
                            }
                          />
                        </List.Item>
                      )}
                    />
                  </Spin>
                </Col>
              </Row>
            </div>
          </div>
        </Col>
        <Col
          xs={{ span: 24, offset: 0 }}
          md={{ span: 15, offset: 1 }}
          lg={{ span: 17, offset: 1 }}
          xxl={{ span: 19, offset: 1 }}
        >
          <div className="admin_dashboard_content_box_border">
            <div className="admin_dashboard_content_box_content">
              {props.children}
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
};
