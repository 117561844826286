import { useContext } from "react";
import { AppContext } from "../context/AppProvider";
import { Theme } from "../../interfaces/Theme";

const useTheme = (): Theme => {
  const { theme } = useContext(AppContext);
  if (!theme) throw new Error("Please add App provider");

  return theme;
};

export default useTheme;
