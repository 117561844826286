import { Button, Col, Form, Input, Row, Typography, message } from "antd";
import { useForm } from "antd/es/form/Form";
import { FC } from "react";
import {
  useCreateSpecialitiesMutation,
  useUpdateSpecialititesMutation,
} from "../../../../services/specialities";

type SpecialityDetailsProps = {
  closeSpecialityDetailModal: () => void;
  specialityEditData?: any;
  refetchSpecialitites: () => void;
};

const { Title } = Typography;

const SpecialityDetails: FC<SpecialityDetailsProps> = ({
  closeSpecialityDetailModal,
  specialityEditData,
  refetchSpecialitites,
}) => {
  const [addSpecialityForm] = useForm();

  const [createSpecialities, { isLoading: createLoading }] =
    useCreateSpecialitiesMutation();
  const [updateSpecialities, { isLoading: updateLoading }] =
    useUpdateSpecialititesMutation();

  const handleCreate = () => {
    addSpecialityForm
      .validateFields()
      .then((values) => {
        createSpecialities(values)
          .unwrap()
          .then(() => {
            message.success("Specialty created successfully!");
            refetchSpecialitites();
            closeSpecialityDetailModal();
          })
          .catch((error) => {
            message.error(error.data.message);
          });
      })
      .catch(() => {
        console.error("Validation failed. Please check your input.");
      });
  };

  const handleEdit = () => {
    addSpecialityForm
      .validateFields()
      .then((values) => {
        updateSpecialities({ ...values, id: specialityEditData.id })
          .unwrap()
          .then(() => {
            message.success("Specialty updated successfully!");
            refetchSpecialitites();
            closeSpecialityDetailModal();
          })
          .catch((error) => {
            message.error(error.data.message);
          });
      })
      .catch(() => {
        console.error("Validation failed. Please check your input.");
      });
  };

  return (
    <div>
      <Form
        form={addSpecialityForm}
        onFinish={specialityEditData ? handleEdit : handleCreate}
      >
        <Title level={3}>Name</Title>
        <Form.Item
          name="name"
          rules={[
            {
              required: true,
              message: "Name is required",
              validateTrigger: "onClick",
            },
          ]}
          initialValue={specialityEditData?.name}
        >
          <Input size="large" placeholder="Enter Name" />
        </Form.Item>
        <Row gutter={[12, 12]} align="middle" justify="end">
          <Col>
            <Button
              size="large"
              type="primary"
              htmlType="submit"
              loading={specialityEditData ? updateLoading : createLoading}
            >
              {specialityEditData ? "Update" : "Save"}
            </Button>
          </Col>
          <Col>
            <Button
              size="large"
              type="default"
              onClick={closeSpecialityDetailModal}
            >
              Cancel
            </Button>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default SpecialityDetails;
