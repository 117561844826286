import { Button, Col, Form, Input, Row, Typography, message } from "antd";
import { useForm } from "antd/es/form/Form";
import { FC } from "react";
import {
  useCreateQuickLinkMutation,
  useUpdateQuickLinkMutation,
} from "../../../../services/quickLinks";
import { urlPatternRegex } from "../../../../common/utilities/validator";

type QuickLinksDetailsProps = {
  closeQuickLinksDetailModal: () => void;
  quickLinksEditData?: any;
  refetchQuickLinks: () => void;
};

const { Title } = Typography;

const QuickLinksDetails: FC<QuickLinksDetailsProps> = ({
  closeQuickLinksDetailModal,
  quickLinksEditData,
  refetchQuickLinks,
}) => {
  const [addQuickLinksForm] = useForm();

  const [createQuickLinks, { isLoading: createLoading }] =
    useCreateQuickLinkMutation();
  const [updateQuickLinks, { isLoading: updateLoading }] =
    useUpdateQuickLinkMutation();

  const handleCreate = () => {
    addQuickLinksForm
      .validateFields()
      .then((values) => {
        createQuickLinks(values)
          .unwrap()
          .then(() => {
            message.success("Quick Link created successfully");
            refetchQuickLinks();
            closeQuickLinksDetailModal();
          })
          .catch((error) => {
            message.error(error.data.message);
          });
      })
      .catch(() => {
        console.error("Validation failed. Please check your input.");
      });
  };

  const handleEdit = () => {
    addQuickLinksForm
      .validateFields()
      .then((values) => {
        updateQuickLinks({ ...values, id: quickLinksEditData.id })
          .unwrap()
          .then(() => {
            message.success("Quick Link updated successfully");
            refetchQuickLinks();
            closeQuickLinksDetailModal();
          })
          .catch((error) => {
            message.error(error.data.message);
          });
      })
      .catch(() => {
        console.error("Validation failed. Please check your input.");
      });
  };

  return (
    <div>
      <Form
        form={addQuickLinksForm}
        onFinish={quickLinksEditData ? handleEdit : handleCreate}
      >
        <Row>
          <Col span={24}>
            <Title level={4}>Display Name</Title>
            <Form.Item
              name="name"
              rules={[
                {
                  required: true,
                  message: "Display Name is required",
                  validateTrigger: "onClick",
                },
              ]}
              initialValue={quickLinksEditData?.name}
            >
              <Input size="large" placeholder="Enter Name" />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Title level={4}>URL</Title>
            <Form.Item
              name="url"
              rules={[
                {
                  required: true,
                  message: "URL is required",
                  validateTrigger: "onClick",
                },
                {
                  pattern: urlPatternRegex,
                  message: "Enter valid URL",
                  validateTrigger: "onClick",
                },
              ]}
              initialValue={quickLinksEditData?.url}
            >
              <Input size="large" placeholder="Enter URL" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[12, 12]} align="middle" justify="end">
          <Col>
            <Button
              size="large"
              type="primary"
              htmlType="submit"
              loading={quickLinksEditData ? updateLoading : createLoading}
            >
              {quickLinksEditData ? "Update" : "Save"}
            </Button>
          </Col>
          <Col>
            <Button
              size="large"
              type="default"
              onClick={closeQuickLinksDetailModal}
            >
              Cancel
            </Button>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default QuickLinksDetails;
