import Icon from "@ant-design/icons";
import { CustomIconComponentProps } from "@ant-design/icons/lib/components/Icon";

const IdCardSvg = () => (
  <svg
    x="0px"
    y="0px"
    viewBox="0 0 64 64"
    height={35}
    width={35}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="m30 47h-20c-.55 0-1-.45-1-1v-2c0-4.54 2.72-8.54 6.93-10.21l.74 1.86c-3.44 1.36-5.67 4.64-5.67 8.35v1h18v-1c0-2.4-.94-4.66-2.64-6.36-.86-.86-1.9-1.54-3.03-1.99l.73-1.86c1.38.54 2.66 1.38 3.71 2.43 2.08 2.08 3.22 4.84 3.22 7.78v2c0 .55-.45 1-1 1z"
      fill="#a6a6a6"
    />
    <path
      d="m58 53h-52c-1.65 0-3-1.35-3-3v-32c0-1.65 1.35-3 3-3h22v2h-22c-.55 0-1 .45-1 1v32c0 .55.45 1 1 1h52c.55 0 1-.45 1-1v-32c0-.55-.45-1-1-1h-22v-2h22c1.65 0 3 1.35 3 3v32c0 1.65-1.35 3-3 3z"
      fill="#a6a6a6"
    />
    <path
      d="m32 23c-2.76 0-5-2.24-5-5v-6c0-.55.45-1 1-1h8c.55 0 1 .45 1 1v6c0 2.76-2.24 5-5 5zm-3-10v5c0 1.65 1.35 3 3 3s3-1.35 3-3v-5z"
      fill="#a6a6a6"
    />
    <path
      d="m20 37c-3.86 0-7-3.14-7-7s3.14-7 7-7 7 3.14 7 7-3.14 7-7 7zm0-12c-2.76 0-5 2.24-5 5s2.24 5 5 5 5-2.24 5-5-2.24-5-5-5z"
      fill="#a6a6a6"
    />
    <path
      d="m54 27h-14c-.55 0-1-.45-1-1s.45-1 1-1h14c.55 0 1 .45 1 1s-.45 1-1 1z"
      fill="#a6a6a6"
    />
    <path
      d="m54 33h-14c-.55 0-1-.45-1-1s.45-1 1-1h14c.55 0 1 .45 1 1s-.45 1-1 1z"
      fill="#a6a6a6"
    />
    <path
      d="m54 39h-14c-.55 0-1-.45-1-1s.45-1 1-1h14c.55 0 1 .45 1 1s-.45 1-1 1z"
      fill="#a6a6a6"
    />
    <path
      d="m54 45h-14c-.55 0-1-.45-1-1s.45-1 1-1h14c.55 0 1 .45 1 1s-.45 1-1 1z"
      fill="#a6a6a6"
    />
  </svg>
);

export const IdCardIcon = (props: Partial<CustomIconComponentProps>) => (
  <Icon component={IdCardSvg} {...props} />
);
