import { useEffect, useState } from "react";
import { Theme } from "../common/interfaces/Theme";
import { Content } from "../common/interfaces/Content";
import { InitialContent } from "../common/settings/content/InitialContent";
import { InitialTheme } from "../common/settings/theme/InitialTheme";

export const useThemeAndContent = () => {
  const [theme, setTheme] = useState<Theme>(InitialTheme);
  const [content, setContent] = useState<Content>(InitialContent);

  useEffect(() => {
    const host = window.location.hostname;
    getTheme(host).then((res) => setTheme(res));
    getContent(host).then((res) => setContent(res));
  }, []);

  return { theme, content };
};

const getContent = (host: string) =>
  fetch(`/contents/${host}.json`).then((res) => res.json());

const getTheme = (host: string) =>
  fetch(`/themes/${host}.json`).then((res) => res.json());
