import { FC, JSX } from "react";
import { Route, Routes } from "react-router-dom";
import { Layout } from "antd";
import Home from "../../../pages/home/Home";
import AccessDenied from "../../../pages/access-denied/AccessDenied";

interface RouteKey {
  [key: string]: {
    path: string;
    element?: JSX.Element;
  };
}

interface IRoute {
  Page: RouteKey;
}

export const PageRoutesConstant: IRoute = {
  Page: {
    Home: {
      path: "/",
      element: <Home />,
    },
    Admin: {
      path: "/admin",
    },
    AccessDenied: {
      path: "/access-denied",
      element: <AccessDenied />,
    },
  },
};

const PageRoutes: FC = () => {
  return (
    <Layout>
      <Routes>
        {Object.keys(PageRoutesConstant.Page).map((key) => {
          const pageItem = PageRoutesConstant.Page[key];
          return (
            <Route key={key} path={pageItem.path} element={pageItem.element} />
          );
        })}
      </Routes>
    </Layout>
  );
};

export default PageRoutes;
