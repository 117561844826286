import {
  LeftOutlined,
  LoadingOutlined,
  PlusOutlined,
  RightOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import { Avatar, Button, Col, Modal, Row, Table, Tag, Typography } from "antd";
import { ColumnsType } from "antd/es/table";
import { FC, Reducer, useEffect, useMemo, useReducer, useState } from "react";
import { getInitials } from "../../common/utilities/helper";
import useRolesPermissionsAccess from "../../hooks/useRolesPermissionsAccess";
import UserSearch from "./search/UserSearch";
import UserDetails from "./details/UserDetails";
import {
  CommonDropdownFilterProps,
  HeaderCellDropdownFilter,
  SelectOption,
} from "../../common/components/headercelldropdownfilter/HeaderCellDropdownFilter";
import "./Users.style.scss";
import {
  useExportUsersMutation,
  useGetUserClinicsQuery,
  useGetUserDepartmentQuery,
  useGetUserJobTitleQuery,
  useGetUsersMutation,
} from "../../services/users";
import { UserListRequestDto } from "../../common/interfaces/UserListRequestDto";
import { UserData } from "../../common/interfaces/UserData";
import useResponsive from "../../hooks/useResponsive";
import { useDebounced } from "../../hooks/useDebounced";
import { DownloadFile } from "../../common/utilities/converter";

const { Title, Text } = Typography;

type UsersProps = {};

const Users: FC<UsersProps> = () => {
  const { isMobile } = useResponsive();

  const [pageSize, setPageSize] = useState<number>(10);

  const [getUsers, { isLoading, isSuccess, data }] = useGetUsersMutation();
  const [userListRequest, setUserListRequest] = useState<UserListRequestDto>(
    {}
  );
  const users = useMemo<UserData[] | undefined>(() => {
    return data?.data?.map((user) => ({
      key: user.id,
      ...user,
    }));
  }, [data]);
  useEffect(() => {
    const request = getUsers(userListRequest);
    return () => request.abort();
  }, [userListRequest, getUsers]);

  const { isAdmin, hasAddUser, hasExportUsers } = useRolesPermissionsAccess();

  const [exportUsers, { isLoading: exportLoading }] = useExportUsersMutation();

  const exportData = async () => {
    const exportFileData = await exportUsers(userListRequest).unwrap();
    if (exportFileData) {
      DownloadFile(
        exportFileData.data.contentType,
        exportFileData.data.rawData,
        exportFileData.data.fileName
      );
    }
  };

  const refreshUserList = () => {
    getUsers(userListRequest);
  };

  const handleSelectedValue = (value: string) => {
    setUserListRequest({ ...userListRequest, name: value });
  };

  const applyJobTitleFilters = (value: string[]) => {
    setUserListRequest({
      ...userListRequest,
      jobTitles: value,
    });
  };
  const appliedJobTitleFilters = userListRequest.jobTitles;

  const applyDepartmentFilters = (value: string[]) => {
    setUserListRequest({
      ...userListRequest,
      departments: value,
    });
  };
  const appliedDepartmentFilters = userListRequest.departments;

  const applyClinicNameFilters = (value: string[]) => {
    setUserListRequest({
      ...userListRequest,
      clinics: value,
    });
  };
  const appliedClinicNameFilters = userListRequest.clinics;

  const [resetFilterCounter, resetFilters] = useReducer<Reducer<number, void>>(
    (state) => state + 1,
    0
  );

  const handleResetAll = () => {
    resetFilters();
    setUserListRequest({});
  };

  const columns: ColumnsType<UserData> = [
    {
      title: (
        <UserSearch
          key={resetFilterCounter}
          handleSelectedValue={handleSelectedValue}
        />
      ),
      dataIndex: "name",
      key: "name",
      width: !isMobile ? 430 : "auto",
      fixed: "left",
      render: (value, record) => (
        <Name
          record={record}
          refreshUserList={refreshUserList}
        />
      ),
    },
    {
      title: (
        <JobTitleHeaderCell
          key={resetFilterCounter}
          apply={applyJobTitleFilters}
          applied={appliedJobTitleFilters}
          gotUser={isSuccess}
        />
      ),
      dataIndex: "jobTitle",
      key: "jobTitle",
      responsive: ["md"],
      render: (value, record) => (
        <Row align="middle" justify="center" gutter={[0, 5]}>
          <Col xs={24} className="text-center">
            <Text className="mb-0">{record.jobTitle}</Text>
          </Col>
        </Row>
      ),
    },
    {
      title: (
        <DepartmentHeaderCell
          key={resetFilterCounter}
          apply={applyDepartmentFilters}
          applied={appliedDepartmentFilters}
          gotUser={isSuccess}
        />
      ),
      dataIndex: "primarySpeciality",
      responsive: ["md"],
      key: "primarySpeciality",
      render: (value, record) => (
        <Row align="middle" justify="center" gutter={[0, 5]}>
          <Col xs={24} className="text-center text-truncate">
            {record.primarySpeciality ? (
              <Tag className="users_list_table_cell_tag me-0" bordered={false}>
                {record.primarySpeciality}
              </Tag>
            ) : (
              "-"
            )}
          </Col>
        </Row>
      ),
    },
    {
      title: (
        <ClinicHeaderCell
          key={resetFilterCounter}
          apply={applyClinicNameFilters}
          applied={appliedClinicNameFilters}
          gotUser={isSuccess}
        />
      ),
      dataIndex: "primaryGroupName",
      responsive: ["md"],
      key: "primaryGroupName",
      render: (value, record) => (
        <Row align="middle" justify="center">
          <Col className="text-center">
            <Text className="users_list_table_cell_clinic mb-0">{value}</Text>
          </Col>
        </Row>
      ),
    },
    {
      title: <ResetAllHeaderCell handleResetAll={handleResetAll} />,
      dataIndex: "moreDetails",
      responsive: ["md"],
      key: "moreDetails",
      render: (value, record) => (
        <MoreDetailsCell
          refreshUserList={refreshUserList}
          record={record}
        />
      ),
    },
  ];

  const handleScroll = useDebounced(300, () => {
    const distanceFromBottom =
      window.innerHeight + window.scrollY - document.body.scrollHeight;

    if (distanceFromBottom > -100) {
      setPageSize((prevPageSize) => prevPageSize + 10);
    }
  });

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [handleScroll]);

  const handleClose = (tagValue: string) => {
    setUserListRequest((prevUserListRequest) => ({
      ...prevUserListRequest,
      ...Object.fromEntries(
        Object.entries(prevUserListRequest).map(([key, value]) => [
          key,
          Array.isArray(value)
            ? (value as string[]).filter((item) => item !== tagValue)
            : value,
        ])
      ),
    }));
  };

  const hasFilterValue = Object.values(userListRequest).some(
    (value) => Array.isArray(value) && value.length > 0
  );

  return (
    <div className="users">
      <div className="users_list">
        <Table
          className="users_list_table"
          columns={columns}
          scroll={!isMobile ? { x: 1000 } : undefined}
          dataSource={users}
          title={
            isAdmin || (hasFilterValue && !isMobile)
              ? () => (
                <Row align="middle" justify="space-between" gutter={[10, 0]}>
                  <Col xs={24} md={13} xl={18} className="d-none d-md-block">
                    {Object.entries(userListRequest).map(([key, value]) => (
                      <>
                        {Array.isArray(value) &&
                          value.map((item) => (
                            <Tag
                              closable
                              key={item}
                              className="users_list_table_cell users_list_table_cell_tag users_list_table_cell_tag_filter_value_tag me-1 mt-1"
                              onClose={() => handleClose(item)}
                            >
                              <span className="me-2">{item}</span>
                            </Tag>
                          ))}
                      </>
                    ))}
                  </Col>
                  {isAdmin && (
                    <Col
                      xs={24}
                      md={11}
                      xl={6}
                      className="d-flex justify-content-end"
                    >
                      {hasExportUsers && (
                        <Button
                          size="large"
                          type="default"
                          className="me-2"
                          icon={<UploadOutlined />}
                          loading={exportLoading}
                          onClick={exportData}
                        >
                          {exportLoading ? "Loading..." : "Export Data"}
                        </Button>
                      )}
                      {hasAddUser && (
                        <AddUser
                          refreshUserList={refreshUserList}
                        />
                      )}
                    </Col>
                  )}
                </Row>
              )
              : undefined
          }
          pagination={
            isMobile
              ? {
                pageSize: pageSize,
              }
              : {
                hideOnSinglePage: true,
                position: ["bottomCenter"],
                prevIcon: (
                  <Button className="users_custom_pagination_btn prev">
                    <LeftOutlined />
                    Prev
                  </Button>
                ),
                nextIcon: (
                  <Button className="users_custom_pagination_btn next">
                    Next <RightOutlined />
                  </Button>
                ),
              }
          }
          loading={{
            size: "large",
            tip: "Loading...",
            spinning: isLoading,
            indicator: <LoadingOutlined />,
          }}
        />
      </div>
    </div>
  );
};

type AddUserProps = {
  refreshUserList: () => void;
};

const AddUser: FC<AddUserProps> = ({ refreshUserList }) => {
  const [isUserDetailModalOpen, setIsUserDetailModalOpen] =
    useState<boolean>(false);
  const openUserDetailsModal = () => {
    setIsUserDetailModalOpen(true);
  };

  const closeUserDetailModal = () => {
    setIsUserDetailModalOpen(false);
  };

  return (
    <Row align="middle" justify="center">
      <Col className="text-center">
        <Button
          size="large"
          onClick={openUserDetailsModal}
          icon={<PlusOutlined />}
        >
          Add User
        </Button>
      </Col>
      <Modal
        open={isUserDetailModalOpen}
        onCancel={closeUserDetailModal}
        footer={null}
        width={830}
        className="users_modal"
        centered
        destroyOnClose={true}
      >
        <UserDetails
          closeUserDetailModal={closeUserDetailModal}
          isCreate={true}
          refreshUserList={refreshUserList}
        />
      </Modal>
    </Row>
  );
};

type JobTitleHeaderCellProps = CommonDropdownFilterProps & {
  gotUser: boolean;
};

const JobTitleHeaderCell: FC<JobTitleHeaderCellProps> = ({
  apply,
  applied,
  gotUser,
}) => {
  const { data, isSuccess } = useGetUserJobTitleQuery(undefined, {
    skip: !gotUser,
  });

  const options = useMemo<SelectOption[]>(
    () =>
      data?.data.map((jobTitle) => ({
        key: jobTitle,
      })) ?? [],
    [data]
  );

  return (
    <HeaderCellDropdownFilter
      applied={applied}
      apply={apply}
      options={options}
      title="Job Title"
      disabled={!isSuccess}
    />
  );
};

type ClinicHeaderCellProps = CommonDropdownFilterProps & {
  gotUser: boolean;
};

const ClinicHeaderCell: FC<ClinicHeaderCellProps> = ({
  apply,
  applied,
  gotUser,
}) => {
  const { data, isSuccess } = useGetUserClinicsQuery(undefined, {
    skip: !gotUser,
  });

  const options = useMemo<SelectOption[]>(
    () =>
      data?.data.map((clinic) => ({
        key: clinic,
      })) ?? [],
    [data]
  );

  return (
    <HeaderCellDropdownFilter
      applied={applied}
      apply={apply}
      options={options}
      title="Clinic"
      disabled={!isSuccess}
    />
  );
};

type DepartmentHeaderCellProps = CommonDropdownFilterProps & {
  gotUser: boolean;
};

const DepartmentHeaderCell: FC<DepartmentHeaderCellProps> = ({
  apply,
  applied,
  gotUser,
}) => {
  const { data, isSuccess } = useGetUserDepartmentQuery(undefined, {
    skip: !gotUser,
  });

  const options = useMemo<SelectOption[]>(
    () =>
      data?.data.map((department) => ({
        key: department,
      })) ?? [],
    [data]
  );

  return (
    <HeaderCellDropdownFilter
      applied={applied}
      apply={apply}
      options={options}
      title="Department"
      disabled={!isSuccess}
    />
  );
};

type MoreDetailsCellProps = {
  record: UserData;
  refreshUserList: () => void;
};
const MoreDetailsCell: FC<MoreDetailsCellProps> = ({
  record,
  refreshUserList,
}) => {
  const [userDetailsModalData, setUserDetailsModalData] = useState<UserData>();

  const isUserDetailModalOpen = !!userDetailsModalData;

  const openUserDetailsModal = () => {
    setUserDetailsModalData(record);
  };

  const closeUsersDetailModal = () => {
    setUserDetailsModalData(undefined);
  };

  return (
    <Row align="middle" justify="center">
      <Col className="text-center">
        <Button
          type="default"
          size="large"
          className="users_list_table_cell_btn_moredetail"
          onClick={openUserDetailsModal}
        >
          More Details
        </Button>
      </Col>
      <Modal
        open={isUserDetailModalOpen}
        onCancel={closeUsersDetailModal}
        footer={null}
        width={800}
        className="users_details_modal"
        centered
        destroyOnClose={true}
      >
        {!!userDetailsModalData && (
          <UserDetails
            userData={userDetailsModalData}
            closeUserDetailModal={closeUsersDetailModal}
            refreshUserList={refreshUserList}
          />
        )}
      </Modal>
    </Row>
  );
};

type ResetAllHeaderCellProps = {
  handleResetAll: () => void;
};

const ResetAllHeaderCell: FC<ResetAllHeaderCellProps> = ({
  handleResetAll,
}) => {
  return (
    <Row align="middle" justify="start">
      <Col span={24}>
        <Title level={4} className="users_filter_title">
          &nbsp;
        </Title>
        <Row>
          <Col span={24} className="text-start">
            <Button
              type="text"
              size="large"
              className="users_list_table_cell_btn_resetall"
              onClick={handleResetAll}
            >
              Reset All
            </Button>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

type NameProps = {
  record: UserData;
  refreshUserList: () => void;
};

const Name: FC<NameProps> = ({ record, refreshUserList }) => {
  const { isMobile } = useResponsive();
  const [userDetailsModalData, setUserDetailsModalData] = useState<UserData>();

  const isUserDetailModalOpen = !!userDetailsModalData;

  const openUserDetailsModal = () => {
    if (isMobile) setUserDetailsModalData(record);
  };

  const closeUsersDetailModal = () => {
    setUserDetailsModalData(undefined);
  };
  return (
    <>
      <Row align="middle" onClick={openUserDetailsModal}>
        <Col xs={6} sm={4} md={6}>
          <Avatar
            shape="square"
            size="large"
            src={record.imageUrl}
            className="users_list_table_cell_image"
          >
            {getInitials(record?.name)}
          </Avatar>
        </Col>
        <Col xs={15} sm={17} md={18}>
          <Row>
            <Col xs={24}>
              <Title level={3} className="users_list_table_cell_name mb-0">
                {record.name}
              </Title>
            </Col>
            <Col xs={24}>
              <Text className="mb-0">{record.email}</Text>
            </Col>
          </Row>
        </Col>
        {isMobile && (
          <Col span={3}>
            <RightOutlined className="users_list_table_cell_right_icon" />
          </Col>
        )}
      </Row>
      <Modal
        open={isUserDetailModalOpen}
        onCancel={closeUsersDetailModal}
        footer={null}
        width={830}
        className="users_details_modal"
        centered
        destroyOnClose={true}
      >
        {!!userDetailsModalData && (
          <UserDetails
            userData={userDetailsModalData}
            closeUserDetailModal={closeUsersDetailModal}
            refreshUserList={refreshUserList}
          />
        )}
      </Modal>
    </>
  );
};

export default Users;
