import { PayloadAction, createSlice } from "@reduxjs/toolkit";

type ProviderFilters = {
  nameFilter?: string;
};

export interface ProviderState {
  filters: ProviderFilters;
}

const initialState: ProviderState = {
  filters: {},
};

export const providersSlice = createSlice({
  name: "providers",
  initialState,
  reducers: {
    setNameFilter: (state, action: PayloadAction<string | undefined>) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.filters.nameFilter = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setNameFilter } = providersSlice.actions;

export default providersSlice.reducer;
