import { FC, Reducer, useEffect, useMemo, useReducer, useState } from "react";
import { Avatar, Button, Col, Modal, Row, Table, Tag, Typography } from "antd";
import { ColumnsType } from "antd/es/table";
import {
  LeftOutlined,
  LoadingOutlined,
  PlusOutlined,
  RightOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import ProviderDetails from "./details/ProviderDetails";
import { ProviderData } from "../../common/interfaces/ProviderData";
import {
  useExportProvidersMutation,
  useGetProviderCitiesQuery,
  useGetProviderPrimarySpecialitiesQuery,
  useGetProvidersMutation,
} from "../../services/providers";
import { FormatDisplayDate } from "../../common/utilities/format";
import { ProviderListRequestDto } from "../../common/interfaces/ProviderListRequestDto";
import ProviderSearch from "./search/ProviderSearch";
import { useGetClinicsNameQuery } from "../../services/clinics";
import { ClinicData } from "../../common/interfaces/ClinicData";
import {
  CommonDropdownFilterProps,
  HeaderCellDropdownFilter,
  SelectOption,
} from "../../common/components/headercelldropdownfilter/HeaderCellDropdownFilter";
import useRolesPermissionsAccess from "../../hooks/useRolesPermissionsAccess";
import { getInitials } from "../../common/utilities/helper";
import useResponsive from "../../hooks/useResponsive";
import { useDebounced } from "../../hooks/useDebounced";
import { useIsAuthenticated } from "@azure/msal-react";
import { DownloadFile } from "../../common/utilities/converter";
import "./Providers.style.scss";

const { Title, Text } = Typography;

type ProviderProps = {
  selectedClinic?: ClinicData;
  clearSelectedClinic: () => void;
};

const Providers: FC<ProviderProps> = ({
  selectedClinic,
  clearSelectedClinic,
}) => {
  const { isMobile } = useResponsive();

  const [pageSize, setPageSize] = useState<number>(10);

  const [getProviders, { isLoading, isSuccess, data }] =
    useGetProvidersMutation();
  const [providerListRequest, setProviderListRequest] =
    useState<ProviderListRequestDto>({});
  const [exportProviders, { isLoading: exportLoading }] =
    useExportProvidersMutation();

  const providers = useMemo<ProviderData[] | undefined>(() => {
    return data?.data?.map((provider) => ({
      key: provider.id,
      ...provider,
    }));
  }, [data]);

  useEffect(() => {
    const request = getProviders(providerListRequest);
    return () => request.abort();
  }, [providerListRequest, getProviders]);

  const { isAdmin, hasAddProvider, hasExportProviders } =
    useRolesPermissionsAccess();

  const refreshProviderList = () => {
    getProviders(providerListRequest);
  };

  const handleSelectedValue = (value: string) => {
    setProviderListRequest({ ...providerListRequest, name: value });
  };

  const applyPrimarySpecialyFilters = (value: string[]) => {
    setProviderListRequest({
      ...providerListRequest,
      primarySpecialities: value,
    });
  };
  const appliedPrimarySpecialityFilters =
    providerListRequest.primarySpecialities;

  const exportData = async () => {
    const exportFileData = await exportProviders(providerListRequest).unwrap();
    if (exportFileData) {
      DownloadFile(
        exportFileData.data.contentType,
        exportFileData.data.rawData,
        exportFileData.data.fileName
      );
    }
  };

  useEffect(() => {
    if (selectedClinic) {
      setProviderListRequest({
        ...providerListRequest,
        clinicId: selectedClinic.key,
        primaryGroupNames: [selectedClinic.name],
      });
      // only apply the selected clinic to the filters once.
      // afterwards, let the user control through the filter dropdown.
      clearSelectedClinic();
    }
  }, [selectedClinic, providerListRequest, clearSelectedClinic]);

  const applyClinicNameFilters = (value: string[]) => {
    const clinicId =
      selectedClinic?.name && value.includes(selectedClinic?.name)
        ? selectedClinic?.key
        : undefined;
    setProviderListRequest({
      ...providerListRequest,
      primaryGroupNames: value,
      clinicId,
    });
  };
  const appliedClinicNameFilters = providerListRequest.primaryGroupNames;

  const applyCityFilters = (value: string[]) => {
    setProviderListRequest({
      ...providerListRequest,
      cities: value,
    });
  };
  const appliedCityFilters = providerListRequest.cities;

  const [resetFilterCounter, resetFilters] = useReducer<Reducer<number, void>>(
    (state) => state + 1,
    0
  );

  const handleResetAll = () => {
    resetFilters();
    setProviderListRequest({});
  };

  const columns: ColumnsType<ProviderData> = [
    {
      title: (
        <ProviderSearch
          key={resetFilterCounter}
          handleSelectedValue={handleSelectedValue}
        />
      ),
      dataIndex: "name",
      key: "name",
      width: !isMobile ? 430 : "auto",
      fixed: "left",
      render: (_value, record) => (
        <Name record={record} refreshProviderList={refreshProviderList} />
      ),
    },
    {
      title: (
        <PrimarySpecialityHeaderCell
          key={resetFilterCounter}
          apply={applyPrimarySpecialyFilters}
          applied={appliedPrimarySpecialityFilters}
          gotProviders={isSuccess}
        />
      ),
      dataIndex: "primarySpeciality",
      key: "primarySpeciality",
      ellipsis: true,
      width: 300,
      responsive: ["md"],
      render: (value, record) => (
        <Row align="middle" justify="center" gutter={[0, 5]}>
          <Col xs={24} className="text-center text-truncate">
            {record.primarySpeciality ? (
              <Tag
                className="providers_list_table_cell_tag me-0"
                bordered={false}
              >
                {record.primarySpeciality}
              </Tag>
            ) : (
              "-"
            )}
          </Col>
          <Col xs={24} className="text-center text-truncate">
            <Text className="mb-0">{record.jobTitle}</Text>
          </Col>
        </Row>
      ),
    },
    {
      title: (
        <ClinicHeaderCell
          key={resetFilterCounter}
          apply={applyClinicNameFilters}
          applied={appliedClinicNameFilters}
          gotProviders={isSuccess}
        />
      ),
      dataIndex: "primaryGroupName",
      key: "primaryGroupName",
      responsive: ["md"],
      render: (value, record) => (
        <Row align="middle" justify="center">
          <Col className="text-center">
            <Text className="providers_list_table_cell_clinic mb-0">
              {value}
            </Text>
          </Col>
        </Row>
      ),
    },
    {
      title: (
        <CityHeaderCell
          key={resetFilterCounter}
          apply={applyCityFilters}
          applied={appliedCityFilters}
          gotProviders={isSuccess}
        />
      ),
      dataIndex: "city",
      key: "city",
      responsive: ["md"],
      render: (value, record) => (
        <Row align="middle" justify="center" gutter={[0, 5]}>
          <Col xs={24} className="text-center text-truncate">
            <Title level={5} className="providers_list_table_cell_city mb-0">
              {record.city}
            </Title>
          </Col>
          <Col xs={24} className="text-center text-truncate">
            <Text className="mb-0">{record.country}</Text>
          </Col>
        </Row>
      ),
    },
    {
      title: <ResetAllHeaderCell handleResetAll={handleResetAll} />,
      dataIndex: "moreDetails",
      responsive: ["md"],
      key: "moreDetails",
      width: 160,
      render: (value, record) => (
        <MoreDetailsCell
          refreshProviderList={refreshProviderList}
          record={record}
        />
      ),
    },
  ];

  const handleScroll = useDebounced(300, () => {
    const distanceFromBottom =
      window.innerHeight + window.scrollY - document.body.scrollHeight;

    if (distanceFromBottom > -100) {
      setPageSize((prevPageSize) => prevPageSize + 10);
    }
  });

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [handleScroll]);

  const handleClose = (tagValue: string) => {
    setProviderListRequest((prevUserListRequest) => ({
      ...prevUserListRequest,
      ...Object.fromEntries(
        Object.entries(prevUserListRequest).map(([key, value]) => [
          key,
          Array.isArray(value)
            ? (value as string[]).filter((item) => item !== tagValue)
            : value,
        ])
      ),
    }));
  };

  const hasFilterValue = Object.values(providerListRequest).some(
    (value) => Array.isArray(value) && value.length > 0
  );

  return (
    <div className="providers">
      <div className="providers_list">
        <Table
          className="providers_list_table"
          columns={columns}
          scroll={!isMobile ? { x: 1000 } : undefined}
          dataSource={providers}
          title={
            isAdmin || (hasFilterValue && !isMobile)
              ? () => (
                  <Row align="middle" justify="space-between" gutter={[10, 0]}>
                    <Col xs={24} md={13} xl={18} className="d-none d-md-block">
                      {Object.entries(providerListRequest).map(
                        ([key, value]) => (
                          <>
                            {Array.isArray(value) &&
                              value.map((item) => (
                                <Tag
                                  closable
                                  key={item}
                                  className="providers_list_table_cell providers_list_table_cell_tag providers_list_table_cell_tag_filter_value_tag me-1 mt-1"
                                  onClose={() => handleClose(item)}
                                >
                                  <span className="me-2">{item}</span>
                                </Tag>
                              ))}
                          </>
                        )
                      )}
                    </Col>
                    {isAdmin && (
                      <Col
                        xs={24}
                        md={11}
                        xl={6}
                        className="d-flex justify-content-end"
                      >
                        {hasExportProviders && (
                          <Button
                            size="large"
                            type="default"
                            className="me-2"
                            icon={<UploadOutlined />}
                            loading={exportLoading}
                            onClick={exportData}
                          >
                            {exportLoading ? "Loading..." : "Export Data"}
                          </Button>
                        )}
                        {hasAddProvider && (
                          <AddProviders
                            refreshProviderList={refreshProviderList}
                          />
                        )}
                      </Col>
                    )}
                  </Row>
                )
              : undefined
          }
          pagination={
            isMobile
              ? {
                  pageSize: pageSize,
                }
              : {
                  hideOnSinglePage: true,
                  position: ["bottomCenter"],
                  prevIcon: (
                    <Button className="providers_custom_pagination_btn prev">
                      <LeftOutlined />
                      Prev
                    </Button>
                  ),
                  nextIcon: (
                    <Button className="providers_custom_pagination_btn next">
                      Next <RightOutlined />
                    </Button>
                  ),
                }
          }
          loading={{
            size: "large",
            tip: "Loading...",
            spinning: isLoading,
            indicator: <LoadingOutlined />,
          }}
        />
      </div>
    </div>
  );
};

type AddProvidersProps = {
  refreshProviderList: () => void;
};

const AddProviders: FC<AddProvidersProps> = ({ refreshProviderList }) => {
  const [isProviderDetailModalOpen, setIsProviderDetailModalOpen] =
    useState<boolean>(false);
  const openProviderDetailsModal = () => {
    setIsProviderDetailModalOpen(true);
  };

  const closeProviderDetailModal = () => {
    setIsProviderDetailModalOpen(false);
  };

  return (
    <Row align="middle" justify="center">
      <Col className="text-center">
        <Button
          size="large"
          onClick={openProviderDetailsModal}
          icon={<PlusOutlined />}
        >
          Add Provider
        </Button>
      </Col>
      <Modal
        open={isProviderDetailModalOpen}
        onCancel={closeProviderDetailModal}
        footer={null}
        width={830}
        className="providers_modal"
        centered
        destroyOnClose={true}
      >
        <ProviderDetails
          closeProviderDetailModal={closeProviderDetailModal}
          isCreate={true}
          refreshProviderList={refreshProviderList}
        />
      </Modal>
    </Row>
  );
};

type PrimarySpecialityHeaderCellProps = CommonDropdownFilterProps & {
  gotProviders: boolean;
};

const PrimarySpecialityHeaderCell: FC<PrimarySpecialityHeaderCellProps> = ({
  apply,
  applied,
  gotProviders,
}) => {
  const { data, isSuccess } = useGetProviderPrimarySpecialitiesQuery(
    undefined,
    {
      skip: !gotProviders,
    }
  );

  const options = useMemo<SelectOption[]>(
    () =>
      data?.data.map((specialty) => ({
        key: specialty,
      })) ?? [],
    [data]
  );

  return (
    <HeaderCellDropdownFilter
      applied={applied}
      apply={apply}
      options={options}
      title="Primary Specialty"
      disabled={!isSuccess}
    />
  );
};

type ClinicHeaderCellProps = CommonDropdownFilterProps & {
  gotProviders: boolean;
};

const ClinicHeaderCell: FC<ClinicHeaderCellProps> = ({
  apply,
  applied,
  gotProviders,
}) => {
  const { data, isSuccess } = useGetClinicsNameQuery(undefined, {
    skip: !gotProviders,
  });

  const options = useMemo<SelectOption[]>(
    () =>
      data?.data.map((item) => ({
        key: item.id!,
        value: item.name,
      })) ?? [],
    [data]
  );

  return (
    <HeaderCellDropdownFilter
      applied={applied}
      apply={apply}
      options={options}
      title="Clinic"
      disabled={!isSuccess}
    />
  );
};

type CityHeaderCellProps = CommonDropdownFilterProps & {
  gotProviders: boolean;
};

const CityHeaderCell: FC<CityHeaderCellProps> = ({
  apply,
  applied,
  gotProviders,
}) => {
  const { data, isSuccess } = useGetProviderCitiesQuery(undefined, {
    skip: !gotProviders,
  });

  const options = useMemo<SelectOption[]>(
    () =>
      data?.data.map((item) => ({
        key: item,
      })) ?? [],
    [data]
  );

  return (
    <HeaderCellDropdownFilter
      applied={applied}
      apply={apply}
      options={options}
      title="City"
      disabled={!isSuccess}
    />
  );
};

type MoreDetailsCellProps = {
  record: ProviderData;
  refreshProviderList: () => void;
};
const MoreDetailsCell: FC<MoreDetailsCellProps> = ({
  record,
  refreshProviderList,
}) => {
  const [providerDetailsModalData, setProviderDetailsModalData] =
    useState<ProviderData>();

  const isProviderDetailModalOpen = !!providerDetailsModalData;

  const openProviderDetailsModal = () => {
    setProviderDetailsModalData(record);
  };

  const closeProvidersDetailModal = () => {
    setProviderDetailsModalData(undefined);
  };

  return (
    <Row align="middle" justify="center">
      <Col className="text-center">
        <Button
          type="default"
          size="large"
          className="providers_list_table_cell_btn_moredetail"
          onClick={openProviderDetailsModal}
        >
          More Details
        </Button>
      </Col>
      <Modal
        open={isProviderDetailModalOpen}
        onCancel={closeProvidersDetailModal}
        footer={null}
        width={830}
        className="providers_details_modal"
        centered
        destroyOnClose={true}
      >
        {!!providerDetailsModalData && (
          <ProviderDetails
            providerData={providerDetailsModalData}
            closeProviderDetailModal={closeProvidersDetailModal}
            refreshProviderList={refreshProviderList}
          />
        )}
      </Modal>
    </Row>
  );
};

type ResetAllHeaderCellProps = {
  handleResetAll: () => void;
};

const ResetAllHeaderCell: FC<ResetAllHeaderCellProps> = ({
  handleResetAll,
}) => {
  return (
    <Row align="middle" justify="start">
      <Col span={24}>
        <Title level={4} className="providers_filter_title">
          &nbsp;
        </Title>
        <Row>
          <Col span={24} className="text-start">
            <Button
              type="text"
              size="large"
              className="providers_list_table_cell_btn_resetall"
              onClick={handleResetAll}
            >
              Reset All
            </Button>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

type NameProps = {
  record: ProviderData;
  refreshProviderList: () => void;
};

const Name: FC<NameProps> = ({ record, refreshProviderList }) => {
  const isAuthenticated = useIsAuthenticated();
  const [providerDetailsModalData, setProviderDetailsModalData] =
    useState<ProviderData>();

  const isProviderDetailModalOpen = !!providerDetailsModalData;

  const openProviderDetailsModal = () => {
    if (isMobile) {
      setProviderDetailsModalData(record);
    }
  };

  const closeProvidersDetailModal = () => {
    setProviderDetailsModalData(undefined);
  };

  const { isMobile } = useResponsive();
  return (
    <>
      <Row align="middle" onClick={openProviderDetailsModal}>
        <Col xs={6} sm={4} md={6}>
          <Avatar
            shape="square"
            size="large"
            src={record.imageUrl}
            className="providers_list_table_cell_image"
          >
            {getInitials(record?.name)}
          </Avatar>
        </Col>
        <Col xs={15} sm={17} md={18}>
          <Row>
            <Col xs={24}>
              <Title level={3} className="providers_list_table_cell_name mb-0">
                {record.name}
              </Title>
            </Col>
            {isAuthenticated && (
              <Col xs={24}>
                <Text className="mb-0">{record.email}</Text>
              </Col>
            )}
            {record.memberSince && (
              <Col xs={24}>
                <Text className="providers_list_table_cell_date mb-0">
                  Member since {FormatDisplayDate(record.memberSince)}
                </Text>
              </Col>
            )}
          </Row>
        </Col>
        {isMobile && (
          <Col span={3}>
            <RightOutlined className="providers_list_table_cell_right_icon" />
          </Col>
        )}
      </Row>
      {isMobile && (
        <Modal
          open={isProviderDetailModalOpen}
          onCancel={closeProvidersDetailModal}
          footer={null}
          width={830}
          className="providers_details_modal"
          centered
          destroyOnClose={true}
        >
          <ProviderDetails
            providerData={providerDetailsModalData}
            closeProviderDetailModal={closeProvidersDetailModal}
            refreshProviderList={refreshProviderList}
          />
        </Modal>
      )}
    </>
  );
};

export default Providers;
