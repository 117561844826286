import { useContext } from "react";
import { AppContext } from "../context/AppProvider";
import { Content } from "../../interfaces/Content";

const UseContent = (): Content => {
  const { content } = useContext(AppContext);
  const { value, copyright, address } = content;

  if (!content) throw new Error("Please add App provider");

  return {
    value,
    copyright,
    address,
  };
};

export default UseContent;
