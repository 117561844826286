import {
  AuthenticatedTemplate,
  MsalProvider,
  UnauthenticatedTemplate,
} from "@azure/msal-react";
import { FC } from "react";
import { PublicClientApplication } from "@azure/msal-browser";
import { useNavigate } from "react-router";
import { ErrorBoundary } from "react-error-boundary";
import ErrorFallback from "./common/components/error/ErrorFallback";
import { AppProvider } from "./common/settings/context/AppProvider";
import MainRoutes from "./common/router/MainRoutes";
import { useThemeAndContent } from "./hooks/useThemeAndContent";
import { MsalLoginProvider } from "./MsalLogin";
import { store } from "./store";
import { Provider } from "react-redux";

interface Props {
  msalInstance: PublicClientApplication;
}

const App: FC<Props> = ({ msalInstance }) => {
  const { theme, content } = useThemeAndContent();
  const navigate = useNavigate();

  return (
    <MsalProvider instance={msalInstance}>
      <MsalLoginProvider>
        <ErrorBoundary
          FallbackComponent={ErrorFallback}
          onReset={() => navigate("/")}
        >
          <Provider store={store}>
            <AppProvider theme={theme} content={content}>
              <AuthenticatedTemplate>
                <MainRoutes />
              </AuthenticatedTemplate>
              <UnauthenticatedTemplate>
                <MainRoutes />
              </UnauthenticatedTemplate>
            </AppProvider>
          </Provider>
        </ErrorBoundary>
      </MsalLoginProvider>
    </MsalProvider>
  );
};

export default App;
