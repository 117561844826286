import {
  FC,
  Key,
  Reducer,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useReducer,
  useRef,
  useState,
} from "react";
import {
  Button,
  Col,
  Input,
  Row,
  Select,
  Table,
  Tag,
  Typography,
  Form,
  message,
  Spin,
  List,
  Checkbox,
} from "antd";
import {
  CopyOutlined,
  LeftOutlined,
  LoadingOutlined,
  RightOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import { ReportData } from "../../common/interfaces/ReportData";
import { ColumnsType, TableProps } from "antd/es/table";
import { FormatDisplayDateTime } from "../../common/utilities/format";
import { ReportStatus } from "../../common/enums";
import {
  useExportReportsUsersMutation,
  useGetReportsMutation,
  useGetReportsUserClinicsQuery,
  useGetReportsUserDomainsQuery,
  useGetReportsUserGroupsQuery,
} from "../../services/reports";
import { ReportsUserListRequestDto } from "../../common/interfaces/ReportsUserListRequestDto";
import { TimeRange } from "../../common/enums/TimeRange";
import { SorterResult, TableRowSelection } from "antd/es/table/interface";
import { DownloadFile } from "../../common/utilities/converter";
import "./Reports.style.scss";
import useRolesPermissionsAccess from "../../hooks/useRolesPermissionsAccess";
import { Popover } from "antd/lib";
import {
  CommonDropdownFilterProps,
  HeaderCellDropdownFilter,
  SelectOption,
} from "../../common/components/headercelldropdownfilter/HeaderCellDropdownFilter";
import {
  useAddRemoveGroupMemberMutation,
  useDeleteUsersMutation,
  useGetSignInLogsByUserIdQuery,
  useUpdateUserAccountStatusMutation,
  useUpdateUserCategoryMutation,
} from "../../services/users";
import { Categories } from "../../common/constants/Categories";
import copy from "copy-to-clipboard";
import { useGetGroupsQuery } from "../../services/groups";
import { MoreDotsIcon } from "../../assets/images/icons";
import ReportUsernamesSearch from "./search/ReportUsernamesSearch";
import ReportUsersNameSearch from "./search/ReportUsersNameSearch";

const { Title, Text, Paragraph } = Typography;
const { TextArea } = Input;

const StatusOptions = [
  { value: ReportStatus.Active, label: "Active" },
  { value: ReportStatus.Blocked, label: "Blocked" },
];

const UserTypeOptions = [
  { value: Categories.Provider, label: Categories.Provider },
  { value: Categories.ClinicStaff, label: Categories.ClinicStaff },
  { value: Categories.CoipaStaff, label: Categories.CoipaStaff },
  { value: Categories.NoDisplay, label: Categories.NoDisplay },
  { value: Categories.MSP, label: Categories.MSP },
]
  .slice()
  .sort((a, b) => a.label.localeCompare(b.label));

const TimeRangeOptions = [
  { value: TimeRange.LastWeek, label: "Within 1 Week" },
  { value: TimeRange.LastMonth, label: "Within 1 Month" },
  { value: TimeRange.LastQuarter, label: "Within 1 Quarter" },
  { value: TimeRange.LastSixMonths, label: "Within 6 Months" },
  { value: TimeRange.LastYear, label: "Within 1 Year" },
];

const Reports: FC = () => {
  const { isAdmin } = useRolesPermissionsAccess();
  const [getReports, { isLoading, isSuccess, data }] =
    useGetReportsMutation(undefined);
  const [userListRequest, setUserListRequest] =
    useState<ReportsUserListRequestDto>({});
  const [selectedRows, setSelectedRows] = useState<ReportData[]>([]);

  const [sortedInfo, setSortedInfo] = useState<SorterResult<ReportData>>({
    column: undefined,
    columnKey: "status",
    field: "status",
    order: null,
  });

  useEffect(() => {
    const request = getReports(userListRequest);
    return () => request.abort();
  }, [userListRequest, getReports]);

  const users = useMemo<ReportData[] | undefined>(() => {
    return data?.data?.map((user) => ({
      key: user.id,
      ...user,
    }));
  }, [data]);

  const [exportReportsUsers, { isLoading: exportLoading }] =
    useExportReportsUsersMutation();

  const refreshReportsList = () => {
    getReports(userListRequest);
  };

  const exportData = async () => {
    const exportFileData = await exportReportsUsers(userListRequest).unwrap();
    if (exportFileData) {
      DownloadFile(
        exportFileData.data.contentType,
        exportFileData.data.rawData,
        exportFileData.data.fileName
      );
    }
  };

  useEffect(() => {
    if (isSuccess)
      setUserListRequest({
        ...userListRequest,
        sortBy: sortedInfo.columnKey as string,
        isAscending: sortedInfo.order
          ? sortedInfo.order === "ascend"
          : undefined,
      });
  }, [sortedInfo.columnKey, sortedInfo.order]);

  const [resetFilterCounter, resetFilters] = useReducer<Reducer<number, void>>(
    (state) => state + 1,
    0
  );

  const [resetDisable, setresetDisable] = useState<boolean>(false);
  const [pageSize, setPageSize] = useState<number>(10);
  const [currentPage, setCurrentPage] = useState<number>(1);

  const handleResetAll = () => {
    setSelectedRows([]);
    setresetDisable(true);
    resetFilters();
    setUserListRequest({});
    setPageSize(10);
    setCurrentPage(1);
    setSortedInfo({});
  };

  const handleReportsTableChange: TableProps<any>["onChange"] = (
    pagination,
    filters,
    sorter
  ) => {
    setSortedInfo(sorter as SorterResult<any>);
    setresetDisable(false);
    setPageSize(pagination.pageSize as SetStateAction<number>);
    setCurrentPage(pagination.current as SetStateAction<number>);
  };

  const handleNameSelectedValue = (value: string) => {
    setresetDisable(false);
    setUserListRequest({ ...userListRequest, name: value });
  };

  const handleUsernameSelectedValue = (value: string) => {
    setresetDisable(false);
    setUserListRequest({ ...userListRequest, userName: value });
  };

  const handleDomainValues = (value: string[]) => {
    setresetDisable(false);
    setUserListRequest({ ...userListRequest, domains: value });
  };

  const handleClinicValues = (value: string[]) => {
    setresetDisable(false);
    setUserListRequest({ ...userListRequest, clinics: value });
  };

  const handleGroupValues = (value: string[]) => {
    setresetDisable(false);
    setUserListRequest({ ...userListRequest, groups: value });
  };

  const handleSelectedStatus = (value: ReportStatus) => {
    setresetDisable(false);
    setUserListRequest({ ...userListRequest, status: value });
  };

  const handleSelectedLastLogin = (value: TimeRange) => {
    setresetDisable(false);
    setUserListRequest({ ...userListRequest, lastLogin: value });
  };

  const handleSelectedCreateDate = (value: TimeRange) => {
    setresetDisable(false);
    setUserListRequest({ ...userListRequest, createDate: value });
  };

  const handleSelectedLastPasswordChange = (value: TimeRange) => {
    setresetDisable(false);
    setUserListRequest({ ...userListRequest, lastPasswordChange: value });
  };

  const appliedDomainFilters = userListRequest.domains;
  const appliedClinicFilters = userListRequest.clinics;
  const appliedGroupFilters = userListRequest.groups;

  const handleSelectedUserType = (value: string) => {
    setresetDisable(false);
    setUserListRequest({ ...userListRequest, category: value });
  };

  const columns: ColumnsType<ReportData> = [
    {
      title: (
        <span
          onClick={(e) => {
            e.stopPropagation();
          }}
          onKeyDown={(e) => {
            e.stopPropagation();
          }}
        >
          <UserNameHeaderCell
            key={resetFilterCounter}
            handleSelectedValue={handleUsernameSelectedValue}
          />
        </span>
      ),
      dataIndex: "userName",
      key: "userName",
      width: 400,
      sorter: true,
      showSorterTooltip: false,
      render: (value, record) => <ContentCell textValue={value} />,
      sortOrder: sortedInfo.columnKey === "userName" ? sortedInfo.order : null,
      fixed: "left",
    },
    {
      title: (
        <span
          onClick={(e) => {
            e.stopPropagation();
          }}
          onKeyDown={(e) => {
            e.stopPropagation();
          }}
        >
          <NameHeaderCell
            key={resetFilterCounter}
            handleSelectedValue={handleNameSelectedValue}
          />
        </span>
      ),
      dataIndex: "name",
      key: "name",
      width: 250,
      sorter: true,
      showSorterTooltip: false,
      render: (value, record) => <ContentCell textValue={value} />,
      sortOrder: sortedInfo.columnKey === "name" ? sortedInfo.order : null,
    },
    {
      title: (
        <span
          onClick={(e) => {
            e.stopPropagation();
          }}
          onKeyDown={(e) => {
            e.stopPropagation();
          }}
        >
          <DomainHeaderCell
            apply={handleDomainValues}
            applied={appliedDomainFilters}
            gotReports={isSuccess}
          />
        </span>
      ),
      dataIndex: "domain",
      key: "domain",
      width: 300,
      sortOrder: sortedInfo.columnKey === "domain" ? sortedInfo.order : null,
      sorter: true,
      showSorterTooltip: false,
      render: (value, record) => <ContentCell textValue={value} />,
    },
    {
      title: (
        <span
          onClick={(e) => {
            e.stopPropagation();
          }}
          onKeyDown={(e) => {
            e.stopPropagation();
          }}
        >
          <ClinicHeaderCell
            apply={handleClinicValues}
            applied={appliedClinicFilters}
            gotReports={isSuccess}
          />
        </span>
      ),
      dataIndex: "primaryGroupName",
      key: "primaryGroupName",
      width: 400,
      sortOrder:
        sortedInfo.columnKey === "primaryGroupName" ? sortedInfo.order : null,
      sorter: true,
      showSorterTooltip: false,
      render: (value, record) => <ContentCell textValue={value} />,
    },
    {
      title: (
          <UsertypeCell
            key={resetFilterCounter}
            handleSelectedUserType={handleSelectedUserType}
          />
      ),
      dataIndex: "category",
      key: "category",
      width: 200,
      sorter: true,
      sortOrder:
        sortedInfo.columnKey === "category" ? sortedInfo.order : null,
      showSorterTooltip: false,
      align: "center",
      render: (value, record) => (
        <UserType
          refreshReportsList={refreshReportsList}
          reportsData={record}
          selectedRows={selectedRows}
        />
      ),
    },
    {
      title: (
        <span
          onClick={(e) => {
            e.stopPropagation();
          }}
          onKeyDown={(e) => {
            e.stopPropagation();
          }}
        >
          <GroupsHeaderCell
            apply={handleGroupValues}
            applied={appliedGroupFilters}
            gotReports={isSuccess}
          />
        </span>
      ),
      dataIndex: "groups",
      key: "groups",
      width: 300,
      sorter: true,
      sortOrder: sortedInfo.columnKey === "groups" ? sortedInfo.order : null,
      showSorterTooltip: false,
      render: (value, record) => (
        <GroupItBelongsCell
          reportsData={record}
          refreshReportsList={refreshReportsList}
          selectedRows={selectedRows}
        />
      ),
    },
    {
      title: (
        <span
          onClick={(e) => {
            e.stopPropagation();
          }}
          onKeyDown={(e) => {
            e.stopPropagation();
          }}
        >
          <CreateDateHeaderCell
            key={resetFilterCounter}
            handleSelectedCreateDate={handleSelectedCreateDate}
          />
        </span>
      ),
      dataIndex: "createDate",
      key: "createDate",
      width: 200,
      sorter: true,
      sortOrder:
        sortedInfo.columnKey === "createDate" ? sortedInfo.order : null,
      showSorterTooltip: false,
      render: (value, record) => <ContentCell dateValue={value} />,
    },
    {
      title: (
        <span
          onClick={(e) => {
            e.stopPropagation();
          }}
          onKeyDown={(e) => {
            e.stopPropagation();
          }}
        >
          <LastLoginHeaderCell
            key={resetFilterCounter}
            handleSelectedLastLogin={handleSelectedLastLogin}
          />
        </span>
      ),
      dataIndex: "lastLogin",
      key: "lastLogin",
      width: 200,
      sorter: true,
      sortOrder: sortedInfo.columnKey === "lastLogin" ? sortedInfo.order : null,
      showSorterTooltip: false,
      render: (value, record) => <LastLoginCell reportData={record} />,
    },
    {
      title: (
        <div className="reports_filter">
          <Title level={4}>&nbsp;</Title>
          <Title level={4} className="reports_filter_title mb-0 mt-1">
            Temporary Password
          </Title>
        </div>
      ),
      dataIndex: "temporaryPassword",
      key: "temporaryPassword",
      width: 230,
      render: (value, record) =>
        record.temporaryPassword && <TemporaryPasswordCell record={record} />,
    },
    {
      title: (
        <span
          onClick={(e) => {
            e.stopPropagation();
          }}
          onKeyDown={(e) => {
            e.stopPropagation();
          }}
        >
          <LastPasswordChangeHeaderCell
            key={resetFilterCounter}
            handleSelectedLastPasswordChange={handleSelectedLastPasswordChange}
          />
        </span>
      ),
      dataIndex: "lastPasswordChange",
      key: "lastPasswordChange",
      width: 300,
      sorter: true,
      sortOrder:
        sortedInfo.columnKey === "lastPasswordChange" ? sortedInfo.order : null,
      showSorterTooltip: false,
      render: (value, record) => <ContentCell dateValue={value} />,
    },
    {
      title: (
        <span
          onClick={(e) => {
            e.stopPropagation();
          }}
          onKeyDown={(e) => {
            e.stopPropagation();
          }}
        >
          <StatusHeaderCell
            key={resetFilterCounter}
            handleSelectedStatus={handleSelectedStatus}
          />
        </span>
      ),
      dataIndex: "status",
      key: "status",
      width: 150,
      sorter: true,
      sortOrder: sortedInfo.columnKey === "status" ? sortedInfo.order : null,
      showSorterTooltip: false,
      align: "center",
      render: (value, record) => (
        <ContentCell
          refreshReportsList={refreshReportsList}
          reportsData={record}
          selectedRows={selectedRows}
        />
      ),
    },
  ];

  const { hasExportReports } = useRolesPermissionsAccess();

  const handleClose = (tagValue: string) => {
    setUserListRequest((prevUserListRequest) => ({
      ...prevUserListRequest,
      ...Object.fromEntries(
        Object.entries(prevUserListRequest).map(([key, value]) => [
          key,
          Array.isArray(value)
            ? (value as string[]).filter((item) => item !== tagValue)
            : value,
        ])
      ),
    }));
  };

  const newColumns = isAdmin
    ? columns
    : columns.filter((item) => item.key !== "temporaryPassword");

  const rowSelection: TableRowSelection<ReportData> = {
    onChange: (_selectedRowKeys: Key[], selectedRows: ReportData[]) => {
      setSelectedRows(selectedRows);
      setresetDisable(selectedRows.length > 0 ? false : resetDisable);
    },
    selectedRowKeys: selectedRows.map(row => row.key),
    type: "checkbox",
  };

  return (
    <div className="reports">
      <Table
        className="reports_table"
        rowSelection={isAdmin ? rowSelection : undefined}
        columns={newColumns}
        dataSource={users}
        onChange={handleReportsTableChange}
        scroll={{ x: 2000 }}
        pagination={{
          hideOnSinglePage: true,
          pageSize: pageSize,
          current: currentPage,
          position: ["bottomCenter"],
          prevIcon: (
            <Button className="reports_custom_pagination_btn prev">
              <LeftOutlined />
              Prev
            </Button>
          ),
          nextIcon: (
            <Button className="reports_custom_pagination_btn next">
              Next <RightOutlined />
            </Button>
          ),
        }}
        title={() => (
          <Row justify="space-between" align="middle" gutter={[20, 0]}>
            <Col xs={24} md={14} xl={18} className="d-none d-md-block">
              {selectedRows.length ? <Text className="me-2 reports_selected">{`Selected (${selectedRows.length})`}</Text> : null}
              {Object.entries(userListRequest).map(([key, value]) => (
                <span key={key}>
                  {Array.isArray(value) &&
                    value.map((item, index) => (
                      <Tag
                        closable
                        key={`${item}_${index}`}
                        className="reports_table_cell reports_table_cell_tag reports_table_cell_tag_filter_value_tag me-1 mt-1"
                        onClose={() => handleClose(item)}
                      >
                        <span className="me-2">{item}</span>
                      </Tag>
                    ))}
                </span>
              ))}
            </Col>
            <Col xs={24} md={10} xl={6} className="d-flex justify-content-end">
              <Button
                type="text"
                size="large"
                className="me-2"
                disabled={resetDisable}
                onClick={handleResetAll}
              >
                Reset All Filters
              </Button>
              {hasExportReports && (
                <Button
                  size="large"
                  type="default"
                  icon={<UploadOutlined />}
                  loading={exportLoading}
                  onClick={exportData}
                >
                  {exportLoading ? "Loading..." : "Export Data"}
                </Button>
              )}
            </Col>
          </Row>
        )}
        loading={{
          size: "large",
          tip: "Loading...",
          spinning: isLoading,
          indicator: <LoadingOutlined />,
        }}
        sticky={{ offsetHeader: 72, offsetScroll: 10 }}
      />
    </div>
  );
};

type UserNameHeaderCellProps = {
  handleSelectedValue: (Params: string) => void;
};

const UserNameHeaderCell: FC<UserNameHeaderCellProps> = ({
  handleSelectedValue,
}) => {
  return (
    <span
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      <ReportUsernamesSearch handleSelectedValue={handleSelectedValue} />
    </span>
  );
};

type DomainHeaderCellProps = CommonDropdownFilterProps & {
  gotReports: boolean;
};

const DomainHeaderCell: FC<DomainHeaderCellProps> = ({
  apply,
  applied,
  gotReports,
}) => {
  const { data, isSuccess } = useGetReportsUserDomainsQuery(undefined, {
    skip: !gotReports,
  });

  const options = useMemo<SelectOption[]>(
    () =>
      data?.data.map((domains) => ({
        key: domains,
      })) ?? [],
    [data]
  );

  return (
    <HeaderCellDropdownFilter
      applied={applied}
      apply={apply}
      options={options}
      title="Domain"
      disabled={!isSuccess}
    />
  );
};

type ClinicHeaderCellProps = CommonDropdownFilterProps & {
  gotReports: boolean;
};

const ClinicHeaderCell: FC<ClinicHeaderCellProps> = ({
  applied,
  apply,
  gotReports,
}) => {
  const { data, isSuccess } = useGetReportsUserClinicsQuery(undefined, {
    skip: !gotReports,
  });

  const options = useMemo<SelectOption[]>(
    () =>
      data?.data.map((domains) => ({
        key: domains,
      })) ?? [],
    [data]
  );

  return (
    <HeaderCellDropdownFilter
      applied={applied}
      apply={apply}
      options={options}
      title="Clinic"
      disabled={!isSuccess}
    />
  );
};

type GroupsHeaderCellProps = CommonDropdownFilterProps & {
  gotReports: boolean;
};

const GroupsHeaderCell: FC<GroupsHeaderCellProps> = ({
  gotReports,
  applied,
  apply,
}) => {
  const { data, isSuccess } = useGetReportsUserGroupsQuery(undefined, {
    skip: !gotReports,
  });

  const options = useMemo<SelectOption[]>(
    () =>
      data?.data.map((domains) => ({
        key: domains,
      })) ?? [],
    [data]
  );

  return (
    <HeaderCellDropdownFilter
      applied={applied}
      apply={apply}
      options={options}
      title="Groups"
      disabled={!isSuccess}
    />
  );
};

type CreateDateHeaderCellProps = {
  handleSelectedCreateDate: (value: TimeRange) => void;
};

const CreateDateHeaderCell: FC<CreateDateHeaderCellProps> = ({
  handleSelectedCreateDate,
}) => {
  const handleChange = (value: TimeRange) => {
    handleSelectedCreateDate(value);
  };
  return (
    <span
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      <Row className="reports_filter">
        <Col span={24}>
          <Title level={4} className="reports_filter_title">
            Create Date
          </Title>
          <Select
            className="w-100"
            size="large"
            placeholder="View All"
            onChange={handleChange}
            allowClear
            popupClassName="reports_filter_popup"
          >
            {TimeRangeOptions.map((option) => (
              <Select.Option key={option.value} value={option.value}>
                {option.label}
              </Select.Option>
            ))}
          </Select>
        </Col>
      </Row>
    </span>
  );
};

type LastLoginHeaderCellProps = {
  handleSelectedLastLogin: (value: TimeRange) => void;
};

const LastLoginHeaderCell: FC<LastLoginHeaderCellProps> = ({
  handleSelectedLastLogin,
}) => {
  const handleChange = (value: TimeRange) => {
    handleSelectedLastLogin(value);
  };
  return (
    <span
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      <Row className="reports_filter">
        <Col span={24}>
          <Title level={4} className="reports_filter_title">
            Last Login
          </Title>
          <Select
            className="w-100"
            size="large"
            placeholder="View All"
            onChange={handleChange}
            allowClear
            popupClassName="reports_filter_popup"
          >
            {TimeRangeOptions.map((option) => (
              <Select.Option key={option.value} value={option.value}>
                {option.label}
              </Select.Option>
            ))}
          </Select>
        </Col>
      </Row>
    </span>
  );
};

type LastPasswordChangeHeaderCellProps = {
  handleSelectedLastPasswordChange: (value: TimeRange) => void;
};

const LastPasswordChangeHeaderCell: FC<LastPasswordChangeHeaderCellProps> = ({
  handleSelectedLastPasswordChange,
}) => {
  const handleChange = (value: TimeRange) => {
    handleSelectedLastPasswordChange(value);
  };

  return (
    <span
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      <Row className="reports_filter">
        <Col span={24}>
          <Title level={4} className="reports_filter_title">
            Last Password Change
          </Title>
          <Select
            className="w-100"
            size="large"
            placeholder="View All"
            onChange={handleChange}
            allowClear
            popupClassName="reports_filter_popup"
          >
            {TimeRangeOptions.map((option) => (
              <Select.Option key={option.value} value={option.value}>
                {option.label}
              </Select.Option>
            ))}
          </Select>
        </Col>
      </Row>
    </span>
  );
};

type StatusHeaderCellProps = {
  handleSelectedStatus: (value: ReportStatus) => void;
};

const StatusHeaderCell: FC<StatusHeaderCellProps> = ({
  handleSelectedStatus,
}) => {
  const handleChange = (value: ReportStatus) => {
    handleSelectedStatus(value);
  };
  return (
    <span
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      <Row className="reports_filter">
        <Col span={24}>
          <Title level={4} className="reports_filter_title">
            Status
          </Title>
          <Select
            className="w-100"
            size="large"
            placeholder="View All"
            onChange={handleChange}
            allowClear
            popupClassName="reports_filter_popup"
          >
            {StatusOptions.map((option) => (
              <Select.Option key={option.value} value={option.value}>
                {option.label}
              </Select.Option>
            ))}
          </Select>
        </Col>
      </Row>
    </span>
  );
};

type ContentCellProps = {
  textValue?: string;
  dateValue?: Date;
  reportsData?: ReportData;
  refreshReportsList?: (() => void) | undefined;
  selectedRows?: ReportData[];
};

const ContentCell: FC<ContentCellProps> = ({
  textValue,
  dateValue,
  reportsData,
  refreshReportsList,
  selectedRows,
}) => {
  return (
    <Row
      align="middle"
      justify={dateValue || reportsData !== undefined ? "center" : "start"}
    >
      <Col className={dateValue && "text-center"}>
        {reportsData !== undefined ? (
          <Status
            reportsData={reportsData}
            refreshReportsList={refreshReportsList}
            selectedRows={selectedRows}
          />
        ) : (
          <Text className="reports_table_cell mb-0">
            {dateValue ? FormatDisplayDateTime(dateValue) : textValue}
          </Text>
        )}
      </Col>
    </Row>
  );
};

type StatusProps = {
  reportsData: ReportData;
  refreshReportsList: (() => void) | undefined;
  selectedRows?: ReportData[];
};

const Status: FC<StatusProps> = ({
  reportsData,
  refreshReportsList,
  selectedRows,
}) => {
  const [disableUserForm] = Form.useForm();
  const { isAdmin } = useRolesPermissionsAccess();

  const [updateUserAccountStatusApi, { isLoading: updateUserLoading }] =
    useUpdateUserAccountStatusMutation();
  const [deleteUsers, { isLoading }] = useDeleteUsersMutation();
  const [isDeleteButtonDisabled, setDeleteButtonDisabled] =
    useState<boolean>(false);
  const [showConfirmButton, setShowConfirmButton] = useState<boolean>(false);

  const handleDeleteUser = () => {
    setDeleteButtonDisabled(true);
    setShowConfirmButton(true);
  };

  const userIds =
    selectedRows && selectedRows.length > 0
      ? selectedRows.map((item) => item.key)
      : [reportsData.key];

  const handleConfirmDelete = () => {
    deleteUsers(userIds)
      .unwrap()
      .then((res) => {
        if (res.success) {
          refreshReportsList?.();
          message.success("User deleted successfully!");
        } else {
          message.error(res.message);
        }
      })
      .catch((error) => {
        message.error(error.data.message || error.data.title);
      });
  };

  const handleDiscardDelete = () => {
    setDeleteButtonDisabled(false);
    setShowConfirmButton(false);
  };

  const handleSave = (accountEnabled: boolean) => {
    const userIds =
      selectedRows && selectedRows.length > 0
        ? selectedRows.map((item) => item.key)
        : [reportsData.key];

    disableUserForm.validateFields().then((values) => {
      const updatedProviderRequest = {
        userIds,
        accountEnabled: accountEnabled,
        reason: values.reason,
      };

      updateUserAccountStatusApi(updatedProviderRequest)
        .then(() => {
          message.success("User account status updated successfully");
          disableUserForm.resetFields();
          refreshReportsList && refreshReportsList();
        })
        .catch((error) => {
          message.error(error.data.message || error.data.title);
        });
    });
  };

  const content = (
    <Form form={disableUserForm}>
      <Row gutter={[0, 10]}>
        <Col span={24}>
          <Title level={5} className="mb-0">
            {reportsData?.status === ReportStatus.Active
              ? "Disable this account:"
              : "Unblock this account:"}
          </Title>
        </Col>
        <Col span={24}>
          {selectedRows && selectedRows.length > 0 ? (
            <List>
              {selectedRows.map((item: ReportData) => (
                <List.Item
                  key={item.key}
                  className="py-0 border-0 text-break"
                >
                  {item.userName}
                </List.Item>
              ))}
            </List>
          ) : (
            <Text>{reportsData.userName}</Text>
          )}
        </Col>
        <Col span={24}>
          <Form.Item
            name="reason"
            rules={[
              {
                required: true,
                message: "Reason is required",
              },
            ]}
          >
            <TextArea
              autoSize
              size="large"
              placeholder={`${reportsData?.status === ReportStatus.Active
                ? "Reason code to block user from login"
                : "Reason for unblocking..."
                }`}
            />
          </Form.Item>
        </Col>
        {reportsData?.status === ReportStatus.Blocked && (
          <Col span={24}>
            <Paragraph>
              Blocked {reportsData?.accountBlockedAt ? " on:" : ""}
              {reportsData?.accountBlockedAt
                ? FormatDisplayDateTime(reportsData?.accountBlockedAt, true)
                : ""}
            </Paragraph>
          </Col>
        )}
        <Col span={24}>
          {reportsData?.status === ReportStatus.Active ? (
            <Row gutter={[10, 10]}>
              <Col span={24}>
                <Button
                  className="w-100 reports_table_cell_tag_unblock_button"
                  size="large"
                  onClick={() => handleSave(false)}
                  loading={updateUserLoading}
                >
                  Block User
                </Button>
              </Col>
              {isAdmin && (
                <>
                  <Col span={24}>
                    <Button
                      className={`w-100 ${isDeleteButtonDisabled
                        ? "discard-delete-button"
                        : "delete-button"
                        }`}
                      size="large"
                      onClick={
                        isDeleteButtonDisabled
                          ? handleDiscardDelete
                          : handleDeleteUser
                      }
                    >
                      {isDeleteButtonDisabled
                        ? "Discard Delete"
                        : "Delete User"}
                    </Button>
                  </Col>
                  {showConfirmButton && (
                    <Col span={24}>
                      <Button
                        className="w-100 delete-button"
                        size="large"
                        onClick={handleConfirmDelete}
                        loading={isLoading}
                      >
                        Confirm Delete
                      </Button>
                    </Col>
                  )}
                </>
              )}
            </Row>
          ) : (
            <Button
              type="primary"
              className="w-100"
              size="large"
              onClick={() => handleSave(true)}
              loading={updateUserLoading}
            >
              Unblock User
            </Button>
          )}
        </Col>
      </Row>
    </Form>
  );

  return (
    <Popover
      content={isAdmin && content}
      trigger="click"
      placement="bottomRight"
      arrow={false}
      overlayClassName="reports_table_cell_popover"
    >
      <Tag
        bordered={false}
        color={
          reportsData?.status === ReportStatus.Active ? "#00b050" : "#ffc000"
        }
        className="reports_table_cell reports_table_cell_tag me-0"
      >
        {ReportStatus[reportsData?.status]}
      </Tag>
    </Popover>
  );
};

type UsertypeCellProps = {
  handleSelectedUserType: (value: string) => void;
};

const UsertypeCell: FC<UsertypeCellProps> = ({ handleSelectedUserType }) => {
  const handleChange = (value: string) => {
    handleSelectedUserType(value);
  };

  return (
    <Row
      className="reports_filter"
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      <Col span={24}>
        <Title level={4} className="reports_filter_title">
          User Type
        </Title>
        <Select
          className="w-100"
          size="large"
          placeholder="View All"
          onChange={handleChange}
          allowClear
          popupClassName="reports_filter_popup"
        >
          {UserTypeOptions.map((option) => (
            <Select.Option key={option.value} value={option.value}>
              {option.label}
            </Select.Option>
          ))}
        </Select>
      </Col>
    </Row>
  );
};

type UserTypeProp = {
  refreshReportsList: (() => void) | undefined;
  reportsData: ReportData;
  selectedRows?: ReportData[];
};

const UserType: FC<UserTypeProp> = ({
  refreshReportsList,
  reportsData,
  selectedRows,
}) => {
  const { isAdmin } = useRolesPermissionsAccess();
  const [updateUserCategory, { isLoading }] = useUpdateUserCategoryMutation();
  const userIds =
    selectedRows && selectedRows.length > 0
      ? selectedRows.map((item) => item.key)
      : [reportsData.key];

  const handleSubmit = (category: string) => {
    updateUserCategory({
      userIds,
      category: category,
    })
      .unwrap()
      .then((result) => {
        if (result.success) {
          refreshReportsList?.();
          message.success("User Type updated succesfully");
        } else {
          message.error(result.message);
        }
      })
      .catch((err) => {
        message.error(err.data.message);
      });
  };

  const categoryButton = (category: string, categoryPrimaryShow: Boolean) => (
    <Col span={24} key={category}>
      <Button
        size="large"
        type={`${reportsData.category === category && categoryPrimaryShow
          ? "primary"
          : "default"
          }`}
        className="w-100"
        onClick={() =>
          reportsData.category !== category && handleSubmit(category)
        }
      >
        {category}
      </Button>
    </Col>
  );

  const categoriesItems = [
    Categories.Provider,
    Categories.ClinicStaff,
    Categories.CoipaStaff,
    Categories.NoDisplay,
    Categories.MSP,
  ]
    .slice()
    .sort((a, b) => a.localeCompare(b));

  const categoryPrimaryShow =
    !selectedRows || (selectedRows && selectedRows.length === 0);

  const content = (
    <Row gutter={[0, 10]}>
      <Col span={24}>
        <Title level={5} className="mb-0">
          User Type:
        </Title>
      </Col>
      <Col span={24}>
        {selectedRows && selectedRows.length > 0 ? (
          <List>
            {selectedRows.map((item: ReportData) => (
              <List.Item
                key={item.key}
                className="py-0 border-0 text-break"
              >
                {item.userName}
              </List.Item>
            ))}
          </List>
        ) : (
          <Text>{reportsData.userName}</Text>
        )}
      </Col>
      <Col span={24}>
        <Spin
          size="large"
          tip="Loading..."
          spinning={isLoading}
          indicator={<LoadingOutlined />}
        >
          <Row gutter={[6, 6]}>
            {categoriesItems.map((category: string) =>
              categoryButton(category, categoryPrimaryShow)
            )}
          </Row>
        </Spin>
      </Col>
    </Row>
  );
  return isAdmin ? (
    <Popover
      content={content}
      trigger="click"
      placement="bottomRight"
      arrow={false}
      overlayClassName="reports_table_cell_popover"
    >
      <Tag
        bordered={false}
        className="reports_table_cell reports_table_cell_tag reports_table_cell_tag_user_type me-0 justify-content-center"
        color="#f2eef1"
      >
        {reportsData.category ?? "-"}
      </Tag>
    </Popover>
  ) : (
    <Text className="reports_table_cell mb-0">
      {reportsData.category ?? "-"}
    </Text>
  );
};

type TemporaryPasswordCellProps = {
  record: ReportData;
};

const TemporaryPasswordCell: FC<TemporaryPasswordCellProps> = ({ record }) => {
  const tempPwdRef = useRef<any>(null);

  const copyToClipboard = useCallback(() => {
    const copyText = tempPwdRef.current?.input?.value;

    if (copyText) {
      if (copy(copyText)) {
        message.success("Copied!");
      } else {
        message.error("Copy to Clipboard failed");
      }
    }
  }, []);

  return (
    <Row align="middle" justify="center">
      <Col span={21}>
        <Input.Password
          readOnly
          size="large"
          ref={tempPwdRef}
          value={record.temporaryPassword}
          bordered={false}
          className="reports_temp-pwd"
        />
      </Col>
      <Col span={1}>
        <CopyOutlined
          title="Copy to clipboard"
          style={{ cursor: "pointer" }}
          onClick={copyToClipboard}
        />
      </Col>
    </Row>
  );
};

type GroupItBelongsCellProps = {
  reportsData: ReportData;
  refreshReportsList?: (() => void) | undefined;
  selectedRows?: ReportData[];
};

const GroupItBelongsCell: FC<GroupItBelongsCellProps> = ({
  reportsData,
  refreshReportsList,
  selectedRows,
}) => {
  const { isAdmin } = useRolesPermissionsAccess();
  const { data, isLoading } = useGetGroupsQuery(undefined);
  const [open, setOpen] = useState(false);
  const [addRemoveGroupMember, { isLoading: savingGroupMember }] =
    useAddRemoveGroupMemberMutation();

  const groups = useMemo(
    () =>
      data?.data.map((group) => ({
        label: group.name,
        value: group.id,
      })),
    [data]
  );

  const [searchedGroups, setSearchedGroups] = useState(groups);

  const initiallySelectedGroups = useMemo(
    () =>
      reportsData.groupIds && !(selectedRows && selectedRows.length)
        ? reportsData.groupIds.split(",")
        : [],
    [reportsData.groupIds, selectedRows]
  );

  const [selectedGroups, setSelectedGroups] = useState<string[]>(
    initiallySelectedGroups
  );
  const [removedGroups, setRemovedGroups] = useState<string[]>([]);

  useEffect(() => {
    setSelectedGroups(initiallySelectedGroups);
  }, [initiallySelectedGroups]);

  const handleCheckboxChange = (value: string) => {
    setSelectedGroups((prevSelected) => {
      if (prevSelected.includes(value)) {
        setRemovedGroups((prevDeselected) => {
          return prevDeselected.includes(value) ||
            !initiallySelectedGroups.includes(value)
            ? prevDeselected
            : [...prevDeselected, value];
        });
        return prevSelected.filter((id) => id !== value);
      } else {
        setRemovedGroups((prevDeselected) =>
          prevDeselected.filter((id) => id !== value)
        );
        return [...prevSelected, value];
      }
    });
  };

  const cancel = () => {
    setOpen(false);
  };

  const handleOpenChange = (newOpen: boolean) => {
    setOpen(newOpen);
  };

  const userIds =
    selectedRows && selectedRows.length > 0
      ? selectedRows.map((item) => item.key)
      : [reportsData.key];

  const handleApply = () => {
    addRemoveGroupMember({
      userIds,
      newGroups: selectedGroups,
      removedGroups: removedGroups,
    })
      .unwrap()
      .then((result) => {
        if (result.success) {
          refreshReportsList?.();
          message.success("Groups updated succesfully");
        } else {
          message.error(result.message);
        }
      })
      .catch((err) => {
        message.error(err.data.message);
      });
  };

  const onSearchChange = (value: string) => {
    setSearchedGroups(
      groups?.filter((g) =>
        g.label?.toLowerCase().includes(value.toLowerCase())
      )
    );
  };

  const content = (
    <Row gutter={[0, 10]}>
      <Col span={24}>
        <Title level={5} className="mb-0">
          Groups:
        </Title>
      </Col>
      <Col span={24}>
        {selectedRows && selectedRows.length > 0 ? (
          <List>
            {selectedRows.map((item: ReportData) => (
              <List.Item
                key={item.key}
                className="py-0 border-0 text-break"
              >
                {item.userName}
              </List.Item>
            ))}
          </List>
        ) : (
          <Text>{reportsData.userName}</Text>
        )}
      </Col>
      <Col span={24}>
        <Input
          placeholder="Search"
          allowClear
          size="large"
          onChange={(e) => onSearchChange(e.target.value)}
          className="mb-2"
        />
      </Col>
      <Col span={24}>
        <Spin
          size="large"
          tip="Loading..."
          spinning={isLoading}
          indicator={<LoadingOutlined />}
        >
          <List
            className="reports_table_cell_groups_list"
            dataSource={searchedGroups ?? groups}
            renderItem={(item) => (
              <List.Item className="d-flex align-items-center reports_table_cell_groups_list_item">
                <Checkbox
                  className="fields_checkbox"
                  checked={selectedGroups.includes(item.value as string)}
                  onChange={() => handleCheckboxChange(item.value as string)}
                >
                  {item.label}
                </Checkbox>
              </List.Item>
            )}
          />
        </Spin>
      </Col>
      <Col span={24}>
        <div className="d-flex justify-content-around my-1 mt-3">
          <Button
            type="default"
            size="large"
            className="w-100 me-1"
            onClick={cancel}
          >
            Cancel
          </Button>
          <Button
            type="primary"
            size="large"
            className="w-100 ms-1"
            loading={savingGroupMember}
            onClick={handleApply}
            disabled={groups?.length === 0}
          >
            Apply
          </Button>
        </div>
      </Col>
    </Row>
  );

  return (
    <Row align="middle" justify="start">
      <Col>
        {isAdmin ? (
          <Popover
            content={content}
            trigger="click"
            placement="bottom"
            arrow={false}
            overlayClassName="reports_table_cell_popover"
            open={open}
            onOpenChange={handleOpenChange}
          >
            <Tag
              bordered={false}
              className="reports_table_cell reports_table_cell_tag reports_table_cell_tag_groups me-0"
              color="#f2eef1"
            >
              {reportsData.groups}
            </Tag>
          </Popover>
        ) : (
          <Text className="reports_table_cell mb-0">{reportsData.groups}</Text>
        )}
      </Col>
    </Row>
  );
};

type LastLoginCellProps = {
  reportData: ReportData;
};

const LastLoginCell: FC<LastLoginCellProps> = ({ reportData }) => {
  const { isAdmin } = useRolesPermissionsAccess();
  const [isSignInPopoverVisible, setSignInPopoverVisible] = useState(false);
  const { data, isFetching } = useGetSignInLogsByUserIdQuery(reportData.key, {
    skip: !isSignInPopoverVisible,
  });

  const content = (
    <Row>
      <Col span={24}>
        <Title level={5}>
          User Logins in last 7 days <small>(Local Time)</small>
        </Title>
      </Col>
      <Col span={24}>
        <Paragraph className="mb-2">{reportData.userName}</Paragraph>
      </Col>
      <Col span={24} className="reports_table_cell_signin">
        <Spin
          size="large"
          tip="Loading..."
          spinning={isFetching}
          indicator={<LoadingOutlined />}
        >
          {data?.data.length ? (
            <List
              className="reports_table_cell_signin_list"
              dataSource={data?.data}
              renderItem={(item) => (
                <List.Item
                  className="d-flex align-items-center reports_table_cell_signin_list_item mb-1 px-2"
                  style={{
                    backgroundColor:
                      item.status === "Success" ? "#ddedde" : "#fce1e3",
                  }}
                >
                  {`${FormatDisplayDateTime(item.createdDateTime)} | `}
                  {item.appDisplayName
                    ? `${item.appDisplayName} | ${item.status}`
                    : item.status}
                </List.Item>
              )}
            />
          ) : (
            <Text>{!isFetching && "No Logins"}</Text>
          )}
        </Spin>
      </Col>
    </Row>
  );

  const handleSignInPopoverOpenChange = (open: boolean) => {
    setSignInPopoverVisible(open);
  };

  return (
    <Row align="middle" justify="center" gutter={[5, 0]}>
      <Col>
        {reportData.lastLogin && (
          <Text className="reports_table_cell mb-0">
            {FormatDisplayDateTime(reportData.lastLogin)}
          </Text>
        )}
      </Col>
      {isAdmin && (
        <Col>
          {reportData.lastLogin && (
            <Popover
              arrow={false}
              content={content}
              trigger="hover"
              overlayClassName="reports_table_cell_signin_popover"
              onOpenChange={handleSignInPopoverOpenChange}
              autoAdjustOverflow={true}
            >
              <Tag bordered={false} className="m-0 px-1">
                <MoreDotsIcon className="mt-1" />
              </Tag>
            </Popover>
          )}
        </Col>
      )}
    </Row>
  );
};

type NameHeaderCellProps = {
  handleSelectedValue: (Params: string) => void;
};

const NameHeaderCell: FC<NameHeaderCellProps> = ({ handleSelectedValue }) => {
  return (
    <span
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      <ReportUsersNameSearch handleSelectedValue={handleSelectedValue} />
    </span>
  );
};

export default Reports;
