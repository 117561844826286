import {
  InteractionStatus,
  InteractionRequiredAuthError,
} from "@azure/msal-browser";
import { useMsal, useIsAuthenticated } from "@azure/msal-react";
import { FC, PropsWithChildren, useEffect } from "react";
import { getCachedScopes } from "./common/settings/config/AzureAuthenticationConfig";

export const MsalLoginProvider: FC<PropsWithChildren> = ({ children }) => {
  const { instance, accounts, inProgress } = useMsal();
  const isAuthenticated = useIsAuthenticated();

  useEffect(() => {
    const scopes = getCachedScopes();
    if (inProgress === InteractionStatus.None) {
      if (accounts?.length > 0) {
        instance
          .acquireTokenSilent({
            scopes,
            account: accounts[0],
          })
          .catch(async (error) => {
            if (error instanceof InteractionRequiredAuthError) {
              instance
                .acquireTokenRedirect({
                  scopes,
                  redirectStartPage: window.location.origin,
                })
                .catch((err) => {
                  console.error(err);
                });
            }
          });
      }
    }
  }, [isAuthenticated, instance, inProgress, accounts]);

  return <>{children}</>;
};
