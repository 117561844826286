import { useEffect, useState } from "react";
import { useDebounced } from "./useDebounced";
 
interface ResponsiveState {
  windowWidth: number;
  isMobile: boolean;
}
 
const minDesktopWidth = 768;
 
const useResponsive = (): ResponsiveState => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [isMobile, setIsMobile] = useState(window.innerWidth < minDesktopWidth);
 
  const handleResize = useDebounced(200, () => {
    const width = window.innerWidth;
    setWindowWidth(width);
    setIsMobile(width < minDesktopWidth);
  });
 
  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [handleResize]);
 
  return { windowWidth, isMobile };
};
 
export default useResponsive;
 