import { useIsAuthenticated } from "@azure/msal-react";
import { useGetUserRolesAndPermissionsQuery } from "../services/roleGroups";
import { Roles } from "../common/constants/Roles";
import { Permissions } from "../common/constants/Permissions";

interface RolesAndPermissionsAccess {
  isAdmin: boolean;
  isCoipaStaff: boolean;
  isCoipaFinance: boolean;
  isProviders: boolean;
  isClinicSupport: boolean;
  hasAddAppRole: boolean;
  hasEditAppRole: boolean;
  hasDeleteAppRole: boolean;
  hasAddUser: boolean;
  hasEditUser: boolean;
  hasDeleteUser: boolean;
  hasAddProvider: boolean;
  hasEditProvider: boolean;
  hasDeleteProvider: boolean;
  hasAddClinic: boolean;
  hasEditClinic: boolean;
  hasDeleteClinic: boolean;
  hasViewReports: boolean;
  hasExportReports: boolean;
  hasExportUsers: boolean;
  hasExportProviders: boolean;
  hasExportClinics: boolean;
}

const useRolesPermissionsAccess = (): RolesAndPermissionsAccess => {
  const isAuthenticated = useIsAuthenticated();
  const { data } = useGetUserRolesAndPermissionsQuery(undefined, {
    skip: !isAuthenticated,
  });

  const { roles, permissions } = data?.data || {};

  return {
    isAdmin: roles?.includes(Roles.Admin) ?? false,
    isCoipaStaff: roles?.includes(Roles.COIPA_Staff) ?? false,
    isCoipaFinance: roles?.includes(Roles.COIPA_Finance) ?? false,
    isProviders: roles?.includes(Roles.Providers) ?? false,
    isClinicSupport: roles?.includes(Roles.Clinic_Support) ?? false,
    hasAddAppRole: permissions?.includes(Permissions.AddAppRole) ?? false,
    hasEditAppRole: permissions?.includes(Permissions.EditAppRole) ?? false,
    hasDeleteAppRole: permissions?.includes(Permissions.DeleteAppRole) ?? false,
    hasAddUser: permissions?.includes(Permissions.AddUser) ?? false,
    hasEditUser: permissions?.includes(Permissions.EditUser) ?? false,
    hasDeleteUser: permissions?.includes(Permissions.DeleteUser) ?? false,
    hasAddProvider: permissions?.includes(Permissions.AddProvider) ?? false,
    hasEditProvider: permissions?.includes(Permissions.EditProvider) ?? false,
    hasDeleteProvider:
      permissions?.includes(Permissions.DeleteProvider) ?? false,
    hasAddClinic: permissions?.includes(Permissions.AddClinic) ?? false,
    hasEditClinic: permissions?.includes(Permissions.EditClinic) ?? false,
    hasDeleteClinic: permissions?.includes(Permissions.DeleteClinic) ?? false,
    hasViewReports: permissions?.includes(Permissions.ViewReports) ?? false,
    hasExportReports: permissions?.includes(Permissions.ExportReports) ?? false,
    hasExportUsers: permissions?.includes(Permissions.ExportUsers) ?? false,
    hasExportProviders:
      permissions?.includes(Permissions.ExportProviders) ?? false,
    hasExportClinics: permissions?.includes(Permissions.ExportClinics) ?? false,
  };
};

export default useRolesPermissionsAccess;
