import Icon, {
  CustomIconComponentProps,
} from "@ant-design/icons/lib/components/Icon";

const RolesSvg = () => (
  <svg
    height="35"
    viewBox="0 0 64 64"
    width="35"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="Cut_line_icon" data-name="Cut line icon">
      <path d="m50.971 35.9h-.018a11.675 11.675 0 0 0 -8.308 3.455 12.045 12.045 0 0 0 -1.467 1.779l-8.426-4.913v-8.16a11.779 11.779 0 1 0 -10.1-18.936.75.75 0 0 0 1.188.916 10.281 10.281 0 1 1 15.548 13.407 7.952 7.952 0 0 0 -4.756-5.4 4.765 4.765 0 1 0 -5.395-.213 8.015 8.015 0 0 0 -5.2 4.991 10.284 10.284 0 0 1 -1.524-10.517.75.75 0 1 0 -1.381-.584 11.779 11.779 0 0 0 10.117 16.338v8.159l-8.419 4.911a12.059 12.059 0 0 0 -1.447-1.761 11.689 11.689 0 0 0 -8.333-3.472h-.01a11.785 11.785 0 1 0 11.4 14.809.75.75 0 1 0 -1.44-.415 10.929 10.929 0 0 1 -2.277 4.2 7.929 7.929 0 0 0 -4.978-5.184 4.767 4.767 0 1 0 -5.412 0 7.971 7.971 0 0 0 -4.974 5.19 10.262 10.262 0 0 1 7.682-17.1h.008a10.294 10.294 0 0 1 10.271 10.288.749.749 0 0 0 .746.753.749.749 0 0 0 .75-.746 11.579 11.579 0 0 0 -1.24-5.264l8.424-4.911 8.427 4.914a11.613 11.613 0 0 0 -1.249 5.258 11.785 11.785 0 1 0 11.793-11.792zm-37.931 6.221a3.27 3.27 0 1 1 -3.27 3.271 3.269 3.269 0 0 1 3.27-3.271zm0 8.22a6.466 6.466 0 0 1 6.424 5.364 10.233 10.233 0 0 1 -12.846 0 6.488 6.488 0 0 1 6.422-5.364zm19.053-39.587h.136a3.264 3.264 0 0 1 3.123 3.4 3.27 3.27 0 0 1 -3.258 3.135c-.044 0-.089 0-.135 0a3.27 3.27 0 0 1 .134-6.537zm-.2 8.215a6.475 6.475 0 0 1 6.186 5.621 10.223 10.223 0 0 1 -6.071 2.01h-.013a10.228 10.228 0 0 1 -6.726-2.523 6.527 6.527 0 0 1 6.623-5.108zm12.656 36.731a6.527 6.527 0 0 1 12.843 0 10.219 10.219 0 0 1 -12.843 0zm6.421-7.042a3.27 3.27 0 1 1 3.26-3.269 3.274 3.274 0 0 1 -3.26 3.272zm7.682 5.83a7.937 7.937 0 0 0 -4.977-5.183 4.766 4.766 0 1 0 -5.41.005 8 8 0 0 0 -4.974 5.19 10.278 10.278 0 0 1 7.663-17.1h.016a10.265 10.265 0 0 1 7.682 17.09z" />
    </g>
  </svg>
);

export const RolesIcon = (props: Partial<CustomIconComponentProps>) => (
  <Icon component={RolesSvg} {...props} />
);
