import { FC } from "react";
import { Route, Routes } from "react-router-dom";
import { Layout } from "antd";
import useTheme from "../settings/theme/UseTheme";
import MainHeader from "../components/header/MainHeader";
import MainFooter from "../components/footer/MainFooter";
import { Helmet } from "react-helmet";
import PageRoutes from "./components/PageRoutes";
import AdminFlowRoutes from "./components/AdminFlowRoutes";
import RoleAccess from "../components/access/RoleAccess";
import { Roles } from "../constants/Roles";

const { Content } = Layout;

const MainRoutes: FC = () => {
  const { favicon, title } = useTheme();

  return (
    <>
      <Helmet>
        <link rel="shortcut icon" type="image/png" href={favicon} />
        <title>{title}</title>
      </Helmet>
      <Layout>
        <MainHeader />
        <Content className="main-content">
          <Routes>
            <Route path="/*" element={<PageRoutes />} />
            <Route element={<RoleAccess userRoles={[Roles.Admin]} />}>
              <Route path="/admin/*" element={<AdminFlowRoutes />} />
            </Route>
          </Routes>
        </Content>
        <MainFooter />
      </Layout>
    </>
  );
};

export default MainRoutes;
