import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { getAuthToken } from "../common/settings/config/AzureAuthenticationConfig";
import { UserDepartmentData } from "../common/interfaces/UserDepartmentData";

export const userDepartmentsApi = createApi({
  reducerPath: "userDepartmentsApi",
  baseQuery: fetchBaseQuery({
    baseUrl: "/api/userdepartments",
    prepareHeaders: async (headers) => {
      const token = await getAuthToken();
      headers.set("Authorization", `Bearer ${token}`);
      return headers;
    },
  }),
  endpoints: (builder) => ({
    createUserDepartment: builder.mutation<
      ResponseDto<boolean>,
      UserDepartmentData
    >({
      query: (args) => ({
        url: `/`,
        method: "POST",
        body: args,
      }),
    }),
    updateUserDepartment: builder.mutation<
      ResponseDto<boolean>,
      UserDepartmentData[]
    >({
      query: (data) => ({
        url: ``,
        method: "PATCH",
        body: data,
      }),
    }),
    deleteUserDepartment: builder.mutation<ResponseDto<boolean>, number>({
      query: (id) => ({
        url: `/${id}`,
        method: "DELETE",
      }),
    }),
    getUserDepartments: builder.query<
      ResponseDto<UserDepartmentData[]>,
      undefined
    >({
      query: () => `/`,
    }),
  }),
});

type ResponseDto<T> = {
  success: boolean;
  data: T;
  message?: string | undefined;
};

export const {
  useCreateUserDepartmentMutation,
  useUpdateUserDepartmentMutation,
  useDeleteUserDepartmentMutation,
  useGetUserDepartmentsQuery,
} = userDepartmentsApi;
