import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { getAuthToken } from "../common/settings/config/AzureAuthenticationConfig";
import { SpecialityData } from "../common/interfaces/SpecialityData";

export const specialitiesApi = createApi({
  reducerPath: "specialitiesApi",
  baseQuery: fetchBaseQuery({
    baseUrl: "/api/specialities",
    prepareHeaders: async (headers) => {
      const token = await getAuthToken();
      headers.set("Authorization", `Bearer ${token}`);
      return headers;
    },
  }),
  endpoints: (builder) => ({
    createSpecialities: builder.mutation<ResponseDto<boolean>, SpecialityData>({
      query: (args) => ({
        url: `/`,
        method: "POST",
        body: args,
      }),
    }),
    updateSpecialitites: builder.mutation<
      ResponseDto<boolean>,
      SpecialityData[]
    >({
      query: (data) => ({
        url: ``,
        method: "PATCH",
        body: data,
      }),
    }),
    deleteSpecialities: builder.mutation<ResponseDto<boolean>, number>({
      query: (id) => ({
        url: `/${id}`,
        method: "DELETE",
      }),
    }),
    getSpecialities: builder.query<ResponseDto<SpecialityData[]>, undefined>({
      query: () => `/`,
    }),
  }),
});

type ResponseDto<T> = {
  success: boolean;
  data: T;
  message?: string | undefined;
};

export const {
  useGetSpecialitiesQuery,
  useCreateSpecialitiesMutation,
  useDeleteSpecialitiesMutation,
  useUpdateSpecialititesMutation,
} = specialitiesApi;
