import Icon, { CustomIconComponentProps } from "@ant-design/icons/lib/components/Icon";

const StarSvg = () => (
  <svg
    id="Layer_3"
    height={35}
    width={35}
    viewBox="0 0 32 32"
    xmlns="http://www.w3.org/2000/svg"
    data-name="Layer 3"
  >
    <path d="m23.479 29.691a2.325 2.325 0 0 1 -1.089-.27l-6.233-3.276a.333.333 0 0 0 -.314 0l-6.232 3.276a2.338 2.338 0 0 1 -3.393-2.465l1.19-6.939a.337.337 0 0 0 -.1-.3l-5.038-4.917a2.338 2.338 0 0 1 1.3-3.989l6.963-1.011a.337.337 0 0 0 .254-.185l3.113-6.315a2.337 2.337 0 0 1 4.193 0l3.115 6.313a.34.34 0 0 0 .254.185l6.969 1.012a2.339 2.339 0 0 1 1.3 3.989l-5.043 4.914a.339.339 0 0 0 -.1.3l1.19 6.939a2.341 2.341 0 0 1 -2.3 2.735zm-7.479-5.586a2.325 2.325 0 0 1 1.088.27l6.232 3.275a.321.321 0 0 0 .356-.025.325.325 0 0 0 .135-.331l-1.191-6.94a2.343 2.343 0 0 1 .672-2.069l5.043-4.915a.338.338 0 0 0 -.188-.576l-6.968-1.013a2.335 2.335 0 0 1 -1.76-1.279l-3.119-6.314a.338.338 0 0 0 -.606 0l-3.113 6.312a2.335 2.335 0 0 1 -1.761 1.279l-6.967 1.015a.337.337 0 0 0 -.187.576l5.042 4.915a2.343 2.343 0 0 1 .672 2.069l-1.191 6.94a.338.338 0 0 0 .492.356l6.231-3.276a2.335 2.335 0 0 1 1.088-.269z" />
  </svg>
);

export const StarIcon = (props: Partial<CustomIconComponentProps>) => (
  <Icon component={StarSvg} {...props} />
);
