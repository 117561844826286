import { FC, useEffect, useMemo, useState } from "react";
import {
  Avatar,
  Button,
  Col,
  Modal,
  Popover,
  Row,
  Table,
  Tag,
  Typography,
} from "antd";
import { ColumnsType } from "antd/es/table";
import {
  LeftOutlined,
  LoadingOutlined,
  PlusOutlined,
  RightOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import ClinicDetails from "./details/ClinicDetails";
import { ClinicData } from "../../common/interfaces/ClinicData";
import {
  useExportClinicsMutation,
  useGetClinicCitiesQuery,
  useGetClinicSpecialitiesQuery,
  useGetClinicsMutation,
} from "../../services/clinics";
import { HomeTab } from "../../common/enums";
import { FormatDisplayDate } from "../../common/utilities/format";
import { ClinicGroupListRequestDto } from "../../common/interfaces/ClinicGroupListRequestDto";
import ClinicSearch from "./search/ClinicSearch";
import useRolesPermissionsAccess from "../../hooks/useRolesPermissionsAccess";
import "./Clinics.style.scss";
import { getInitials } from "../../common/utilities/helper";
import {
  CommonDropdownFilterProps,
  HeaderCellDropdownFilter,
  SelectOption,
} from "../../common/components/headercelldropdownfilter/HeaderCellDropdownFilter";
import useResponsive from "../../hooks/useResponsive";
import { useDebounced } from "../../hooks/useDebounced";
import { MoreDotsIcon } from "../../assets/images/icons";
import { DownloadFile } from "../../common/utilities/converter";

const { Title, Text } = Typography;

type ClinicProps = {
  setActiveTab: (key: string) => void;
  setSelectedClinic: (clinic: ClinicData) => void;
};

const Clinics: FC<ClinicProps> = ({ setActiveTab, setSelectedClinic }) => {
  const { isMobile, windowWidth } = useResponsive();

  const [pageSize, setPageSize] = useState<number>(10);

  const [getClinics, { isLoading, isSuccess, data }] =
    useGetClinicsMutation(undefined);
  const [clinicListRequest, setClinicListRequest] =
    useState<ClinicGroupListRequestDto>({});
  const clinics = useMemo<ClinicData[] | undefined>(
    () =>
      data?.data?.map((clinic) => ({
        key: clinic.id,
        ...clinic,
      })),
    [data]
  );

  const { isAdmin, hasAddClinic, hasExportClinics } =
    useRolesPermissionsAccess();

  const [exportClinics, { isLoading: exportLoading }] =
    useExportClinicsMutation();

  const exportData = async () => {
    const exportFileData = await exportClinics(clinicListRequest).unwrap();
    if (exportFileData) {
      DownloadFile(
        exportFileData.data.contentType,
        exportFileData.data.rawData,
        exportFileData.data.fileName
      );
    }
  };

  useEffect(() => {
    const request = getClinics(clinicListRequest);
    return () => request.abort();
  }, [clinicListRequest, getClinics]);

  const refreshClinicList = () => {
    getClinics(clinicListRequest);
  };

  const handleSelectedValue = (value: string) => {
    setClinicListRequest({ ...clinicListRequest, name: value });
  };

  const handleSelectedSpecialities = (value: string[]) => {
    setClinicListRequest({ ...clinicListRequest, specialities: value });
  };
  const handleSelectedCities = (value: string[]) => {
    setClinicListRequest({ ...clinicListRequest, cities: value });
  };

  const appliedSpecialities = clinicListRequest.specialities;
  const appliedCities = clinicListRequest.cities;

  const columns: ColumnsType<ClinicData> = [
    {
      title: <ClinicSearch handleSelectedValue={handleSelectedValue} />,
      dataIndex: "name",
      key: "name",
      width: !isMobile
        ? windowWidth === 1440
          ? 400
          : windowWidth === 1536
          ? 360
          : 430
        : "auto",
      fixed: "left",
      render: (_value, record) => (
        <Name
          record={record}
          refreshClinicList={refreshClinicList}
          setActiveTab={setActiveTab}
          setSelectedClinic={setSelectedClinic}
        />
      ),
    },
    {
      title: (
        <CityHeaderCell
          apply={handleSelectedCities}
          applied={appliedCities}
          gotClinics={isSuccess}
        />
      ),
      dataIndex: "location",
      key: "location",
      width: 200,
      ellipsis: true,
      responsive: ["md"],
      render: (value, record) => (
        <Row align="middle" justify="center" gutter={[0, 5]}>
          <Col xs={24} className="text-center text-truncate">
            <Title level={5} className="clinics_list_table_cell_city mb-0">
              {record.city}
            </Title>
          </Col>
          <Col xs={24} className="text-center text-truncate">
            <Text className="mb-0">{record.country}</Text>
          </Col>
        </Row>
      ),
    },
    {
      title: (
        <SpecialitiesHeaderCell
          apply={handleSelectedSpecialities}
          applied={appliedSpecialities}
          gotClinics={isSuccess}
        />
      ),
      width: 350,
      responsive: ["md"],
      dataIndex: "specialities",
      key: "specialities",
      render: (value) => <SpecialitiesCell value={value} />,
    },
    {
      title: (
        <>
          <Title level={4}>&nbsp;</Title>
          <Title level={4} className="clinics_filter_title mb-0 mt-1">
            Providers
          </Title>
        </>
      ),
      dataIndex: "providers",
      key: "providers",
      width: 130,
      responsive: ["md"],
      render: (value, record) => (
        <ProvidersCell
          record={record}
          onClick={() => {
            setSelectedClinic(record);
            setActiveTab(HomeTab.Providers);
          }}
        />
      ),
    },
    {
      title: (
        <>
          <Title level={4}>&nbsp;</Title>
          <Title level={4} className="clinics_filter_title mb-0 mt-1">
            More Details
          </Title>
        </>
      ),
      dataIndex: "moreDetails",
      key: "moreDetails",
      width: 160,
      responsive: ["md"],
      render: (value, record) => (
        <MoreDetailsCell
          record={record}
          setActiveTab={setActiveTab}
          setSelectedClinic={setSelectedClinic}
          refreshClinicList={refreshClinicList}
        />
      ),
    },
  ];

  const handleScroll = useDebounced(200, () => {
    const distanceFromBottom =
      window.innerHeight + window.scrollY - document.body.scrollHeight;

    if (distanceFromBottom > -100) {
      setPageSize((prevPageSize) => prevPageSize + 10);
    }
  });

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [handleScroll]);

  const handleClose = (tagValue: string) => {
    setClinicListRequest((prevUserListRequest) => ({
      ...prevUserListRequest,
      ...Object.fromEntries(
        Object.entries(prevUserListRequest).map(([key, value]) => [
          key,
          Array.isArray(value)
            ? (value as string[]).filter((item) => item !== tagValue)
            : value,
        ])
      ),
    }));
  };

  const hasFilterValue = Object.values(clinicListRequest).some(
    (value) => Array.isArray(value) && value.length > 0
  );

  return (
    <div className="clinics">
      <div className="clinics_list">
        <Table
          className="clinics_list_table"
          columns={columns}
          scroll={!isMobile ? { x: 1000 } : undefined}
          dataSource={clinics}
          title={
            isAdmin || (hasFilterValue && !isMobile)
              ? () => (
                  <Row align="middle" justify="space-between" gutter={[10, 0]}>
                    <Col xs={24} md={13} xl={18} className="d-none d-md-block">
                      {Object.entries(clinicListRequest).map(([key, value]) => (
                        <>
                          {Array.isArray(value) &&
                            value.map((item) => (
                              <Tag
                                closable
                                key={item}
                                className="clinics_list_table_cell clinics_list_table_cell_tag clinics_list_table_cell_tag_filter_value_tag me-1 mt-1"
                                onClose={() => handleClose(item)}
                              >
                                <span className="me-2">{item}</span>
                              </Tag>
                            ))}
                        </>
                      ))}
                    </Col>
                    {isAdmin && (
                      <Col
                        xs={24}
                        md={11}
                        xl={6}
                        className="d-flex justify-content-end"
                      >
                        {hasExportClinics && (
                          <Button
                            size="large"
                            type="default"
                            className="me-2"
                            icon={<UploadOutlined />}
                            loading={exportLoading}
                            onClick={exportData}
                          >
                            {exportLoading ? "Loading..." : "Export Data"}
                          </Button>
                        )}
                        {hasAddClinic && (
                          <AddClinic refreshClinicList={refreshClinicList} />
                        )}
                      </Col>
                    )}
                  </Row>
                )
              : undefined
          }
          pagination={
            isMobile
              ? {
                  pageSize: pageSize,
                }
              : {
                  hideOnSinglePage: true,
                  position: ["bottomCenter"],
                  prevIcon: (
                    <Button className="clinics_custom_pagination_btn prev">
                      <LeftOutlined />
                      Prev
                    </Button>
                  ),
                  nextIcon: (
                    <Button className="clinics_custom_pagination_btn next">
                      Next <RightOutlined />
                    </Button>
                  ),
                }
          }
          loading={{
            size: "large",
            tip: "Loading...",
            spinning: isLoading,
            indicator: <LoadingOutlined />,
          }}
        />
      </div>
    </div>
  );
};

type CityHeaderCellProps = CommonDropdownFilterProps & {
  gotClinics: boolean;
};

const CityHeaderCell: FC<CityHeaderCellProps> = ({
  apply,
  applied,
  gotClinics,
}) => {
  const { data, isSuccess } = useGetClinicCitiesQuery(undefined, {
    skip: !gotClinics,
  });

  const options = useMemo<SelectOption[]>(
    () =>
      data?.data.map((specialty) => ({
        key: specialty,
      })) ?? [],
    [data]
  );

  return (
    <HeaderCellDropdownFilter
      applied={applied}
      apply={apply}
      options={options}
      title="City"
      disabled={!isSuccess}
    />
  );
};

type SpecialitiesHeaderCellProps = CommonDropdownFilterProps & {
  gotClinics: boolean;
};

const SpecialitiesHeaderCell: FC<SpecialitiesHeaderCellProps> = ({
  gotClinics,
  apply,
  applied,
}) => {
  const { data, isSuccess } = useGetClinicSpecialitiesQuery(undefined, {
    skip: !gotClinics,
  });

  const options = useMemo<SelectOption[]>(
    () =>
      data?.data.map((specialty) => ({
        key: specialty,
      })) ?? [],
    [data]
  );

  return (
    <HeaderCellDropdownFilter
      applied={applied}
      apply={apply}
      options={options}
      title="Primary Specialty"
      disabled={!isSuccess}
    />
  );
};

type ProvidersCellProps = {
  record: ClinicData;
  onClick: () => void;
};

const ProvidersCell: FC<ProvidersCellProps> = ({ record, onClick }) => {
  return (
    <Row align="middle" justify="center">
      <Col className="text-center">
        <Button
          className="clinics_list_table_cell_number fw-bold mb-0"
          type="link"
          onClick={onClick}
        >
          {record.providers}
        </Button>
      </Col>
    </Row>
  );
};

type MoreDetailsCellProps = {
  record: ClinicData;
  setActiveTab: (key: string) => void;
  setSelectedClinic: (clinicData: ClinicData) => void;
  refreshClinicList: () => void;
};

const MoreDetailsCell: FC<MoreDetailsCellProps> = ({
  record,
  setActiveTab,
  setSelectedClinic,
  refreshClinicList,
}) => {
  const [clinicDetailsModalData, setClinicDetailsModalData] =
    useState<ClinicData>();

  const [isClinicDetailModalOpen, setIsClinicDetailModalOpen] = useState(
    !!clinicDetailsModalData
  );
  const moreDetailsButtonClick = () => {
    setClinicDetailsModalData(record);
    setIsClinicDetailModalOpen(true);
  };

  const closeClinicDetailModal = () => {
    setClinicDetailsModalData(undefined);
    setIsClinicDetailModalOpen(false);
  };

  return (
    <Row align="middle" justify="center">
      <Col className="text-center">
        <Button
          type="default"
          size="large"
          className="clinics_list_table_cell_btn_moredetail"
          onClick={moreDetailsButtonClick}
        >
          More Details
        </Button>
      </Col>
      <Modal
        open={isClinicDetailModalOpen}
        onCancel={closeClinicDetailModal}
        footer={null}
        width={700}
        className="clinics_modal"
        destroyOnClose={true}
      >
        {!!clinicDetailsModalData && (
          <ClinicDetails
            clinicData={clinicDetailsModalData}
            setActiveTab={setActiveTab}
            closeClinicDetailModal={closeClinicDetailModal}
            setSelectedClinic={setSelectedClinic}
            refreshClinicList={refreshClinicList}
          />
        )}
      </Modal>
    </Row>
  );
};

type AddClinicProps = {
  refreshClinicList: () => void;
};

const AddClinic: FC<AddClinicProps> = ({ refreshClinicList }) => {
  const [isClinicDetailModalOpen, setIsClinicDetailModalOpen] = useState(false);

  const moreDetailsButtonClick = () => {
    setIsClinicDetailModalOpen(true);
  };

  const closeClinicDetailModal = () => {
    setIsClinicDetailModalOpen(false);
  };

  return (
    <Row align="middle" justify="center">
      <Col className="text-center">
        <Button
          size="large"
          onClick={moreDetailsButtonClick}
          icon={<PlusOutlined />}
        >
          Add Clinic
        </Button>
      </Col>
      <Modal
        open={isClinicDetailModalOpen}
        onCancel={closeClinicDetailModal}
        footer={null}
        width={700}
        className="clinics_modal"
        centered
        destroyOnClose={true}
      >
        <ClinicDetails
          closeClinicDetailModal={closeClinicDetailModal}
          isCreate={true}
          refreshClinicList={refreshClinicList}
        />
      </Modal>
    </Row>
  );
};

type NameProps = {
  record: ClinicData;
  refreshClinicList: () => void;
  setActiveTab: (key: string) => void;
  setSelectedClinic: (clinicData: ClinicData) => void;
};

const Name: FC<NameProps> = ({
  record,
  refreshClinicList,
  setActiveTab,
  setSelectedClinic,
}) => {
  const [clinicDetailsModalData, setClinicDetailsModalData] =
    useState<ClinicData>();

  const isClinicDetailModalOpen = !!clinicDetailsModalData;

  const openClinicDetailsModal = () => {
    setClinicDetailsModalData(record);
  };

  const closeClinicDetailModal = () => {
    setClinicDetailsModalData(undefined);
  };

  const { isMobile } = useResponsive();
  return (
    <>
      <Row align="middle" justify="center" onClick={openClinicDetailsModal}>
        <Col xs={6} sm={4} md={6} xl={6} xxl={4}>
          <Avatar
            shape="square"
            size="large"
            src={record.imageUrl}
            className="clinics_list_table_cell_image"
          >
            {getInitials(record?.name)}
          </Avatar>
        </Col>
        <Col xs={15} sm={17} md={18} xl={18} xxl={20}>
          <Row>
            <Col xs={24}>
              <Title
                level={isMobile ? 4 : 3}
                className="clinics_list_table_cell_name mb-0"
              >
                {record.name}
              </Title>
            </Col>
            {record.since && (
              <Col xs={24}>
                <Text className="clinics_list_table_cell_date mb-0">
                  Since {FormatDisplayDate(record.since)}
                </Text>
              </Col>
            )}
          </Row>
        </Col>
        {isMobile && (
          <Col span={3}>
            <RightOutlined className="clinics_list_table_cell_right_icon" />
          </Col>
        )}
      </Row>

      {isMobile && (
        <Modal
          open={isClinicDetailModalOpen}
          onCancel={closeClinicDetailModal}
          footer={null}
          width={830}
          className="clinics_details_modal"
          centered
          destroyOnClose={true}
        >
          {!!clinicDetailsModalData && (
            <ClinicDetails
              clinicData={clinicDetailsModalData}
              setActiveTab={setActiveTab}
              closeClinicDetailModal={closeClinicDetailModal}
              setSelectedClinic={setSelectedClinic}
              refreshClinicList={refreshClinicList}
            />
          )}
        </Modal>
      )}
    </>
  );
};

type SpecialitiesCellProps = {
  value: string | "";
};

const SpecialitiesCell: FC<SpecialitiesCellProps> = ({ value }) => {
  return (
    <Row align="middle" justify="start">
      <Col>
        {value &&
          value
            .split(/[,;]/)
            .filter((s: string) => s.trim())
            .slice(0, 2)
            .map((s: string, index: number) => (
              <Tag
                key={`${s}_${index}`}
                className="clinics_list_table_cell_tag mb-1"
                bordered={false}
              >
                {s}
              </Tag>
            ))}
      </Col>
      <Col>
        {value && value.split(/[,;]/).length > 2 && (
          <Popover
            arrow={false}
            content={
              value &&
              value
                .split(/[,;]/)
                .filter((s: string) => s.trim())
                .splice(2, value.split(/[,;]/).length)
                .map((s: string, index: number) => (
                  <Row key={`tag_row_${s}_${index}`}>
                    <Col>
                      <Tag
                        className="clinics_list_table_cell_tag mb-1"
                        bordered={false}
                      >
                        {s}
                      </Tag>
                    </Col>
                  </Row>
                ))
            }
            title=""
          >
            <MoreDotsIcon />
          </Popover>
        )}
      </Col>
    </Row>
  );
};
export default Clinics;
