import { configureStore } from "@reduxjs/toolkit";
import { useDispatch, useSelector, TypedUseSelectorHook } from "react-redux";
import { providerApi } from "./services/providers";
import providerReducer from "./store/providersSlice";
import { clinicApi } from "./services/clinics";
import clinicReducer from "./store/clinicsSlice";
import { reportApi } from "./services/reports";
import reportReducer from "./store/reportsSlice";
import { groupApi } from "./services/groups";
import { roleGroupsApi } from "./services/roleGroups";
import { domainsApi } from "./services/domains";
import { credentialsApi } from "./services/credentials";
import { userApi } from "./services/users";
import { addressApi } from "./services/address";
import { specialitiesApi } from "./services/specialities";
import { userDepartmentsApi } from "./services/userDepartments";
import { countryApi } from "./services/country";
import { quickLinksApi } from "./services/quickLinks";

export const store = configureStore({
  reducer: {
    // Add the generated reducer as a specific top-level slice
    [providerApi.reducerPath]: providerApi.reducer,
    providers: providerReducer,
    [clinicApi.reducerPath]: clinicApi.reducer,
    clinics: clinicReducer,
    [reportApi.reducerPath]: reportApi.reducer,
    reports: reportReducer,
    [groupApi.reducerPath]: groupApi.reducer,
    [roleGroupsApi.reducerPath]: roleGroupsApi.reducer,
    [domainsApi.reducerPath]: domainsApi.reducer,
    [credentialsApi.reducerPath]: credentialsApi.reducer,
    [userApi.reducerPath]: userApi.reducer,
    [addressApi.reducerPath]: addressApi.reducer,
    [specialitiesApi.reducerPath]: specialitiesApi.reducer,
    [userDepartmentsApi.reducerPath]: userDepartmentsApi.reducer,
    [countryApi.reducerPath]: countryApi.reducer,
    [quickLinksApi.reducerPath]: quickLinksApi.reducer,
  },
  // Adding the api middleware enables caching, invalidation, polling,
  // and other useful features of `rtk-query`.
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware()
      .concat(providerApi.middleware)
      .concat(clinicApi.middleware)
      .concat(reportApi.middleware)
      .concat(groupApi.middleware)
      .concat(roleGroupsApi.middleware)
      .concat(domainsApi.middleware)
      .concat(credentialsApi.middleware)
      .concat(userApi.middleware)
      .concat(addressApi.middleware)
      .concat(specialitiesApi.middleware)
      .concat(userDepartmentsApi.middleware)
      .concat(countryApi.middleware)
      .concat(quickLinksApi.middleware)
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
