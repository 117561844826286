import {
  Spin,
  Typography,
  Table,
  Row,
  Col,
  Button,
  Modal,
  message,
} from "antd";
import {
  useDeleteUserDepartmentMutation,
  useGetUserDepartmentsQuery,
} from "../../../services/userDepartments";
import { FC, useMemo, useState } from "react";
import { UserDepartmentData } from "../../../common/interfaces/UserDepartmentData";
import { ColumnsType } from "antd/es/table";
import {
  DeleteOutlined,
  EditOutlined,
  LeftOutlined,
  LoadingOutlined,
  PlusOutlined,
  RightOutlined,
} from "@ant-design/icons";
import UserDepartmentDetails from "./details/UserDepartmentDetails";
import "./UserDepartment.style.scss";

const { Text } = Typography;

const UserDepartment: FC = () => {
  const { data, refetch, isLoading } = useGetUserDepartmentsQuery(undefined);
  const refetchUserDepartments = (): void => {
    refetch();
  };

  const userDepartments = useMemo<UserDepartmentData[] | undefined>(() => {
    return data?.data?.map((userDepartment) => ({
      key: userDepartment.id,
      ...userDepartment,
    }));
  }, [data]);

  const columns: ColumnsType<UserDepartmentData> = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (text) => <Text>{text}</Text>,
    },
    {
      key: "edit",
      width: 70,
      render: (text, record) => (
        <EditUserDepartment
          userDepartmentEditData={record}
          refetchUserDepartments={refetchUserDepartments}
        />
      ),
    },
    {
      key: "delete",
      width: 70,
      render: (text, record) => (
        <DeleteUserDepartment
          userDepartmentId={record.id}
          refetchUserDepartments={refetchUserDepartments}
        />
      ),
    },
  ];

  return (
    <div className="user_department">
      <Spin
        size="large"
        tip="Loading..."
        spinning={isLoading}
        indicator={<LoadingOutlined />}
      >
        <Table
          columns={columns}
          dataSource={userDepartments}
          className="user_department_table"
          title={() => (
            <Row align="middle" justify="end">
              <Col>
                <AddUserDepartment
                  refetchUserDepartments={refetchUserDepartments}
                />
              </Col>
            </Row>
          )}
          pagination={{
            hideOnSinglePage: true,
            position: ["bottomCenter"],
            prevIcon: (
              <Button className="prev">
                <LeftOutlined />
                Prev
              </Button>
            ),
            nextIcon: (
              <Button className="next">
                Next <RightOutlined />
              </Button>
            ),
          }}
        />
      </Spin>
    </div>
  );
};

type DeleteUserDepartmentProps = {
  userDepartmentId: number;
  refetchUserDepartments: () => void;
};

const DeleteUserDepartment: FC<DeleteUserDepartmentProps> = ({
  userDepartmentId,
  refetchUserDepartments,
}) => {
  const [DeleteUserDepartment, { isLoading: deleteLoading }] =
    useDeleteUserDepartmentMutation();
  const onDelete = () => {
    DeleteUserDepartment(userDepartmentId)
      .unwrap()
      .then((res) => {
        if (res?.success) {
          refetchUserDepartments();
          message.success("User Department deleted successfully");
        } else {
          message.error(res?.message);
        }
      })
      .catch((error) => {
        message.error(error.data.message);
      });
  };

  return (
    <Button
      size="large"
      type="default"
      danger
      onClick={onDelete}
      loading={deleteLoading}
      icon={<DeleteOutlined />}
    >
      Delete
    </Button>
  );
};

type EditUserDepartmentProps = {
  userDepartmentEditData: any;
  refetchUserDepartments: () => void;
};

const EditUserDepartment: FC<EditUserDepartmentProps> = ({
  userDepartmentEditData,
  refetchUserDepartments,
}) => {
  const [isUserDepartmentDetailModalOpen, setIsUserDepartmentModalOpen] =
    useState<boolean>(false);

  const moreDetailsButtonClick = () => {
    setIsUserDepartmentModalOpen(true);
  };

  const closeUserDepartmentDetailModal = () => {
    setIsUserDepartmentModalOpen(false);
  };

  return (
    <Row align="middle" justify="center">
      <Col className="text-center">
        <Button
          size="large"
          onClick={moreDetailsButtonClick}
          icon={<EditOutlined />}
        >
          Edit
        </Button>
      </Col>
      <Modal
        open={isUserDepartmentDetailModalOpen}
        onCancel={closeUserDepartmentDetailModal}
        footer={null}
        centered
        destroyOnClose={true}
      >
        <UserDepartmentDetails
          refetchUserDepartments={refetchUserDepartments}
          userDepartmentEditData={userDepartmentEditData}
          closeUserDepartmentDetailModal={closeUserDepartmentDetailModal}
        />
      </Modal>
    </Row>
  );
};

type AddUserDepartmentProps = {
  refetchUserDepartments: () => void;
};

const AddUserDepartment: FC<AddUserDepartmentProps> = ({
  refetchUserDepartments,
}) => {
  const [isUserDepartmentDetailModalOpen, setIsUserDepartmentDetailModalOpen] =
    useState<boolean>(false);

  const moreDetailsButtonClick = () => {
    setIsUserDepartmentDetailModalOpen(true);
  };

  const closeUserDepartmentDetailModal = () => {
    setIsUserDepartmentDetailModalOpen(false);
  };
  return (
    <Row align="middle" justify="center">
      <Col className="text-center">
        <Button
          size="large"
          onClick={moreDetailsButtonClick}
          icon={<PlusOutlined />}
        >
          Add User Department
        </Button>
      </Col>
      <Modal
        open={isUserDepartmentDetailModalOpen}
        onCancel={closeUserDepartmentDetailModal}
        footer={null}
        centered
        destroyOnClose={true}
      >
        <UserDepartmentDetails
          closeUserDepartmentDetailModal={closeUserDepartmentDetailModal}
          refetchUserDepartments={refetchUserDepartments}
        />
      </Modal>
    </Row>
  );
};

export default UserDepartment;
