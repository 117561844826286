import Icon from "@ant-design/icons";
import { CustomIconComponentProps } from "@ant-design/icons/lib/components/Icon";

const ManagerSvg = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="35"
    height="35"
    viewBox="0 0 48 48"
    version="1.1"
  >
    <g>
      <g>
        <g transform="matrix(0.432432,0,0,0.722222,-44.973,-105.556)">
          <path d="M159.5,158.615C153.98,158.615 148.686,159.928 144.783,162.265C140.88,164.602 138.687,167.772 138.688,171.077L138.688,178C138.688,178.765 139.723,179.385 141,179.385L178,179.385C179.277,179.385 180.313,178.765 180.313,178L180.312,171.077C180.313,167.772 178.12,164.602 174.217,162.265C170.314,159.928 165.02,158.615 159.5,158.615L159.5,161.385M175.688,171.077L175.688,176.615L143.313,176.615L143.313,171.077C143.312,168.506 145.018,166.041 148.054,164.223C151.089,162.406 155.207,161.385 159.5,161.385C163.793,161.385 167.911,162.406 170.946,164.223C173.982,166.041 175.688,168.506 175.688,171.077Z" />
        </g>
        <g transform="matrix(1.125,0,0,1.125,-154.875,-154.25)">
          <path d="M159,137.111C156.302,137.111 154.111,139.302 154.111,142C154.111,144.698 156.302,146.889 159,146.889C161.698,146.889 163.889,144.698 163.889,142C163.889,139.302 161.698,137.111 159,137.111ZM159,138.889C160.717,138.889 162.111,140.283 162.111,142C162.111,143.717 160.717,145.111 159,145.111C157.283,145.111 155.889,143.717 155.889,142C155.889,140.283 157.283,138.889 159,138.889Z" />
        </g>
        <g transform="matrix(0.972973,0,0,0.944444,-131.189,-121.111)">
          <path d="M179.028,160C179.028,159.415 178.568,158.941 178,158.941L141,158.941C140.432,158.941 139.972,159.415 139.972,160L139.972,178C139.972,178.585 140.432,179.059 141,179.059L178,179.059C178.568,179.059 179.028,178.585 179.028,178L179.028,160ZM176.972,161.059L176.972,176.941L142.028,176.941L142.028,161.059L176.972,161.059Z" />
        </g>
        <g transform="matrix(1.13514,0,0,0.388889,-157.054,-39.2222)">
          <path d="M178.881,160C178.881,158.58 178.487,157.429 178,157.429L141,157.429C140.513,157.429 140.119,158.58 140.119,160L140.119,178C140.119,179.42 140.513,180.571 141,180.571L178,180.571C178.487,180.571 178.881,179.42 178.881,178L178.881,160ZM177.119,162.571L177.119,175.429L141.881,175.429L141.881,162.571L177.119,162.571Z" />
        </g>
        <g transform="matrix(1,0,0,1,-136,-136)">
          <path d="M174,178L172,178C171.448,178 171,178.448 171,179C171,179.552 171.448,180 172,180L174,180C174.552,180 175,179.552 175,179C175,178.448 174.552,178 174,178Z" />
        </g>
        <g transform="matrix(0.5,0,0,1,-54,-136)">
          <path d="M174,178L172,178C170.896,178 170,178.448 170,179C170,179.552 170.896,180 172,180L174,180C175.104,180 176,179.552 176,179C176,178.448 175.104,178 174,178Z" />
        </g>
      </g>
    </g>
  </svg>
);

export const ManagerIcon = (props: Partial<CustomIconComponentProps>) => (
  <Icon component={ManagerSvg} {...props} />
);
