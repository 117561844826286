import { PayloadAction, createSlice } from "@reduxjs/toolkit";

type ReportFilters = {
  nameFilter?: string;
};

export interface ReportState {
  filters: ReportFilters;
}

const initialState: ReportState = {
  filters: {},
};

export const reportsSlice = createSlice({
  name: "reports",
  initialState,
  reducers: {
    setNameFilter: (state, action: PayloadAction<string | undefined>) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.filters.nameFilter = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setNameFilter } = reportsSlice.actions;

export default reportsSlice.reducer;
