import Icon, {
  CustomIconComponentProps,
} from "@ant-design/icons/lib/components/Icon";

const PadLockSvg = () => (
  <svg
    id="Capa_1"
    height="35"
    viewBox="0 0 24 24"
    width="35"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g>
      <path d="m19 9.25h-1.25v-2.25c0-3.176-2.574-5.75-5.75-5.75s-5.75 2.574-5.75 5.75v2.25h-1.25c-.964.005-1.745.786-1.75 1.75v10c.005.964.786 1.745 1.75 1.75h14c.964-.005 1.745-.786 1.75-1.75v-10c-.005-.964-.786-1.745-1.75-1.75zm-11.25-2.25c0-2.347 1.903-4.25 4.25-4.25s4.25 1.903 4.25 4.25v2.25h-8.5zm11.5 14c0 .138-.112.25-.25.25h-14c-.138 0-.25-.112-.25-.25v-10c0-.138.112-.25.25-.25h2 10 2c.138 0 .25.112.25.25z" />
      <path d="m12 13.5c-.828 0-1.5.672-1.5 1.5 0 .554.304 1.032.75 1.292v.708c.005.412.338.745.75.75.412-.005.745-.338.75-.75v-.708c.446-.26.75-.738.75-1.292 0-.828-.672-1.5-1.5-1.5z" />
    </g>
  </svg>
);

export const PadLockIcon = (props: Partial<CustomIconComponentProps>) => (
  <Icon component={PadLockSvg} {...props} />
);
