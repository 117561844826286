import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { getAuthToken } from "../common/settings/config/AzureAuthenticationConfig";
import { SchemaExtensionData } from "../common/interfaces/SchemaExtensionData";
import { ProfileData } from "../common/interfaces/ProfileData";
import { UserData } from "../common/interfaces/UserData";
import { UserListRequestDto } from "../common/interfaces/UserListRequestDto";
import { FileResponseDto } from "../common/interfaces/FileResponseDto";
import { SignInLogDto } from "../common/interfaces/SignInLogDto";

export const userApi = createApi({
  reducerPath: "userApi",
  baseQuery: fetchBaseQuery({
    baseUrl: "/api/users",
    prepareHeaders: async (headers) => {
      const token = await getAuthToken();
      headers.set("Authorization", `Bearer ${token}`);
      return headers;
    },
  }),
  endpoints: (builder) => ({
    getUserSchemaExtensions: builder.query<
      ResponseDto<SchemaExtensionData>,
      undefined
    >({ query: () => `/schemaextensions` }),
    getUserSchemaExtensionId: builder.query<ResponseDto<string>, undefined>({
      query: () => `/schemaextensionid`,
    }),
    checkUserNameExist: builder.query<
      ResponseDto<boolean>,
      [issuerAssignedId: string | undefined, issuer: string | undefined]
    >({
      query: ([issuerAssignedId, issuer]) =>
        `/checkusernameexist?issuerAssignedId=${issuerAssignedId}&issuer=${issuer}`,
    }),
    checkEmailExist: builder.query<
      ResponseDto<boolean>,
      [email: string | undefined, userId?: string | undefined]
    >({
      query: ([email, userId]) => {
        if (userId) {
          return `/checkemailexist?email=${email}&userId=${userId}`;
        }
        return `/checkemailexist?email=${email}`;
      },
    }),
    getUser: builder.query<ResponseDto<ProfileData>, string | undefined>({
      query: (userId) => `/${userId}`,
    }),
    updateProfile: builder.mutation<ResponseDto<boolean>, undefined>({
      query: (data) => ({
        url: `/profile`,
        method: "PATCH",
        body: data,
      }),
    }),
    getUsers: builder.mutation<ResponseDto<UserDto[]>, UserListRequestDto>({
      query: (args) => ({
        url: `/list`,
        method: "POST",
        body: args,
      }),
    }),
    createUser: builder.mutation<ResponseDto<string>, any>({
      query: (args) => ({
        url: `/`,
        method: "POST",
        body: args,
      }),
    }),
    searchUsers: builder.query<ResponseDto<string[]>, string | undefined>({
      query: (searchText) => `/search?q=${searchText}`,
    }),
    getUserPrimarySpecialities: builder.query<ResponseDto<string[]>, undefined>(
      {
        query: () => `/primaryspecialities`,
      }
    ),
    getUserClinics: builder.query<ResponseDto<string[]>, undefined>({
      query: () => `/clinics`,
    }),
    getUserJobTitle: builder.query<ResponseDto<string[]>, undefined>({
      query: () => `/jobtitles`,
    }),
    getUserDepartment: builder.query<ResponseDto<string[]>, undefined>({
      query: () => `/departments`,
    }),
    updateUser: builder.mutation<ResponseDto<boolean>, any>({
      query: (data) => ({
        url: ``,
        method: "PATCH",
        body: data,
      }),
    }),
    deleteUser: builder.mutation<ResponseDto<boolean>, string>({
      query: (userId) => ({
        url: `/${userId}`,
        method: "DELETE",
      }),
    }),
    updateUserAccountStatus: builder.mutation<ResponseDto<boolean>, any>({
      query: (data) => ({
        url: `/account/status`,
        method: "PATCH",
        body: data,
      }),
    }),
    updateUserCategory: builder.mutation<ResponseDto<boolean>, any>({
      query: (data) => ({
        url: `/category`,
        method: "PATCH",
        body: data,
      }),
    }),
    deleteUserTemporaryPassword: builder.mutation<ResponseDto<boolean>, undefined>({
      query: () => ({
        url: `/temporarypassword`,
        method: "DELETE",
      }),
    }),
    addRemoveGroupMember: builder.mutation<ResponseDto<boolean>, any>({
      query: (data) => ({
        url: `/addremovegroupmember`,
        method: "PATCH",
        body: data,
      }),
    }),
    exportUsers: builder.mutation<ResponseDto<FileResponseDto>, UserListRequestDto>({
      query: (args) => ({
        url: `/export`,
        method: "POST",
        body: args,
      }),
    }),
    getSignInLogsByUserId: builder.query<ResponseDto<SignInLogDto[]>, string>({
      query: (userId) => `/${userId}/signinlogs`,
    }),
    deleteUsers: builder.mutation<ResponseDto<boolean>, string[]>({
      query: (data) => ({
        url: ``,
        method: "DELETE",
        body: data,
      }),
    }),
  }),
});

type UserDto = Omit<UserData, "key"> & { id: string };

type ResponseDto<T> = {
  success: boolean;
  data: T;
  message?: string | undefined;
};

export const {
  useGetUserSchemaExtensionsQuery,
  useGetUserSchemaExtensionIdQuery,
  useCheckUserNameExistQuery,
  useCheckEmailExistQuery,
  useGetUserQuery,
  useUpdateProfileMutation,
  useGetUsersMutation,
  useSearchUsersQuery,
  useGetUserPrimarySpecialitiesQuery,
  useGetUserClinicsQuery,
  useCreateUserMutation,
  useUpdateUserMutation,
  useDeleteUserMutation,
  useGetUserDepartmentQuery,
  useGetUserJobTitleQuery,
  useUpdateUserAccountStatusMutation,
  useUpdateUserCategoryMutation,
  useDeleteUserTemporaryPasswordMutation,
  useAddRemoveGroupMemberMutation,
  useExportUsersMutation,
  useGetSignInLogsByUserIdQuery,
  useDeleteUsersMutation
} = userApi;
