import {
  ComponentType,
  FC,
  ReactNode,
  Reducer,
  useCallback,
  useEffect,
  useMemo,
  useReducer,
  useRef,
  useState,
} from "react";
import {
  Avatar,
  Button,
  Col,
  DatePicker,
  Form,
  Input,
  Modal,
  Row,
  Select,
  Tabs,
  Tag,
  Typography,
  Upload,
  Popover,
  message,
  Switch,
  SelectProps,
  Checkbox,
} from "antd";
import {
  CertificateIcon,
  ClinicIcon,
  EmailIcon,
  LanguageIcon,
  LocationIcon,
  TelephoneIcon,
  UserIcon,
  PadLockIcon,
  OutBoxIcon,
  RolesIcon,
  IdCardIcon,
} from "../../../assets/images/icons";
import { ProviderData } from "../../../common/interfaces/ProviderData";
import {
  authPhonePatternMesssage,
  authPhonePatternRegex,
  namePatternMesssage,
  namePatternRegex,
  npiPatternRegex,
  passwordPatternRegex,
  phonePatternRegex,
  spacePatternRegex,
} from "../../../common/utilities/validator";
import ModifyGroupAndRole from "./groupandrole/ModifyGroupAndRole";
import { AuthMethod, SignInType } from "../../../common/enums";
import {
  FormatAddress,
  FormatDisplayDate,
  FormatDisplayDateTime,
  ParseDayJsDate,
  RemoveSpaceFromString,
} from "../../../common/utilities/format";
import { RcFile } from "antd/es/upload";
import {
  CheckCircleFilled,
  CloseCircleFilled,
  CopyOutlined,
  DownOutlined,
  LoadingOutlined,
} from "@ant-design/icons";
import { useGetDomainsQuery } from "../../../services/domains";
import { useGetClinicsNameQuery } from "../../../services/clinics";
import dayjs from "dayjs";
import { MaskedInput } from "antd-mask-input";
import { UserType } from "../../../common/enums/UserType";
import {
  getInitials,
  handleImageUpload,
  mergeFormsAndNestAdditionalData,
  parseOnlyBase64ImageData,
} from "../../../common/utilities/helper";
import { useGetUserRolesQuery } from "../../../services/roleGroups";
import { FormInstance, Rule } from "antd/es/form";
import { useGetCredentialsQuery } from "../../../services/credentials";
import { CredentialsDto } from "../../../common/interfaces/CredentialsDto";
import {
  useCreateProviderMutation,
  useDeleteProviderMutation,
  useUpdateProviderMutation,
} from "../../../services/providers";
import {
  useCheckEmailExistQuery,
  useCheckUserNameExistQuery,
  useGetUserSchemaExtensionIdQuery,
  useUpdateUserAccountStatusMutation,
} from "../../../services/users";
import "./ProviderDetails.style.scss";
import {
  FormInputField,
  getInitialValue,
  getKey,
} from "../../../common/utilities/formUtils";
import { useNavigate } from "react-router-dom";
import useRolesPermissionsAccess from "../../../hooks/useRolesPermissionsAccess";
import { useGetSpecialitiesQuery } from "../../../services/specialities";
import { useIsAuthenticated } from "@azure/msal-react";
import useResponsive from "../../../hooks/useResponsive";
import { randomPasswordGenerator } from "../../../common/utilities/passwordGenerator";
import copy from "copy-to-clipboard";
import { useGetCountryCodesQuery } from "../../../services/country";

const { Title, Paragraph, Text } = Typography;
const { TextArea } = Input;

type ProviderDetailsFormData = Partial<{
  photo: string;
  additionalData: Partial<{
    SecondaryGroupName: string;
    SpokenLanguage: string;
    Credentials: string;
    MemberSince: dayjs.Dayjs;
    Location: string;
  }>;
  identity: Partial<{
    signInType: SignInType;
    issuerAssignedId: string;
    issuer: string;
  }>;
  authPhoneCountryCode?: string;
  authPhone?: string;
}>;

interface ProviderDetailsProps {
  providerData?: ProviderData;
  closeProviderDetailModal: () => void;
  isCreate?: boolean;
  refreshProviderList: () => void;
}

const jobTitleOptions: SelectProps["options"] = [
  {
    label: "Specialist",
    value: "Specialist",
  },
  { label: "Primary Care Provider", value: "Primary Care Provider" },
];

const ENTER_PERSONAL_INFO = "Enter Personal Information";
const SEE_DETAILS = "See Details";

const RESET = "Reset";
const CLEAR = "Clear";

const ProviderDetails: FC<ProviderDetailsProps> = ({
  providerData,
  closeProviderDetailModal,
  isCreate = false,
  refreshProviderList,
}) => {
  const isAuthenticated = useIsAuthenticated();
  const navigate = useNavigate();
  const [isGroupRoleModalOpen, setIsGroupRoleModalOpen] =
    useState<boolean>(false);
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [personalInfo, setPersonalInfo] = useState<PersonalInfoForm>({
    firstName: providerData?.firstName,
    lastName: providerData?.lastName,
    additionalData: {
      MiddleName: providerData?.middleName,
    },
  });

  const [newGroups, setNewGroups] = useState<string[]>([]);
  const [removedGroups, setRemovedGroups] = useState<string[]>([]);

  const initialGroups = useMemo(
    () => (providerData?.groupIds ? providerData?.groupIds.split(",") : []),
    [providerData?.groupIds]
  );

  const newSelectedGroups = useCallback(() => {
    setNewGroups((prevSelectedGroup) => {
      return prevSelectedGroup.filter((val) => !initialGroups.includes(val));
    });
  }, [initialGroups]);

  const [providersForm] = Form.useForm<ProviderDetailsFormData>();

  const [createUserApi, { isLoading: createUserLoading }] =
    useCreateProviderMutation();
  const [updateUserApi, { isLoading: updateUserLoading }] =
    useUpdateProviderMutation();

  const { isAdmin, isCoipaStaff, isCoipaFinance, hasDeleteProvider } =
    useRolesPermissionsAccess();

  const { data: userSchemaExtensionData } = useGetUserSchemaExtensionIdQuery(
    undefined,
    { skip: !isCreate && !isEdit }
  );
  const userSchemaExtensionId = useMemo(
    () => userSchemaExtensionData?.data || "",
    [userSchemaExtensionData]
  );

  const { data: userRoles } = useGetUserRolesQuery(providerData?.groupIds!, {
    skip: !isAdmin || !providerData?.groupIds,
  });
  const userRoleNames = useMemo(
    () => userRoles?.data.map((d) => d.name) || [],
    [userRoles]
  );

  const { data: clinics, refetch } = useGetClinicsNameQuery(undefined, {
    skip: !isCreate && !isEdit,
  });

  const clinicOptions = useMemo<SelectProps["options"]>(
    () =>
      clinics?.data.map((clinic) => ({
        label: clinic.name,
        value: clinic.name,
      })),
    [clinics]
  );

  useEffect(() => {
    if (isCreate || isEdit) {
      refetch();
    }
  }, [isCreate, isEdit, navigate, refetch]);

  const showGroupRoleModal = () => {
    setIsGroupRoleModalOpen(true);
  };

  const handleGroupRoleCancel = () => {
    setIsGroupRoleModalOpen(false);
  };

  const [resetCounter, resetForms] = useReducer<Reducer<number, void>>(
    (n) => n + 1,
    0
  );

  const getGroupData = (
    selectedGroups: string[],
    removedGroups: string[]
  ): void => {
    setNewGroups(selectedGroups);
    setRemovedGroups(removedGroups);
    newSelectedGroups();
  };

  const createUser = () => {
    providersForm
      .validateFields()
      .then((values) => {
        const merged = mergeFormsAndNestAdditionalData(
          values,
          userSchemaExtensionId,
          personalInfo
        );
        const updatedProviderData = {
          ...merged,
          identity: { ...values.identity, signInType: SignInType.EmailAddress },
          photo: parseOnlyBase64ImageData(values.photo),
          userType: UserType.Member,
          accountEnabled: true,
          authPhone: values.authPhoneCountryCode && values.authPhone ? `${values.authPhoneCountryCode} ${values.authPhone}` : null,
          newGroups,
        };

        createUserApi(updatedProviderData)
          .unwrap()
          .then((res) => {
            if (res.success) {
              message.success("Provider created successfully");
              closeProviderDetailModal();
              refreshProviderList();
              resetForms();
            } else {
              message.error(res.message);
            }
          })
          .catch((error) =>
            message.error(error.data.message || error.data.title)
          );
      })
      .catch((info) => {
        console.error("Provider Create Validate Failed:", info);
      });
  };

  const updateUser = (): void => {
    providersForm
      .validateFields()
      .then((values: any) => {
        const merged = mergeFormsAndNestAdditionalData(
          values,
          userSchemaExtensionId,
          personalInfo
        );
        const updatedUserData = {
          ...merged,
          userId: providerData?.key,
          photo: parseOnlyBase64ImageData(values.photo),
          newGroups,
          removedGroups,
        };

        updateUserApi(updatedUserData)
          .unwrap()
          .then((res) => {
            if (res.success) {
              message.success("Provider updated successfully");
              closeProviderDetailModal();
              refreshProviderList();
            } else {
              message.error(res.message);
            }
          })
          .catch((error) => {
            message.error(error.data.message || error.data.title);
          });
      })
      .catch((info) => {
        console.error("Provider Update Validate Failed:", info);
      });
  };

  return (
    <>
      <Form form={providersForm} onFinish={isCreate ? createUser : updateUser}>
        <Row className="provider_details">
          <Col span={24}>
            <ProviderDetailsHeader
              key={resetCounter}
              providerData={providerData}
              isCreate={isCreate}
              setIsEdit={setIsEdit}
              isEdit={isEdit}
              updatePersonalInfoFormData={setPersonalInfo}
              initialPersonalInfo={personalInfo}
              isLoadingDataNecessaryForSubmit={
                createUserLoading || updateUserLoading
              }
            />
          </Col>
          <Col span={24}>
            <Row>
              <ProviderDetailsRow
                first={true}
                name="primaryGroupName"
                label="Primary Group Name"
                Icon={ClinicIcon}
                textContent={providerData?.primaryGroupName}
                isCreate={isCreate}
                isEdit={isEdit}
                rules={[
                  {
                    required: true,
                    message: "Please enter Primary Group Name",
                  },
                ]}
                options={clinicOptions}
              />
              <ProviderDetailsRow
                label="Secondary Group Name"
                name={["additionalData", "SecondaryGroupName"]}
                Icon={ClinicIcon}
                textContent={providerData?.secondaryGroupName}
                isCreate={isCreate}
                isEdit={isEdit}
              />
              {isAuthenticated && (
                <>
                  <ProviderDetailsRow
                    label="Email"
                    Icon={EmailIcon}
                    customContent={
                      <EmailField
                        name="email"
                        providerData={providerData}
                        isEdit={isEdit}
                        isCreate={isCreate}
                      />
                    }
                  />
                  <ProviderDetailsRow
                    label="Phone"
                    name="phone"
                    Icon={TelephoneIcon}
                    textContent={providerData?.phone}
                    isCreate={isCreate}
                    isEdit={isEdit}
                    mask="(000)-000-0000"
                    rules={[
                      {
                        pattern: phonePatternRegex,
                        message: "Enter valid phone",
                        validateTrigger: "onClick",
                      },
                    ]}
                  />
                </>
              )}
              <ProviderDetailsRow
                label="Spoken Language"
                name={["additionalData", "SpokenLanguage"]}
                Icon={LanguageIcon}
                textContent={providerData?.spokenLanguage}
                isCreate={isCreate}
                isEdit={isEdit}
                last={!isAuthenticated}
              />
              {isAuthenticated &&
                (isCoipaStaff || isAdmin || isCoipaFinance) && (
                  <>
                    <ProviderDetailsRow
                      label="Credentials"
                      Icon={CertificateIcon}
                      customContent={
                        <Credentials
                          name={["additionalData", "Credentials"]}
                          textContent={providerData?.credentials}
                          isCreate={isCreate}
                          isEdit={isEdit}
                        />
                      }
                    />
                    <ProviderDetailsRow
                      name={["additionalData", "NPI"]}
                      label="NPI"
                      Icon={IdCardIcon}
                      textContent={providerData?.npi}
                      isCreate={isCreate}
                      isEdit={isEdit}
                      mask="000-000-0000"
                      rules={[
                        {
                          pattern: npiPatternRegex,
                          message: "NPI Number must be a 10-digit number",
                          validateTrigger: "onClick",
                        },
                      ]}
                      last={isCreate || isEdit}
                    />
                    {!isCreate && !isEdit && (
                      <ProviderDetailsRow
                        label="COIPA ID"
                        Icon={IdCardIcon}
                        textContent={providerData?.coipaId}
                        last={true}
                      />
                    )}
                  </>
                )}
            </Row>
          </Col>
          {isAdmin && (
            <CommentRow
              label="Comment"
              name={["additionalData", "Comment"]}
              textContent={providerData?.comment}
              isCreate={isCreate}
              isEdit={isEdit}
            />
          )}
          {(isAdmin || isCoipaStaff) && (
            <Col span={24} className="provider_details">
              <Row className="provider_details_secure">
                <SectionTitle title="System Prefereces" />
                <ProviderDetailsRow
                  label="Username"
                  Icon={UserIcon}
                  customContent={
                    <UserNameField
                      text={providerData?.userName}
                      isCreate={isCreate}
                      personalInfo={personalInfo}
                      providersForm={providersForm}
                    />
                  }
                />
                {isAdmin && (
                  <>
                    <ProviderDetailsRow
                      label="Role"
                      Icon={RolesIcon}
                      textContent={userRoleNames.join(", ")}
                    />
                    {isCreate && (
                      <ProviderDetailsRow
                        label="Password"
                        Icon={PadLockIcon}
                        customContent={
                          <PasswordField providersForm={providersForm} />
                        }
                      />
                    )}
                  </>
                )}
              </Row>
            </Col>
          )}
        </Row>
        {isAdmin && (
          <Row className="provider_details">
            <Col span={24} className="provider_details">
              <Row className="provider_details_secure">
                <SectionTitle title="Authentication Method" />
                <Col>
                  <Row className="provider_details_content" gutter={[0, 20]}>
                    <Col className="provider_details_auth_method">
                      <Tabs
                        defaultActiveKey={AuthMethod.Email}
                        items={authMethodTabs(isEdit, isCreate, providerData)}
                        className="provider_details_auth_method_tabs"
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
        )}
      </Form>
      {isAdmin && (!isCreate || isEdit) && (
        <Row className="provider_details">
          <Col span={24} className="provider_details">
            <DisableUser providerData={providerData} isEdit={isEdit} />
          </Col>
        </Row>
      )}
      {isAdmin && (
        <Row className="provider_details">
          <Col span={24} className="provider_details">
            <Row className="provider_details_secure">
              <SectionTitle title="Groups" />
              <Col>
                <Row className="provider_details_content" gutter={[0, 20]}>
                  <Col>
                    <Button
                      type="primary"
                      size="large"
                      onClick={showGroupRoleModal}
                      disabled={!isCreate && !isEdit}
                    >
                      Add/Edit Groups
                    </Button>
                    <Modal
                      className="provider_modal"
                      open={isGroupRoleModalOpen}
                      width={300}
                      centered
                      footer={null}
                    >
                      <ModifyGroupAndRole
                        closeModal={handleGroupRoleCancel}
                        groupIds={providerData?.groupIds}
                        getGroupsData={getGroupData}
                      />
                    </Modal>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      )}
      {isEdit && hasDeleteProvider && (
        <Row className="provider_details">
          <Col span={24}>
            <ProviderDetailDeleteRow
              userId={providerData?.key!}
              refreshProviderList={refreshProviderList}
            />
          </Col>
        </Row>
      )}
    </>
  );
};

type ProviderDetailsHeaderProps = {
  providerData?: ProviderData;
  isCreate: boolean;
  updatePersonalInfoFormData: (data: PersonalInfoForm) => void;
  initialPersonalInfo: PersonalInfoForm;
  setIsEdit: (val: boolean) => void;
  isEdit: boolean;
  isLoadingDataNecessaryForSubmit: boolean;
};

const ProviderDetailsHeader: FC<ProviderDetailsHeaderProps> = ({
  providerData,
  isCreate,
  updatePersonalInfoFormData,
  initialPersonalInfo,
  setIsEdit,
  isEdit,
  isLoadingDataNecessaryForSubmit,
}: ProviderDetailsHeaderProps) => {
  const { isMobile } = useResponsive();
  const address = FormatAddress({
    address: providerData?.address,
    city: providerData?.city,
    state: providerData?.state,
    postalCode: providerData?.postalCode,
    country: providerData?.country,
  });

  const handleSwitchChange = (value: boolean) => {
    setIsEdit(value);
  };

  const onDiscardChanges = () => {
    setIsEdit(false);
  };

  const { isAdmin, isCoipaFinance, hasAddProvider, hasEditProvider } =
    useRolesPermissionsAccess();

  const { data: Specialities } = useGetSpecialitiesQuery(undefined, {
    skip: !isCreate && !isEdit,
  });

  const specialitiesOptions = useMemo<SelectProps["options"]>(
    () =>
      Specialities?.data.map((Specialities) => ({
        label: Specialities.name,
        value: Specialities.name,
      })),
    [Specialities]
  );

  return (
    <Row
      className="provider_details_header"
      gutter={[18, { xs: 10, sm: 10 }]}
      align="middle"
      justify="center"
    >
      <Col xs={24} md={6} className="provider_details_header_image_wrapper">
        {isCreate || isEdit ? (
          <Row justify="center">
            <Col xs={14} sm={8} md={24}>
              <Form.Item
                name="photo"
                initialValue={isEdit ? providerData?.imageUrl : undefined}
              >
                <PictureInput />
              </Form.Item>
            </Col>
            <Col xs={24} md={24}>
              <Form.Item
                name="jobTitle"
                initialValue={isEdit ? providerData?.jobTitle : undefined}
              >
                <Select
                  size="large"
                  placeholder="Title"
                  options={jobTitleOptions}
                  popupClassName="provider_details_header_image_wrapper_popup"
                />
              </Form.Item>
            </Col>
          </Row>
        ) : (
          <Row justify="center">
            <Col xs={14} sm={8} md={24} className="text-center">
              <Avatar
                shape="square"
                size="large"
                src={providerData?.imageUrl}
                className="provider_details_header_image_wrapper_image"
              >
                {getInitials(providerData?.name)}
              </Avatar>
              {providerData?.jobTitle && (
                <Tag
                  className="provider_details_header_image_wrapper_tag me-0 text-truncate"
                  title={providerData?.jobTitle}
                >
                  {providerData?.jobTitle}
                </Tag>
              )}
            </Col>
          </Row>
        )}
      </Col>
      <Col xs={24} md={18} className="provider_details_header_personal_info">
        <Row gutter={[10, 12]} align="middle">
          <Col xs={24} sm={24} md={16}>
            <Row justify="center" gutter={[5, 5]}>
              <Col span={24}>
                {isCreate || isEdit ? (
                  <Form.Item
                    name="primarySpeciality"
                    initialValue={
                      isEdit ? providerData?.primarySpeciality : undefined
                    }
                  >
                    <Select
                      size="large"
                      placeholder="Primary Specialty"
                      options={specialitiesOptions}
                      allowClear
                    />
                  </Form.Item>
                ) : (
                  <Title
                    level={4}
                    className="mb-0 provider_details_header_personal_info_speciality"
                  >
                    {providerData?.primarySpeciality}
                  </Title>
                )}
              </Col>
              <Col span={24}>
                {isCreate || isEdit ? (
                  <div>
                    <PersonalInfoPopover
                      updateFormData={updatePersonalInfoFormData}
                      initialData={initialPersonalInfo}
                      isEdit={isEdit}
                    />
                  </div>
                ) : (
                  <Title
                    level={isMobile ? 3 : 1}
                    className="mb-0 provider_details_header_personal_info_name"
                  >
                    {providerData?.name}
                  </Title>
                )}
              </Col>
              {!isCreate && !isEdit && address && (
                <Col span={24}>
                  <div
                    className="px-0 provider_details_header_personal_info_button"
                    onClick={() => {
                      window.open(
                        `https://www.google.com/maps/place/${address}`,
                        "_blank"
                      );
                    }}
                  >
                    <LocationIcon className="provider_details_header_personal_info_location_icon" />
                    <Paragraph className="mb-0 provider_details_header_personal_info_address">
                      {address}
                    </Paragraph>
                  </div>
                </Col>
              )}
              <Col span={24}>
                {isCreate || isEdit ? (
                  <Form.Item
                    name={["additionalData", "MemberSince"]}
                    initialValue={
                      isEdit
                        ? providerData?.memberSince &&
                        ParseDayJsDate(providerData?.memberSince)
                        : undefined
                    }
                    className="text-center text-md-start"
                  >
                    <DatePicker
                      className="provider_details_header_personal_info_datepicker"
                      placeholder="Member Since"
                      size="large"
                    />
                  </Form.Item>
                ) : (
                  providerData?.memberSince && (
                    <Text className="provider_details_header_personal_info_date">
                      Member since{" "}
                      {FormatDisplayDate(providerData?.memberSince)}
                    </Text>
                  )
                )}
              </Col>
            </Row>
          </Col>
          {(isAdmin || isCoipaFinance) && (hasAddProvider || hasEditProvider) && (
            <Col
              xs={24}
              sm={24}
              md={7}
              className="provider_details_header_buttons"
            >
              <Row align="middle" gutter={[10, 12]}>
                {isCreate ? (
                  <Col
                    xs={24}
                    className="provider_details_header_buttons_edit text-center"
                  >
                    <Button
                      size="large"
                      type="primary"
                      htmlType="submit"
                      loading={isLoadingDataNecessaryForSubmit}
                      className="w-100"
                    >
                      Create Provider
                    </Button>
                  </Col>
                ) : (
                  <Col span={24}>
                    <Row
                      gutter={[{ sm: 10 }, 12]}
                      align="middle"
                      justify="center"
                      className="text-center"
                    >
                      <Col span={24}>
                        <Paragraph className="mb-0">
                          Edit Mode
                          <Switch
                            checked={isEdit}
                            className="ms-2"
                            onChange={handleSwitchChange}
                          />
                        </Paragraph>
                      </Col>
                      <Col xs={24} sm={12} md={24}>
                        <Button
                          size="large"
                          disabled={!isEdit}
                          loading={isLoadingDataNecessaryForSubmit}
                          type="primary"
                          className="w-100"
                          htmlType="submit"
                        >
                          Save Changes
                        </Button>
                      </Col>
                      <Col xs={24} sm={12} md={24}>
                        <Button
                          size="large"
                          disabled={!isEdit}
                          type="default"
                          htmlType="reset"
                          onClick={onDiscardChanges}
                          className="w-100"
                        >
                          Discard Changes
                        </Button>
                      </Col>
                    </Row>
                  </Col>
                )}
              </Row>
            </Col>
          )}
        </Row>
      </Col>
    </Row>
  );
};

type PersonalInfoForm = Partial<{
  firstName: string;
  lastName: string;
  additionalData: Partial<{
    MiddleName: string;
  }>;
}>;

type PersonalInfoPopoverProps = {
  updateFormData: (data: PersonalInfoForm) => void;
  initialData: PersonalInfoForm;
  isEdit: boolean;
};

const PersonalInfoPopover: FC<PersonalInfoPopoverProps> = ({
  updateFormData,
  isEdit,
  initialData,
}) => {
  const [personalInfoForm] = Form.useForm<PersonalInfoForm>();
  const [personalInfoLabel, setPersonalInfoLabel] = useState<string>(
    isEdit ? SEE_DETAILS : ENTER_PERSONAL_INFO
  );

  const inputFields = useMemo<FormInputField[]>(
    () => [
      {
        name: "firstName",
        placeholder: "First Name",
        rules: [
          {
            required: true,
            message: "Please enter Firstname",
          },
          {
            pattern: namePatternRegex,
            message: namePatternMesssage,
          },
        ],
      },
      {
        name: ["additionalData", "MiddleName"],
        placeholder: "Middle Name",
        rules: [
          {
            pattern: namePatternRegex,
            message: namePatternMesssage,
          },
        ],
      },
      {
        name: "lastName",
        placeholder: "Last Name",
        rules: [
          {
            required: true,
            message: "Please enter Lastname",
          },
          {
            pattern: namePatternRegex,
            message: namePatternMesssage,
          },
        ],
      },
    ],
    []
  );

  const [popoverVisible, setPopoverVisible] = useState<boolean>(false);

  const handleVisibleChange = (visible: boolean) => {
    setPopoverVisible(visible);
  };

  const handleClear = () => {
    updateFormData({});
    setPopoverVisible(false);
    personalInfoForm.resetFields();
    setPersonalInfoLabel(isEdit ? SEE_DETAILS : ENTER_PERSONAL_INFO);
  };

  const handleApply = () => {
    personalInfoForm.validateFields().then((values) => {
      updateFormData?.(values);
      setPopoverVisible(false);
      setPersonalInfoLabel(SEE_DETAILS);
    });
  };

  return (
    <div className="d-flex">
      <Popover
        trigger="click"
        content={
          <Form form={personalInfoForm}>
            <Row gutter={[0, 12]}>
              {inputFields.map((field) => (
                <Col key={getKey(field)} span={24}>
                  <Title className="mb-0" level={5}>
                    {field.placeholder}
                  </Title>
                  <Form.Item
                    name={field.name}
                    initialValue={
                      isEdit ? getInitialValue(initialData, field) : undefined
                    }
                    rules={field.rules}
                  >
                    {field.mask ? (
                      <MaskedInput size="large" mask={field.mask} />
                    ) : (
                      <Input size="large" placeholder={field.placeholder} />
                    )}
                  </Form.Item>
                </Col>
              ))}
              <Col className="text-center" span={24}>
                <Button
                  id="applyPersonalInfo"
                  size="large"
                  type="primary"
                  onClick={handleApply}
                  className="me-2"
                >
                  Apply
                </Button>
                <Button size="large" type="default" onClick={handleClear}>
                  {isEdit ? RESET : CLEAR}
                </Button>
              </Col>
            </Row>
          </Form>
        }
        overlayClassName="provider_details_header_personal_info_popover"
        open={popoverVisible}
        arrow={false}
        onOpenChange={handleVisibleChange}
      >
        <Button
          size="large"
          type="text"
          className="provider_details_header_personal_info_enter_address_btn"
          onClick={() => setPopoverVisible(!popoverVisible)}
        >
          {personalInfoLabel}
          <DownOutlined className="ms-auto ms-md-3" />
        </Button>
      </Popover>
    </div>
  );
};

type ProviderDetailRowProps = {
  first?: boolean;
  name?: string | string[] | undefined;
  label: string;
  Icon: ComponentType<{ className: string }>;
  textContent?: string;
  customContent?: ReactNode;
  last?: boolean;
  isCreate?: boolean;
  isEdit?: boolean;
  rules?: Rule[];
  mask?: string;
  options?: SelectProps["options"] | undefined;
};

const ProviderDetailsRow: FC<ProviderDetailRowProps> = ({
  name,
  label,
  textContent,
  customContent,
  Icon,
  first,
  last,
  isCreate,
  isEdit,
  rules,
  mask,
  options,
}) => {
  return (
    <Col span={24}>
      <Row
        align="middle"
        className={`provider_details_content ${first ? "border-top" : ""} ${first || !last ? "border-bottom" : ""
          }`}
      >
        <Col xs={24} md={7} className="provider_details_content_icon_wrapper">
          <Icon className="provider_details_content_icon_wrapper_icon" />
          <Text className="provider_details_content_icon_wrapper_icon_name">
            {label}
          </Text>
        </Col>
        <Col xs={24} md={17} className="provider_details_content_info">
          {isCreate || isEdit ? (
            <Form.Item
              name={name}
              initialValue={isEdit ? textContent : undefined}
              rules={rules}
            >
              {mask ? (
                <MaskedInput size="large" mask={mask} />
              ) : options ? (
                <Select
                  size="large"
                  allowClear
                  placeholder={`Select ${label}`}
                  options={options}
                  showSearch
                />
              ) : (
                <Input size="large" placeholder={`Enter ${label}`} />
              )}
            </Form.Item>
          ) : customContent ? (
            customContent
          ) : (
            <Text className="provider_details_content_info_text">
              {textContent || "-"}
            </Text>
          )}
        </Col>
      </Row>
    </Col>
  );
};

type SectionTitleProps = {
  title: string;
};
const SectionTitle: FC<SectionTitleProps> = ({ title }) => {
  return (
    <Col span={24}>
      <Row className="provider_details_title_wrapper">
        <Col xs={18} md={12}>
          <Title level={4} className="section-badge-title mb-0">
            {title}
          </Title>
        </Col>
      </Row>
    </Col>
  );
};

type EmailTabProps = {
  isEdit: boolean;
  isCreate: boolean;
  authEmail?: string;
};

const EmailTab: FC<EmailTabProps> = ({
  isEdit,
  isCreate,
  authEmail,
}: EmailTabProps) => {
  return (
    <Row>
      <Col span={24}>
        <p className="provider_details_auth_method_description">
          Add an e-mail to allow the user to receive a one-time use code via
          email for authentication and self-service password reset.
        </p>
      </Col>
      <Col span={24} className="provider_details_auth_method_field">
        <Form.Item
          name="authEmail"
          initialValue={authEmail}
          rules={[
            {
              type: "email",
              message: "Please enter a valid Email",
            },
          ]}
        >
          <Input
            className="provider_details_auth_method_field_input w-100"
            size="large"
            placeholder="Enter Email"
            readOnly={!isEdit && !isCreate}
          />
        </Form.Item>
      </Col>
    </Row>
  );
};

type CommentRowProps = {
  name?: string | string[] | undefined;
  label: string;
  textContent?: string;
  isCreate?: boolean;
  isEdit?: boolean;
  rules?: Rule[];
};

const CommentRow: FC<CommentRowProps> = ({
  name,
  label,
  textContent,
  isCreate,
  isEdit,
  rules,
}) => {
  return (
    <Col span={24}>
      <Row className="provider_details_content border-top">
        <Col xs={24}>
          <Text className="provider_details_content_icon_wrapper_icon_name">
            {label}
          </Text>
        </Col>
        <Col xs={24} className="provider_details_content_info">
          {isCreate || isEdit ? (
            <Form.Item
              name={name}
              initialValue={isEdit ? textContent : undefined}
              rules={rules}
            >
              <TextArea size="large" rows={4} placeholder={`Enter ${label}`} />
            </Form.Item>
          ) : (
            <Text className="provider_details_content_info_text">
              {textContent || "-"}
            </Text>
          )}
        </Col>
      </Row>
    </Col>
  );
};

type PhoneTabProps = {
  isEdit: boolean;
  isCreate: boolean;
  authPhone?: string;
};
const PhoneTab: FC<PhoneTabProps> = ({
  isEdit,
  isCreate,
  authPhone,
}: PhoneTabProps) => {
  const { data: countryCodes } = useGetCountryCodesQuery(undefined);
  const countryCodeOptions = useMemo<SelectProps["options"]>(
    () =>
      countryCodes?.data.map((country) => ({
        label: `${country.name} (${country.dialCode})`,
        value: country.dialCode,
      })),
    [countryCodes]
  );
  return (
    <Row gutter={[10, 10]} className="provider_details_auth_method w-100">
      <Col span={24}>
        <Paragraph className="provider_details_auth_method_description">
          Add an number to allow the user to recieve one-time use code via
          number to use authentication and self service password reset
        </Paragraph>
      </Col>
      <Col span={24}>
        <Form.Item
          className="w-100"
          name="authPhone"
          initialValue={authPhone}
          rules={[
            {
              pattern: authPhonePatternRegex,
              message: authPhonePatternMesssage,
            },
          ]}
        >
          <Input
            addonBefore={
              isCreate ? (
                <Form.Item name="authPhoneCountryCode">
                  <Select
                    size="large"
                    showSearch
                    popupMatchSelectWidth={300}
                    placeholder="Select Country Code"
                    options={countryCodeOptions}
                    disabled={!isCreate}
                  />
                </Form.Item>
              ) : undefined
            }
            size="large"
            placeholder={
              isCreate
                ? "Enter Mobile Number"
                : "Enter Mobile Number with Country Code"
            }
            readOnly={!isEdit && !isCreate}
            autoComplete="off"
          />
        </Form.Item>
      </Col>
    </Row>
  );
};

const authMethodTabs = (
  isEdit: boolean,
  isCreate: boolean,
  providerData?: ProviderData
) => {
  return [
    {
      key: AuthMethod.Email,
      label: AuthMethod.Email,
      children: (
        <EmailTab
          isEdit={isEdit}
          isCreate={isCreate}
          authEmail={providerData?.authEmail}
        />
      ),
    },
    {
      key: AuthMethod.Phone,
      label: AuthMethod.Phone,
      children: (
        <PhoneTab
          isEdit={isEdit}
          isCreate={isCreate}
          authPhone={providerData?.authPhone}
        />
      ),
    },
  ];
};

type UserNameFieldProps = {
  text?: string;
  isCreate: boolean;
  personalInfo: PersonalInfoForm;
  providersForm: FormInstance<ProviderDetailsFormData>;
};
const UserNameField: FC<UserNameFieldProps> = ({
  text,
  isCreate,
  personalInfo,
  providersForm,
}: UserNameFieldProps) => {
  const { data: domains } = useGetDomainsQuery(undefined, { skip: !isCreate });
  const [checked, setIsChecked] = useState(true);
  const [issuerAssignedId, setIssuerAssignedId] = useState<string>();
  const [issuer, setIssuer] = useState<string>();
  const { data: checkUserNameData, isFetching } = useCheckUserNameExistQuery(
    [issuerAssignedId, issuer],
    {
      skip: !issuerAssignedId || !issuer,
    }
  );
  const isUserNameExists =
    issuerAssignedId && issuer && checkUserNameData?.data;

  const firstName = checked ? personalInfo.firstName?.[0] : undefined;
  const lastName = checked ? personalInfo.lastName : undefined;
  const middleName = checked
    ? personalInfo.additionalData?.MiddleName?.[0]
    : undefined;

  const userName =
    firstName && lastName ? `${firstName}${lastName}` : undefined;
  const userNameWithMiddleName =
    firstName && middleName && lastName
      ? `${firstName}${middleName}${lastName}`
      : userName;

  const [isReadOnly, setIsReadOnly] = useState(true);

  const handleCheckboxChange = useCallback(
    (checked: boolean) => {
      setIsChecked(checked);
      setIsReadOnly(checked);
      setIssuerAssignedId(
        userName || userNameWithMiddleName ? issuerAssignedId : undefined
      );
    },
    [issuerAssignedId, userName, userNameWithMiddleName]
  );

  useEffect(() => {
    userName
      ? providersForm.setFieldValue(
        ["identity", "issuerAssignedId"],
        RemoveSpaceFromString(userName.toLowerCase())
      )
      : checked &&
      providersForm.setFieldValue(
        ["identity", "issuerAssignedId"],
        undefined
      );

    userName && setIssuerAssignedId(userName);
  }, [providersForm, userName, checked]);

  useEffect(() => {
    const nameWithMiddleName = isUserNameExists
      ? userNameWithMiddleName
      : undefined;

    isUserNameExists &&
      nameWithMiddleName &&
      providersForm.setFieldValue(
        ["identity", "issuerAssignedId"],
        RemoveSpaceFromString(nameWithMiddleName.toLowerCase())
      );

    nameWithMiddleName && setIssuerAssignedId(nameWithMiddleName);
  }, [isUserNameExists, providersForm, userNameWithMiddleName]);

  return (
    <Row align="middle" className="w-100">
      <Col className="provider_details_content_info w-100">
        {isCreate ? (
          <Row
            align="middle"
            className="provider_details_content_info_text w-100"
            gutter={[10, 10]}
          >
            <Col xs={24} sm={10}>
              <Form.Item
                name={["identity", "issuerAssignedId"]}
                rules={[
                  {
                    required: true,
                    message: "Username is required",
                  },
                  {
                    pattern: spacePatternRegex,
                    message: "Spaces are not allowed",
                  },
                  {
                    pattern: namePatternRegex,
                    message: namePatternMesssage,
                  },
                ]}
                validateStatus={
                  issuerAssignedId
                    ? isUserNameExists
                      ? "error"
                      : "success"
                    : undefined
                }
                help={
                  issuerAssignedId
                    ? isUserNameExists && "Username already exists"
                    : undefined
                }
              >
                <Input
                  type="text"
                  autoComplete="off"
                  placeholder="Enter UserName"
                  size="large"
                  onBlur={(e) => setIssuerAssignedId(e.target.value)}
                  readOnly={isReadOnly}
                  suffix={
                    isFetching && issuerAssignedId && issuer ? (
                      <LoadingOutlined />
                    ) : issuerAssignedId && issuer ? (
                      isUserNameExists ? (
                        <CloseCircleFilled style={{ color: "red" }} />
                      ) : (
                        <CheckCircleFilled style={{ color: "#50844b" }} />
                      )
                    ) : undefined
                  }
                />
              </Form.Item>
            </Col>
            <Col xs={4} sm={2} className="text-center">
              <span className="provider_details_content_info_text_at">@</span>
            </Col>
            <Col xs={20} sm={12}>
              <Form.Item
                name={["identity", "issuer"]}
                rules={[
                  {
                    required: true,
                    message: "Domain is required",
                  },
                ]}
              >
                <Select
                  size="large"
                  allowClear
                  placeholder="Select Domain"
                  onChange={(val) => setIssuer(val)}
                >
                  {domains?.data?.map((item: string) => (
                    <Select.Option key={item} value={item}>
                      {item}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={24}>
              <Checkbox
                defaultChecked
                onChange={(e) => handleCheckboxChange(e.target.checked)}
              >
                Auto-generate username
              </Checkbox>
            </Col>
            <Col span={24}>
              <Form.Item
                name="isSendEmail"
                initialValue={true}
                valuePropName="checked"
              >
                <Checkbox defaultChecked>Send onboarding e-mail</Checkbox>
              </Form.Item>
            </Col>
          </Row>
        ) : (
          <Text className="provider_details_content_info_text">{text}</Text>
        )}
      </Col>
    </Row>
  );
};

interface PasswordFieldProps {
  providersForm: FormInstance<ProviderDetailsFormData>;
}

const PasswordField: FC<PasswordFieldProps> = ({ providersForm }) => {
  const [isReadOnly, setIsReadOnly] = useState(true);
  const pwdRef = useRef<any>(null);

  const handleCheckboxChange = useCallback(
    (checked: boolean) => {
      const password = checked ? randomPasswordGenerator() : undefined;
      setIsReadOnly(checked);
      providersForm.setFieldValue(["passwordProfile", "password"], password);
    },
    [providersForm]
  );

  const copyToClipboard = useCallback(() => {
    const copyText = pwdRef.current?.input?.value;

    if (copyText) {
      if (copy(copyText)) {
        message.success("Copied!");
      } else {
        message.error("Copy to Clipboard failed");
      }
    }
  }, []);

  return (
    <Row className="w-100" align="middle" gutter={[10, 10]}>
      <Col span={23}>
        <Form.Item
          name={["passwordProfile", "password"]}
          initialValue={randomPasswordGenerator()}
          rules={[
            {
              required: true,
              message: "Password is required",
            },
            {
              pattern: passwordPatternRegex,
              message:
                "Password must have a minimum of 8 characters, incorporating elements from at least three of the following categories: Lowercase letters, Uppercase letters, Numbers, and Symbols.",
              validateTrigger: ["onBlur", "onClick"],
            },
          ]}
        >
          <Input.Password
            readOnly={isReadOnly}
            size="large"
            placeholder="Enter Password"
            ref={pwdRef}
          />
        </Form.Item>
      </Col>
      <Col
        onClick={copyToClipboard}
        className="text-start"
        style={{ cursor: "pointer" }}
        span={1}
      >
        <CopyOutlined title="Copy to clipboard" />
      </Col>
      <Col span={24}>
        <Checkbox
          defaultChecked
          onChange={(e) => handleCheckboxChange(e.target.checked)}
        >
          Auto-generate password
        </Checkbox>
      </Col>
    </Row>
  );
};

type ProviderDetailDeleteRowProps = {
  userId: string;
  refreshProviderList: () => void;
};

const ProviderDetailDeleteRow: FC<ProviderDetailDeleteRowProps> = ({
  userId,
  refreshProviderList,
}) => {
  const [confirmDelete, setConfirmDelete] = useState(false);

  const handleDeleteUser = () => {
    setConfirmDelete(true);
  };

  const handleDiscardDelete = () => {
    setConfirmDelete(false);
  };

  const [deleteUser, { isLoading }] = useDeleteProviderMutation();

  const handleConfirmDelete = () => {
    try {
      deleteUser(userId)
        .unwrap()
        .then((res) => {
          if (res.success) {
            message.success("Provider deleted successfully!");
            refreshProviderList();
          } else {
            message.error(res.message);
          }
        })
        .catch((error) => {
          message.error(error.data.message || error.data.title);
        });
    } catch (error) {
      console.error("An unexpected error occurred:", error);
    }
  };
  return (
    <Row
      align="middle"
      justify="center"
      className="provider_details_delete_section"
    >
      <Col xs={24} md={confirmDelete ? 14 : 20}>
        <Paragraph className="mb-0">
          {confirmDelete ? (
            <span>
              Are you sure you want to delete this user? Please press{" "}
              <strong>Confirm Delete</strong> to Proceed.
            </span>
          ) : (
            <span>
              Please be aware that after deletion, all providers under this user
              will need to be associated with a new user and primary group name.
            </span>
          )}
        </Paragraph>
      </Col>
      {!confirmDelete ? (
        <Col xs={24} md={4} className="text-end">
          <Button
            size="large"
            className="delete-button"
            onClick={handleDeleteUser}
          >
            Delete User
          </Button>
        </Col>
      ) : (
        <>
          <Col xs={12} md={5} className="text-center">
            <Button
              size="large"
              className="discard-delete-button"
              onClick={handleDiscardDelete}
            >
              Discard Delete
            </Button>
          </Col>
          <Col xs={12} md={5} className="text-end">
            <Button
              size="large"
              className="delete-button"
              onClick={handleConfirmDelete}
              loading={isLoading}
            >
              Confirm Delete
            </Button>
          </Col>
        </>
      )}
    </Row>
  );
};

type PictureInputProps = {
  value?: string;
  onChange?: (value: string) => void;
};

const PictureInput: FC<PictureInputProps> = ({ value, onChange }) => {
  const beforeUpload = async (file: RcFile): Promise<boolean> => {
    try {
      const imageUrl = await handleImageUpload(file);
      onChange?.(imageUrl);
    } catch (error) {
      console.error("File read error:", error);
    }
    return false;
  };

  return (
    <Upload
      listType="picture-card"
      className="providers_details_header_upload"
      showUploadList={false}
      beforeUpload={beforeUpload}
    >
      {value ? (
        <Avatar
          shape="square"
          size="large"
          src={value}
          className="provider_details_header_image_wrapper_image"
        ></Avatar>
      ) : (
        <OutBoxIcon className="provider_details_header_image_wrapper_icon" />
      )}
    </Upload>
  );
};

type EmailFieldProps = {
  name: string;
  providerData?: ProviderData;
  isEdit: boolean;
  isCreate: boolean;
};

const EmailField: FC<EmailFieldProps> = ({
  name,
  providerData,
  isEdit,
  isCreate,
}) => {
  const [email, setEmail] = useState<string>();

  let parameters: [email: string | undefined, userId?: string | undefined] = [
    email,
  ];

  if (isEdit && providerData?.key) {
    parameters = [email, providerData?.key];
  }

  const { data: checkEmailData, isLoading: checkingEmail } =
    useCheckEmailExistQuery(parameters, {
      skip: !email,
    });

  const isEmailExists = email && checkEmailData?.data;

  return (
    <Form.Item
      name={name}
      initialValue={!isEdit ? providerData?.email : undefined}
      rules={[
        {
          required: true,
          type: "email",
          message: "Please enter a valid Email",
        },
      ]}
      validateStatus={email ? (isEmailExists ? "error" : "success") : undefined}
      help={email ? isEmailExists && "Email already exists" : undefined}
    >
      {isCreate || isEdit ? (
        <Input
          size="large"
          placeholder={`Enter Email`}
          onBlur={(e) => setEmail(e.target.value)}
          suffix={
            checkingEmail ? (
              <LoadingOutlined />
            ) : email ? (
              isEmailExists ? (
                <CloseCircleFilled style={{ color: "red" }} />
              ) : (
                <CheckCircleFilled style={{ color: "#50844b" }} />
              )
            ) : undefined
          }
        />
      ) : (
        <Text className="provider_details_content_info_text">
          {providerData?.email}
        </Text>
      )}
    </Form.Item>
  );
};

type CredentialsProps = {
  name?: string | string[] | undefined;
  textContent?: string;
  isCreate?: boolean;
  isEdit?: boolean;
  rules?: Rule[];
};

const Credentials: FC<CredentialsProps> = ({
  name,
  textContent,
  isCreate,
  isEdit,
  rules,
}) => {
  const { data: credentials } = useGetCredentialsQuery(undefined, {
    skip: !isCreate && !isEdit,
  });

  return isCreate || isEdit ? (
    <Form.Item
      name={name}
      initialValue={isEdit ? textContent : undefined}
      rules={rules}
    >
      <Select size="large" allowClear placeholder="Select Credentials">
        {credentials?.data.map((item: CredentialsDto) => (
          <Select.Option key={item.code} value={item.code}>
            {item.code}
          </Select.Option>
        ))}
      </Select>
    </Form.Item>
  ) : (
    <Text className="provider_details_content_info_text">
      {textContent || "-"}
    </Text>
  );
};

type DisableUserProps = {
  providerData?: ProviderData;
  isEdit: boolean;
};

const DisableUser: FC<DisableUserProps> = ({
  providerData,
  isEdit,
}: DisableUserProps) => {
  const [disableUserForm] = Form.useForm();
  const [isAccountEnabled, setIsAccountEnabled] = useState(
    providerData?.accountEnabled
  );
  const [accountBlockedAt, setAccountBlockedAt] = useState(
    providerData?.accountBlockedAt
  );
  const [updateUserAccountStatusApi, { isLoading: updateUserLoading }] =
    useUpdateUserAccountStatusMutation();

  const handleSave = (accountEnabled: boolean) => {
    disableUserForm
      .validateFields()
      .then((values) => {
        const updatedProviderRequest = {
          userId: providerData?.key,
          accountEnabled: accountEnabled,
          reason: values.reason,
        };

        updateUserAccountStatusApi(updatedProviderRequest)
          .unwrap()
          .then((res) => {
            if (res.success) {
              message.success("Provider account status updated successfully");
              setIsAccountEnabled(accountEnabled);
              setAccountBlockedAt(undefined);
              disableUserForm.resetFields();
            } else {
              message.error(res.message);
            }
          })
          .catch((error) =>
            message.error(error.data.message || error.data.title)
          );
      })
      .catch((info) => {
        console.error("Provider Update Account Status Validate Failed:", info);
      });
  };

  return (
    <Row className="provider_details_secure">
      <Col xs={24}>
        <Row className="provider_details_content" gutter={[0, 12]}>
          <Col span={24} className="provider_details_content_block_user">
            {!isAccountEnabled ? (
              <CloseCircleFilled
                className="provider_details_content_block_user_icon"
                style={{ color: "red" }}
              />
            ) : (
              <CheckCircleFilled
                className="provider_details_content_block_user_icon"
                style={{ color: "#50844b" }}
              />
            )}
            <Title level={4} className="mb-0 ms-2">
              {!isAccountEnabled
                ? "This user is disabled"
                : "This user is enabled"}
            </Title>
          </Col>
          {isEdit && (
            <>
              <Col>
                <Text className="mb-0 provider_details_content_block_user_description">
                  {!isAccountEnabled
                    ? `Account has been blocked${accountBlockedAt ? " on " : ""
                    }${accountBlockedAt
                      ? FormatDisplayDateTime(accountBlockedAt, true)
                      : ""
                    }`
                    : "Disable this user from login by Blocking User Account (can be unblocked any time)"}
                </Text>
              </Col>
              <Col className="w-100">
                <Form form={disableUserForm}>
                  <Row gutter={[{ sm: 10 }, 10]}>
                    <Col xs={24} sm={18} md={18}>
                      <Form.Item
                        name="reason"
                        rules={[
                          {
                            required: true,
                            message: "Reason is required",
                          },
                        ]}
                      >
                        <Input
                          className="w-100"
                          size="large"
                          placeholder={
                            !isAccountEnabled
                              ? "Reason for unblocking..."
                              : "Add reason code to block user from login"
                          }
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={6} md={6}>
                      {!isAccountEnabled ? (
                        <Button
                          size="large"
                          className="w-100"
                          type="primary"
                          onClick={() => handleSave(true)}
                          loading={updateUserLoading}
                        >
                          Unblock
                        </Button>
                      ) : (
                        <Button
                          size="large"
                          className="w-100 delete-button"
                          onClick={() => handleSave(false)}
                          loading={updateUserLoading}
                        >
                          Block User
                        </Button>
                      )}
                    </Col>
                  </Row>
                </Form>
              </Col>
            </>
          )}
        </Row>
      </Col>
    </Row>
  );
};

export default ProviderDetails;
