import { FC, JSX } from "react";
import { Route, Routes } from "react-router-dom";
import { AdminDashboardLayout } from "../../components/admindashboardlayout/AdminDashboardLayout";
import Speciality from "../../../pages/admin/speciality/Speciality";
import { PageRoutesConstant } from "./PageRoutes";
import UserDepartment from "../../../pages/admin/user-department/UserDepartment";
import UserGroup from "../../../pages/admin/user-group/UserGroup";
import QuickLinks from "../../../pages/admin/quickLinks/QuickLinks";

interface RouteKey {
  [key: string]: {
    path: string;
    element?: JSX.Element;
  };
}

interface IRoute {
  Admin: RouteKey;
}

export const AdminFlowRoutesConstant: IRoute = {
  Admin: {
    Speciality: {
      path: `${PageRoutesConstant.Page.Admin.path}/specialties`,
      element: <Speciality />,
    },
    UserDepartment: {
      path: `${PageRoutesConstant.Page.Admin.path}/user-departments`,
      element: <UserDepartment />,
    },
    UserGroups: {
      path: `${PageRoutesConstant.Page.Admin.path}/user-groups`,
      element: <UserGroup />,
    },
    QuickLinks: {
      path: `${PageRoutesConstant.Page.Admin.path}/quick-links`,
      element: <QuickLinks />,
    },
  },
};

const AdminRoutes = () => {
  return (
    <Routes>
      {Object.keys(AdminFlowRoutesConstant.Admin).map((key) => {
        const route = AdminFlowRoutesConstant.Admin[key];
        return (
          <Route
            key={key}
            path={route.path.replace(PageRoutesConstant.Page.Admin.path, "")}
            element={route.element}
          />
        );
      })}
    </Routes>
  );
};

const AdminFlowRoutes: FC = () => {
  return (
    <AdminDashboardLayout>
      <AdminRoutes />
    </AdminDashboardLayout>
  );
};

export default AdminFlowRoutes;
