import { Avatar, Button, Col, Layout, Modal, Row } from "antd";
import {
  FC,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import { Link } from "react-router-dom";
import { MenuOutlined } from "@ant-design/icons";
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
  useIsAuthenticated,
  useMsal,
} from "@azure/msal-react";
import { AccountInfo, EndSessionRequest } from "@azure/msal-browser";
import useTheme from "../../settings/theme/UseTheme";
import useResponsive from "../../../hooks/useResponsive";
import { PageRoutesConstant } from "../../router/components/PageRoutes";
import {
  getAndDeleteCachedAzureADConfig,
  getCachedScopes,
} from "../../settings/config/AzureAuthenticationConfig";
import UserProfile from "./profile/UserProfile";
import "./MainHeader.style.scss";
import { AdminSettingsIcon } from "../../../assets/images/icons";
import useRolesPermissionsAccess from "../../../hooks/useRolesPermissionsAccess";
import { msalInstance } from "../../..";
import { useDeleteUserTemporaryPasswordMutation } from "../../../services/users";

const { Header } = Layout;

const MainHeader: FC = () => {
  const { logo, mobileLogo } = useTheme();
  const { isMobile } = useResponsive();
  const isAuthenticated = useIsAuthenticated();

  return (
    <Header className="px-2">
      <Row align="middle">
        <Col xs={6} md={4} xl={3} className="p-0">
          <Link to={PageRoutesConstant.Page.Home.path} reloadDocument>
            <div className="div-logo"></div>
            <div
              style={{
                backgroundImage: `url(${isMobile ? mobileLogo : logo})`,
              }}
              className="img-logo"
            ></div>
          </Link>
        </Col>
        <Col xs={16} md={10} xl={7} className="p-0 d-flex align-items-center">
          {isAuthenticated && (
            <>
              <div className="div-bar"></div>
              <div className="div-text">User Management</div>
            </>
          )}
        </Col>
        <AccountManagementHeaderItem />
      </Row>
    </Header>
  );
};

const AccountManagementHeaderItem: FC = () => {
  const { accounts, instance } = useMsal();
  const account = accounts[0] as AccountInfo | undefined;
  const [deleteUserTemporaryPassword] =
    useDeleteUserTemporaryPasswordMutation();

  const logout = () => {
    const authority = getAndDeleteCachedAzureADConfig()?.authority;
    const logOutRequest: EndSessionRequest = {
      authority: authority,
      account: account,
      postLogoutRedirectUri: window.location.origin,
    };
    instance.logoutRedirect(logOutRequest);
  };

  const [menuOpen, setMenuOpen] = useState(false);
  const hamburgerButtonRef = useRef<HTMLButtonElement>(null);
  const [isProfileModalOpen, setIsProfileModalOpen] = useState<boolean>(false);
  const { isAdmin } = useRolesPermissionsAccess();

  const handleMenuOk = () => {
    setMenuOpen(false);
  };
  const handleMenuCancel = () => {
    setMenuOpen(false);
    if (hamburgerButtonRef.current) {
      hamburgerButtonRef.current.blur();
    }
  };

  useLayoutEffect(() => {
    const handler = handleMenuCancel;
    window.addEventListener("resize", handler);
    return () => {
      window.removeEventListener("resize", handler);
    };
  }, []);

  const name = account?.idTokenClaims?.name ?? "User";

  const onAvatarClick = () => {
    setIsProfileModalOpen(true);
  };

  const closeProfileModal = () => {
    setIsProfileModalOpen(false);
  };

  const handleLoginClick = () => {
    const scopes = getCachedScopes();
    instance
      .loginRedirect({
        scopes,
        redirectUri: window.location.origin,
      })
      .catch((err) => {
        console.error(err);
      });
  };

  useEffect(() => {
    msalInstance.handleRedirectPromise().then((res) => {
      if (res !== null) {
        deleteUserTemporaryPassword(undefined).unwrap();
      }
    });
  }, []);

  return (
    <Col xs={2} md={10} xl={14}>
      <div className="d-block d-md-none text-end">
        <Button
          type="text"
          className="hamburger-btn"
          ref={hamburgerButtonRef}
          onClick={() => {
            setMenuOpen(true);
            hamburgerButtonRef.current?.blur();
          }}
        >
          <MenuOutlined className="hamburger-icon" />
        </Button>
      </div>
      <div className="d-none d-md-block">
        <Row
          align="middle"
          justify="space-between"
          gutter={[
            { md: 4, xl: 0 },
            { md: 0, xl: 16 },
          ]}
        >
          <Col
            md={16}
            xl={20}
            xxl={21}
            className="img-profilename justify-content-md-end"
          >
            <AuthenticatedTemplate>
              <label className="profile-name">
                Hi, <b>{name}</b>
              </label>
              <Avatar
                className={`profile-name-case ${!isAdmin && "me-2"}`}
                onClick={onAvatarClick}
              >
                {name[0].toUpperCase()}
              </Avatar>
              <Modal
                open={isProfileModalOpen}
                onCancel={closeProfileModal}
                footer={null}
                width={750}
                destroyOnClose={true}
                className="profile-modal"
              >
                <UserProfile closeUserProfileDetailModal={closeProfileModal} />
              </Modal>
              {isAdmin && (
                <div className="admin-settings-icon mx-2">
                  <Link to={PageRoutesConstant.Page.Admin.path} reloadDocument>
                    <AdminSettingsIcon />
                  </Link>
                </div>
              )}
            </AuthenticatedTemplate>
          </Col>
          <Col md={8} xl={4} xxl={3} className="text-md-center">
            <AuthenticatedTemplate>
              <Button
                size="large"
                type="default"
                className="btn-logout"
                onClick={logout}
              >
                Log Out
              </Button>
            </AuthenticatedTemplate>
            <UnauthenticatedTemplate>
              <Button
                size="large"
                type="default"
                ghost
                onClick={handleLoginClick}
              >
                Login
              </Button>
            </UnauthenticatedTemplate>
          </Col>
        </Row>
      </div>
      <Modal
        className="menu-modal"
        style={{ top: 85 }}
        open={menuOpen}
        closable={false}
        footer={null}
        keyboard={true}
        maskClosable={true}
        confirmLoading={true}
        onOk={handleMenuOk}
        onCancel={handleMenuCancel}
      >
        <Row align="middle" justify="start" gutter={[12, 16]}>
          <AuthenticatedTemplate>
            <Col xs={24}></Col>
            <Col xs={24} className="img-profilename">
              <label className="profile-name">
                Hi, <b>{name}</b>
              </label>
              <Avatar className="profile-name-case" onClick={onAvatarClick}>
                {name[0].toUpperCase()}
              </Avatar>
              {isAdmin && (
                <div className="admin-settings-icon mx-2">
                  <Link to={PageRoutesConstant.Page.Admin.path} reloadDocument>
                    <AdminSettingsIcon />
                  </Link>
                </div>
              )}
            </Col>
            <Col xs={12} sm={10}>
              <Button
                size="large"
                type="default"
                className="btn-logout"
                onClick={logout}
              >
                Log Out
              </Button>
            </Col>
          </AuthenticatedTemplate>
          <UnauthenticatedTemplate>
            <Col xs={24}>
              <Button
                size="large"
                type="default"
                ghost
                onClick={handleLoginClick}
              >
                Login
              </Button>
            </Col>
          </UnauthenticatedTemplate>
        </Row>
      </Modal>
    </Col>
  );
};

export default MainHeader;
