import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { getAuthToken } from "../common/settings/config/AzureAuthenticationConfig";
import { SchemaExtensionData } from "../common/interfaces/SchemaExtensionData";
import { GroupNameDto } from "../common/interfaces/GroupNameDto";

export const groupApi = createApi({
  reducerPath: "groupApi",
  baseQuery: fetchBaseQuery({
    baseUrl: "/api/groups",
    prepareHeaders: async (headers) => {
      const token = await getAuthToken();
      headers.set("Authorization", `Bearer ${token}`);
      return headers;
    },
  }),
  endpoints: (builder) => ({
    getGroupSchemaExtensions: builder.query<ResponseDto<SchemaExtensionData>,undefined>({ query: () => `/schemaextensions` }),
    createGroup: builder.mutation<ResponseDto<string | undefined>, any>({
      query: (data) => ({
        url: ``,
        method: "POST",
        body: data,
      }),
    }),
    getGroupSchemaExtensionId: builder.query<ResponseDto<string>, undefined>({ query: () => `/schemaextensionid` }),
    updateGroup: builder.mutation<ResponseDto<boolean>, any>({
      query: (data) => ({
        url: ``,
        method: "PATCH",
        body: data,
      }),
    }),
    getGroups: builder.query<ResponseDto<GroupNameDto[]>, undefined>({
      query: () => `/list`,
    }),
    deleteGroup: builder.mutation<ResponseDto<boolean>, any>({
      query: (groupId) => ({
        url: `/${groupId}`,
        method: "DELETE",
      }),
    }),
  }),
});

type ResponseDto<T> = {
  success: boolean;
  data: T;
  message?: string | undefined;
};

export const {
  useGetGroupSchemaExtensionsQuery,
  useCreateGroupMutation,
  useGetGroupSchemaExtensionIdQuery,
  useUpdateGroupMutation,
  useGetGroupsQuery,
  useDeleteGroupMutation,
} = groupApi;
