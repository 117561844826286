import Icon from "@ant-design/icons";
import { CustomIconComponentProps } from "@ant-design/icons/lib/components/Icon";

const OutBoxSvg = () => (
  <svg id="Icon" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path
      d="m21.568 13.257-1.431-5.018c-.337-1.171-1.424-1.989-2.643-1.989h-1.495c-.414 0-.75.336-.75.75s.336.75.75.75h1.495c.554 0 1.048.372 1.201.902l1.026 3.598h-2.721c-.965 0-1.75.785-1.75 1.75 0 .689-.561 1.25-1.25 1.25h-4c-.689 0-1.25-.561-1.25-1.25 0-.965-.785-1.75-1.75-1.75h-2.721l1.025-3.596c.153-.533.647-.904 1.201-.904h1.495c.414 0 .75-.336.75-.75s-.336-.75-.75-.75h-1.495c-1.219 0-2.306.818-2.643 1.991l-1.43 5.016c-.121.424-.182.862-.182 1.302v4.441c0 1.517 1.233 2.75 2.75 2.75h14c1.517 0 2.75-1.233 2.75-2.75v-4.441c0-.44-.061-.878-.182-1.302zm-1.318 5.743c0 .689-.561 1.25-1.25 1.25h-14c-.689 0-1.25-.561-1.25-1.25v-4.441c0-.251.036-.5.093-.744.043-.037.096-.065.157-.065h3c.138 0 .25.112.25.25 0 1.517 1.233 2.75 2.75 2.75h4c1.517 0 2.75-1.233 2.75-2.75 0-.138.112-.25.25-.25h3c.061 0 .113.028.157.065.057.244.093.493.093.744z"
      fill="#112d55"
    />
    <path
      d="m9.474 5.564c.295.292.771.287 1.061-.008l.716-.727v7.17c0 .414.336.75.75.75s.75-.336.75-.75v-7.199l.722.716c.293.292.769.291 1.061-.004.292-.294.29-.769-.004-1.061l-2-1.985c-.141-.14-.331-.218-.528-.218h-.004c-.2 0-.391.082-.53.224l-2 2.03c-.291.295-.287.77.008 1.061z"
      fill="#549bff"
    />
  </svg>
);

export const OutBoxIcon = (props: Partial<CustomIconComponentProps>) => (
  <Icon component={OutBoxSvg} {...props} />
);
