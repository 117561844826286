const b64toBlob = (base64: string, contentType: string) => {
  const byteCharacters = atob(base64);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += 512) {
    const slice = byteCharacters.slice(offset, offset + 512);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  return new Blob(byteArrays, { type: contentType });
};

export const DownloadFile = (
  filetype: string,
  base64: string,
  filename: string
) => {
  const blob = b64toBlob(base64, filetype);
  const url = URL.createObjectURL(blob);

  const downloadLink = document.createElement("a");
  const fileName = filename;

  downloadLink.href = url;
  downloadLink.download = fileName;
  downloadLink.click();
};
