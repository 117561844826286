export const Permissions = {
  AddAppRole: "app.role.add",
  EditAppRole: "app.role.edit",
  DeleteAppRole: "app.role.delete",
  AddUser: "user.add",
  EditUser: "user.edit",
  DeleteUser: "user.delete",
  AddProvider: "provider.add",
  EditProvider: "provider.edit",
  DeleteProvider: "provider.delete",
  AddClinic: "clinic.add",
  EditClinic: "clinic.edit",
  DeleteClinic: "clinic.delete",
  ViewReports: "reports.view",
  ExportReports: "reports.export",
  ExportUsers: "users.export",
  ExportProviders: "providers.export",
  ExportClinics: "clinics.export",
};
