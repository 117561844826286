import {
  DeleteOutlined,
  EditOutlined,
  LeftOutlined,
  LoadingOutlined,
  PlusOutlined,
  RightOutlined,
} from "@ant-design/icons";
import { Button, Col, Modal, Row, Spin, Table, Typography } from "antd";
import type { ColumnsType } from "antd/es/table";
import { FC, useMemo, useState } from "react";
import "./QuickLinks.style.scss";
import QuickLinksDetails from "./details/QuickLinksDetails";
import { QuickLinksData } from "../../../common/interfaces/QuickLinksData";
import {
  useDeleteQuickLinkMutation,
  useGetQuickLinksQuery,
} from "../../../services/quickLinks";

const { Text } = Typography;

const QuickLinks: FC = () => {
  const { data, refetch, isLoading } = useGetQuickLinksQuery(undefined);
  const refetchQuickLinks = (): void => {
    refetch();
  };

  const quickLinks = useMemo<QuickLinksData[] | undefined>(() => {
    return data?.data?.map((quickLink) => ({
      key: quickLink.id,
      ...quickLink,
    }));
  }, [data]);

  const columns: ColumnsType<QuickLinksData> = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (text) => <Text>{text}</Text>,
    },
    {
      title: "URL",
      dataIndex: "url",
      key: "url",
      render: (text) => <Text>{text}</Text>,
    },
    {
      key: "edit",
      width: 70,
      render: (text, record) => (
        <EditQuickLinks
          quickLinksEditData={record}
          refetchQuickLinks={refetchQuickLinks}
        />
      ),
    },
    {
      key: "delete",
      width: 70,
      render: (text, record) => (
        <DeleteQuickLinks
          quickLinkId={record.id}
          refetchQuickLinks={refetchQuickLinks}
        />
      ),
    },
  ];

  return (
    <div className="quicklinks">
      <Spin
        size="large"
        tip="Loading..."
        spinning={isLoading}
        indicator={<LoadingOutlined />}
      >
        <Table
          columns={columns}
          dataSource={quickLinks}
          className="quicklinks_table"
          title={() => (
            <Row align="middle" justify="end">
              <Col>
                <AddQuickLinks refetchQuickLinks={refetchQuickLinks} />
              </Col>
            </Row>
          )}
          pagination={{
            hideOnSinglePage: true,
            position: ["bottomCenter"],
            prevIcon: (
              <Button className="prev">
                <LeftOutlined />
                Prev
              </Button>
            ),
            nextIcon: (
              <Button className="next">
                Next <RightOutlined />
              </Button>
            ),
          }}
        />
      </Spin>
    </div>
  );
};

type DeleteQuickLinksProps = {
  quickLinkId: number;
  refetchQuickLinks: () => void;
};

const DeleteQuickLinks: FC<DeleteQuickLinksProps> = ({
  quickLinkId,
  refetchQuickLinks,
}) => {
  const [deleteQuickLinks, { isLoading: deleteLoading }] =
    useDeleteQuickLinkMutation();
  const onDelete = () => {
    deleteQuickLinks(quickLinkId)
      .unwrap()
      .then((res) => {
        if (res?.success) {
          refetchQuickLinks();
        }
      });
  };

  return (
    <Button
      size="large"
      type="default"
      danger
      onClick={onDelete}
      loading={deleteLoading}
      icon={<DeleteOutlined />}
    >
      Delete
    </Button>
  );
};

type EditQuickLinksProps = {
  quickLinksEditData: any;
  refetchQuickLinks: () => void;
};

const EditQuickLinks: FC<EditQuickLinksProps> = ({
  quickLinksEditData,
  refetchQuickLinks,
}) => {
  const [isQuickLinksDetailModalOpen, setIsQuickLinksDetailModalOpen] =
    useState<boolean>(false);

  const moreDetailsButtonClick = () => {
    setIsQuickLinksDetailModalOpen(true);
  };

  const closeQuickLinksDetailModal = () => {
    setIsQuickLinksDetailModalOpen(false);
  };

  return (
    <Row align="middle" justify="center">
      <Col className="text-center">
        <Button
          size="large"
          onClick={moreDetailsButtonClick}
          icon={<EditOutlined />}
        >
          Edit
        </Button>
      </Col>
      <Modal
        open={isQuickLinksDetailModalOpen}
        onCancel={closeQuickLinksDetailModal}
        footer={null}
        centered
        destroyOnClose={true}
      >
        <QuickLinksDetails
          refetchQuickLinks={refetchQuickLinks}
          quickLinksEditData={quickLinksEditData}
          closeQuickLinksDetailModal={closeQuickLinksDetailModal}
        />
      </Modal>
    </Row>
  );
};

type AddQuickLinksProps = {
  refetchQuickLinks: () => void;
};

const AddQuickLinks: FC<AddQuickLinksProps> = ({ refetchQuickLinks }) => {
  const [isQuickLinksDetailModalOpen, setIsQuickLinksDetailModalOpen] =
    useState<boolean>(false);

  const addQuickLinkButtonClick = () => {
    setIsQuickLinksDetailModalOpen(true);
  };

  const closeQuickLinksDetailModal = () => {
    setIsQuickLinksDetailModalOpen(false);
  };
  return (
    <Row align="middle" justify="center">
      <Col className="text-center">
        <Button
          size="large"
          onClick={addQuickLinkButtonClick}
          icon={<PlusOutlined />}
        >
          Add Quick Link
        </Button>
      </Col>
      <Modal
        open={isQuickLinksDetailModalOpen}
        onCancel={closeQuickLinksDetailModal}
        footer={null}
        centered
        destroyOnClose={true}
      >
        <QuickLinksDetails
          closeQuickLinksDetailModal={closeQuickLinksDetailModal}
          refetchQuickLinks={refetchQuickLinks}
        />
      </Modal>
    </Row>
  );
};

export default QuickLinks;
