import { Table, Button, Typography, Modal, Row, Col, message } from "antd";
import "./UserGroup.style.scss";
import {
  DeleteOutlined,
  EditOutlined,
  LeftOutlined,
  LoadingOutlined,
  PlusOutlined,
  RightOutlined,
} from "@ant-design/icons";
import { ColumnsType } from "antd/es/table";
import { FC, useEffect, useState } from "react";
import UserGroupDetails from "./details/UserGroupDetails";
import { useDeleteGroupMutation, useGetGroupsQuery } from "../../../services/groups";
import useResponsive from "../../../hooks/useResponsive";
import { useDebounced } from "../../../hooks/useDebounced";

const { Text } = Typography;

const UserGroup = () => {
  const { data, isLoading, refetch } = useGetGroupsQuery(undefined);
  const { isMobile } = useResponsive()
  const [isMobileLoading, setMobileLoading] = useState(false);
  const [pageSize, setPageSize] = useState<number>(10);

  const refetchUserGroups = (): void => {
    refetch();
  };

  const handleScroll = useDebounced(200, () => {
    const distanceFromBottom =
      window.innerHeight + window.scrollY - document.body.scrollHeight;

    if (distanceFromBottom > -100 && !isMobileLoading) {
      setMobileLoading(true);
      setPageSize((prevPageSize) => prevPageSize + 10);
    }
  });

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
      setMobileLoading(false);
    };
  }, [handleScroll, isMobileLoading]);

  const columns: ColumnsType<any> = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (text) => <Text>{text}</Text>,
    },
    {
      key: "edit",
      width: 70,
      render: (text, record) => (
        <EditUserGroup
          userGroupEditData={record}
          refetchUserGroups={refetchUserGroups}
        />
      ),
    },
    {
      key: "delete",
      width: 70,
      render: (text, record) => (
        <DeleteUserGroup
          userGroupId={record.id}
          refetchUserGroups={refetchUserGroups}
        />
      ),
    },
  ];

  return (
    <div className="user_group">
      <Table
        columns={columns}
        dataSource={data?.data}
        className="user_group_table"
        scroll={{ x: 200 }}
        title={() => (
          <Row align="middle" justify="end">
            <Col>
              <AddUserGroup refetchUserGroups={refetchUserGroups} />
            </Col>
          </Row>
        )}
        pagination={isMobile
          ? {
            pageSize: pageSize,
          }
          : {
            hideOnSinglePage: true,
            position: ["bottomCenter"],
            prevIcon: (
              <Button className="prev">
                <LeftOutlined />
                Prev
              </Button>
            ),
            nextIcon: (
              <Button className="next">
                Next <RightOutlined />
              </Button>
            ),
          }}
        loading={{
          size: "large",
          tip: "Loading...",
          spinning: isLoading || isMobileLoading,
          indicator: <LoadingOutlined />,
        }}
      />
    </div>
  );
};

type EditUserGroupProps = {
  userGroupEditData: string;
  refetchUserGroups: () => void;
};

const EditUserGroup: FC<EditUserGroupProps> = ({
  userGroupEditData,
  refetchUserGroups,
}) => {
  const [isUserGroupDetailModalOpen, setIsUserGroupModalOpen] =
    useState<boolean>(false);

  const editButtonClick = () => {
    setIsUserGroupModalOpen(true);
  };

  const closeUserGroupDetailModal = () => {
    setIsUserGroupModalOpen(false);
  };

  return (
    <Row align="middle" justify="center">
      <Col className="text-center">
        <Button
          size="large"
          onClick={editButtonClick}
          icon={<EditOutlined />}
        >
          Edit
        </Button>
      </Col>
      <Modal
        open={isUserGroupDetailModalOpen}
        onCancel={closeUserGroupDetailModal}
        footer={null}
        centered
        destroyOnClose={true}
      >
        <UserGroupDetails
          refetchUserGroups={refetchUserGroups}
          userGroupEditData={userGroupEditData}
          closeUserGroupDetailModal={closeUserGroupDetailModal}
        />
      </Modal>
    </Row>
  );
};

type DeleteUserGroupProps = {
  userGroupId: number;
  refetchUserGroups: () => void;
};

const DeleteUserGroup: FC<DeleteUserGroupProps> = ({
  userGroupId,
  refetchUserGroups,
}) => {
  const [deleteUserGroup, { isLoading: deleteLoading }] =
    useDeleteGroupMutation();
  const onDelete = () => {
    deleteUserGroup(userGroupId)
      .unwrap()
      .then((res) => {
        if (res?.success) {
          refetchUserGroups();
          message.success("User Group deleted successfully");
        } else {
          message.error(res?.message);
        }
      })
      .catch((error) => {
        message.error(error.data.message);
      });
  };

  return (
    <Button
      size="large"
      type="default"
      danger
      onClick={onDelete}
      loading={deleteLoading}
      icon={<DeleteOutlined />}
    >
      Delete
    </Button>
  );
};

type AddUserGroupProps = {
  refetchUserGroups: () => void;
};

const AddUserGroup: FC<AddUserGroupProps> = ({ refetchUserGroups }) => {
  const [isUserGroupDetailModalOpen, setIsUserGroupDetailModalOpen] =
    useState<boolean>(false);

  const addButtonClick = () => {
    setIsUserGroupDetailModalOpen(true);
  };

  const closeUserGroupDetailModal = () => {
    setIsUserGroupDetailModalOpen(false);
  };
  return (
    <Row align="middle" justify="center">
      <Col className="text-center">
        <Button
          size="large"
          onClick={addButtonClick}
          icon={<PlusOutlined />}
        >
          Add User Group
        </Button>
      </Col>
      <Modal
        open={isUserGroupDetailModalOpen}
        onCancel={closeUserGroupDetailModal}
        footer={null}
        centered
        destroyOnClose={true}
      >
        <UserGroupDetails
          closeUserGroupDetailModal={closeUserGroupDetailModal}
          refetchUserGroups={refetchUserGroups}
        />
      </Modal>
    </Row>
  );
};
export default UserGroup;
