import { PageRoutesConstant } from "../../router/components/PageRoutes";

export const AdminDashboardConstants = {
  Title: "Admin Dashboard",
  MenuList: [
    {
      title: "Specialties",
      path: `${PageRoutesConstant.Page.Admin.path}/specialties`,
    },
    {
      title: "User Departments",
      path: `${PageRoutesConstant.Page.Admin.path}/user-departments`,
    },
    {
      title: "User Groups",
      path: `${PageRoutesConstant.Page.Admin.path}/user-groups`,
    },
    {
      title: "Quick Links",
      path: `${PageRoutesConstant.Page.Admin.path}/quick-links`,
    },
  ],
};
