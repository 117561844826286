export const getInitials = (name: string | undefined): string => {
  if (!name) {
    return "";
  }

  return name
    .split(" ")
    .filter((part) => part.length > 0)
    .map((part) => part[0])
    .join("");
};

type BaseFormData = {
  additionalData?: Record<string, unknown>;
};

export const mergeFormsAndNestAdditionalData = <
  T1 extends BaseFormData,
  T2 extends BaseFormData,
  T3 extends BaseFormData
>(
  a: T1,
  userSchemaExtensionId: string,
  b?: T2,
  c?: T3
) => ({
  ...a,
  ...(b || {}),
  ...(c || {}),
  additionalData: {
    [userSchemaExtensionId]: {
      ...a.additionalData,
      ...(b ? b.additionalData : {}),
      ...(c ? c.additionalData : {}),
    },
  },
});

export const parseOnlyBase64ImageData = (
  image: string | undefined
): string | undefined => {
  return image?.replace(/^data:image\/[a-z]+;base64,/, "");
};

export const handleImageUpload = (file: Blob): Promise<string> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = () => {
      if (typeof reader.result !== "string") {
        reject("result must be a string");
      } else {
        resolve(reader.result as string);
      }
    };

    reader.onerror = (error) => {
      reject(error);
    };

    reader.readAsDataURL(file);
  });
}