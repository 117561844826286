import { SelectProps } from "antd";
import { Rule } from "antd/es/form";

export type FormInputField = {
  name: string | string[];
  placeholder: string;
  mask?: string;
  rules?: Rule[];
  options?: SelectProps["options"] | undefined;
  disabled?: boolean;
};

export function getInitialValue<
  T extends Record<string, undefined | string | unknown>
>(initialValue: T, field: FormInputField) {
  if (typeof field.name === "string") {
    return initialValue[field.name];
  } else if (Array.isArray(field.name)) {
    let current = initialValue;
    for (const k of field.name) {
      if (!current) return undefined;
      current = current[k] as T;
    }
    return current;
  } else {
    return undefined;
  }
}

export const getKey = (field: FormInputField): string => {
  if (typeof field.name === "string") {
    return field.name;
  } else {
    return field.name.join(".");
  }
};
