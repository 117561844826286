import Icon from "@ant-design/icons";
import { CustomIconComponentProps } from "@ant-design/icons/lib/components/Icon";

const FaxSvg = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    id="fax"
    viewBox="0 0 512 512"
    width="35"
    height="35"
  >
    <path d="M458.36,414.59H401.6992a10,10,0,0,1,0-20H458.36a18.93,18.93,0,0,0,18.9082-18.9082V202.23A18.93,18.93,0,0,0,458.36,183.3218H53.64A18.93,18.93,0,0,0,34.7314,202.23V375.6816A18.93,18.93,0,0,0,53.64,394.59h56.6607a10,10,0,1,1,0,20H53.64a38.9526,38.9526,0,0,1-38.9087-38.9082V202.23A38.9526,38.9526,0,0,1,53.64,163.3218H458.36A38.9522,38.9522,0,0,1,497.2686,202.23V375.6816A38.9522,38.9522,0,0,1,458.36,414.59Z" />
    <path d="M110.3008,183.3218a10,10,0,0,1-10-10V88.9087A38.9527,38.9527,0,0,1,139.21,50H338.1006a10,10,0,0,1,0,20H139.21a18.93,18.93,0,0,0-18.9087,18.9087v84.4131A10,10,0,0,1,110.3008,183.3218Z" />
    <path d="M401.6992,183.3218a10,10,0,0,1-10-10V123.5986a10,10,0,0,1,20,0v49.7232A10,10,0,0,1,401.6992,183.3218Z" />
    <path d="M372.791,462H139.21a38.9523,38.9523,0,0,1-38.9087-38.9082V312.083a10,10,0,0,1,20,0V423.0918A18.93,18.93,0,0,0,139.21,442H372.791a18.93,18.93,0,0,0,18.9082-18.9082V312.083a10,10,0,0,1,20,0V423.0918A38.9522,38.9522,0,0,1,372.791,462Z" />
    <path d="M401.6992,133.5986H361.2275a33.1638,33.1638,0,0,1-33.1269-33.1264V60a10,10,0,0,1,20,0v40.4722a13.1416,13.1416,0,0,0,13.1269,13.1264h40.4717a10,10,0,0,1,0,20Z" />
    <path d="M401.6992,133.5986a9.969,9.969,0,0,1-7.0713-2.9287L331.0293,67.0713a10,10,0,0,1,14.1426-14.1426l63.5986,63.5986a10,10,0,0,1-7.0713,17.0713Z" />
    <path d="M428.2949,322.083H83.7051a10,10,0,0,1,0-20h344.59a10,10,0,0,1,0,20Z" />
    <path d="M352.1758,370.07H159.8242a10,10,0,0,1,0-20H352.1758a10,10,0,0,1,0,20Z" />
    <path d="M352.1758,414.0117H159.8242a10,10,0,0,1,0-20H352.1758a10,10,0,0,1,0,20Z" />
    <path d="M422.5137,266.5781a28.5015,28.5015,0,1,1,28.5009-28.5014A28.5341,28.5341,0,0,1,422.5137,266.5781Zm0-37.0029a8.5015,8.5015,0,1,0,8.5009,8.5015A8.5112,8.5112,0,0,0,422.5137,229.5752Z" />
  </svg>
);

export const FaxIcon = (props: Partial<CustomIconComponentProps>) => (
  <Icon component={FaxSvg} {...props} />
);
