import {
  DeleteOutlined,
  EditOutlined,
  LeftOutlined,
  LoadingOutlined,
  PlusOutlined,
  RightOutlined,
} from "@ant-design/icons";
import { Button, Col, Modal, Row, Spin, Table, Typography } from "antd";
import type { ColumnsType } from "antd/es/table";
import { FC, useMemo, useState } from "react";
import {
  useDeleteSpecialitiesMutation,
  useGetSpecialitiesQuery,
} from "../../../services/specialities";
import { SpecialityData } from "../../../common/interfaces/SpecialityData";
import "./Speciality.style.scss";
import SpecialityDetails from "./details/SpecialityDetails";

const { Text } = Typography;

const Speciality: FC = () => {
  const { data, refetch, isLoading } = useGetSpecialitiesQuery(undefined);
  const refetchSpecialitites = (): void => {
    refetch();
  };

  const specialitites = useMemo<SpecialityData[] | undefined>(() => {
    return data?.data?.map((specialities) => ({
      key: specialities.id,
      ...specialities,
    }));
  }, [data]);

  const columns: ColumnsType<SpecialityData> = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (text) => <Text>{text}</Text>,
    },
    {
      key: "edit",
      width: 70,
      render: (text, record) => (
        <EditSpeaciality
          specialityEditData={record}
          refetchSpecialitites={refetchSpecialitites}
        />
      ),
    },
    {
      key: "delete",
      width: 70,
      render: (text, record) => (
        <DeleteSpecialities
          specialityId={record.id}
          refetchSpecialitites={refetchSpecialitites}
        />
      ),
    },
  ];

  return (
    <div className="speciality">
      <Spin
        size="large"
        tip="Loading..."
        spinning={isLoading}
        indicator={<LoadingOutlined />}
      >
        <Table
          columns={columns}
          dataSource={specialitites}
          className="speciality_table"
          title={() => (
            <Row align="middle" justify="end">
              <Col>
                <AddSpeaciality refetchSpecialitites={refetchSpecialitites} />
              </Col>
            </Row>
          )}
          pagination={{
            hideOnSinglePage: true,
            position: ["bottomCenter"],
            prevIcon: (
              <Button className="prev">
                <LeftOutlined />
                Prev
              </Button>
            ),
            nextIcon: (
              <Button className="next">
                Next <RightOutlined />
              </Button>
            ),
          }}
        />
      </Spin>
    </div>
  );
};

type DeleteSpecialitiesProps = {
  specialityId: number;
  refetchSpecialitites: () => void;
};

const DeleteSpecialities: FC<DeleteSpecialitiesProps> = ({
  specialityId,
  refetchSpecialitites,
}) => {
  const [DeleteSpecialities, { isLoading: deleteLoading }] =
    useDeleteSpecialitiesMutation();
  const onDelete = () => {
    DeleteSpecialities(specialityId)
      .unwrap()
      .then((res) => {
        if (res?.success) {
          refetchSpecialitites();
        }
      });
  };

  return (
    <Button
      size="large"
      type="default"
      danger
      onClick={onDelete}
      loading={deleteLoading}
      icon={<DeleteOutlined />}
    >
      Delete
    </Button>
  );
};

type EditSpeacialityProps = {
  specialityEditData: any;
  refetchSpecialitites: () => void;
};

const EditSpeaciality: FC<EditSpeacialityProps> = ({
  specialityEditData,
  refetchSpecialitites,
}) => {
  const [isSpecialityDetailModalOpen, setIsSpecialityDetailModalOpen] =
    useState<boolean>(false);

  const moreDetailsButtonClick = () => {
    setIsSpecialityDetailModalOpen(true);
  };

  const closeSpecialityDetailModal = () => {
    setIsSpecialityDetailModalOpen(false);
  };

  return (
    <Row align="middle" justify="center">
      <Col className="text-center">
        <Button
          size="large"
          onClick={moreDetailsButtonClick}
          icon={<EditOutlined />}
        >
          Edit
        </Button>
      </Col>
      <Modal
        open={isSpecialityDetailModalOpen}
        onCancel={closeSpecialityDetailModal}
        footer={null}
        centered
        destroyOnClose={true}
      >
        <SpecialityDetails
          refetchSpecialitites={refetchSpecialitites}
          specialityEditData={specialityEditData}
          closeSpecialityDetailModal={closeSpecialityDetailModal}
        />
      </Modal>
    </Row>
  );
};

type AddSpeacialityProps = {
  refetchSpecialitites: () => void;
};

const AddSpeaciality: FC<AddSpeacialityProps> = ({ refetchSpecialitites }) => {
  const [isSpecialityDetailModalOpen, setIsSpecialityDetailModalOpen] =
    useState<boolean>(false);

  const moreDetailsButtonClick = () => {
    setIsSpecialityDetailModalOpen(true);
  };

  const closeSpecialityDetailModal = () => {
    setIsSpecialityDetailModalOpen(false);
  };
  return (
    <Row align="middle" justify="center">
      <Col className="text-center">
        <Button
          size="large"
          onClick={moreDetailsButtonClick}
          icon={<PlusOutlined />}
        >
          Add Specialty
        </Button>
      </Col>
      <Modal
        open={isSpecialityDetailModalOpen}
        onCancel={closeSpecialityDetailModal}
        footer={null}
        centered
        destroyOnClose={true}
      >
        <SpecialityDetails
          closeSpecialityDetailModal={closeSpecialityDetailModal}
          refetchSpecialitites={refetchSpecialitites}
        />
      </Modal>
    </Row>
  );
};

export default Speciality;
