import {
  ComponentType,
  FC,
  ReactNode,
  Reducer,
  useCallback,
  useEffect,
  useMemo,
  useReducer,
  useRef,
  useState,
} from "react";
import {
  Avatar,
  Button,
  Col,
  Form,
  Input,
  Modal,
  Row,
  Select,
  Tabs,
  Tag,
  Typography,
  Upload,
  Popover,
  message,
  Switch,
  SelectProps,
  Checkbox,
} from "antd";
import {
  ClinicIcon,
  EmailIcon,
  LanguageIcon,
  LocationIcon,
  TelephoneIcon,
  UserIcon,
  PadLockIcon,
  OutBoxIcon,
  RolesIcon,
  IdCardIcon,
} from "../../../assets/images/icons";
import ModifyGroupAndRole from "./groupandrole/ModifyGroupAndRole";
import { AuthMethod, SignInType } from "../../../common/enums";
import {
  FormatAddress,
  FormatDisplayDateTime,
  RemoveSpaceFromString,
} from "../../../common/utilities/format";
import { RcFile } from "antd/es/upload";
import {
  CheckCircleFilled,
  CloseCircleFilled,
  CopyOutlined,
  DownOutlined,
  LoadingOutlined,
} from "@ant-design/icons";
import { useGetDomainsQuery } from "../../../services/domains";
import { useGetClinicsNameQuery } from "../../../services/clinics";
import { MaskedInput } from "antd-mask-input";
import { UserType } from "../../../common/enums/UserType";
import {
  getInitials,
  handleImageUpload,
  mergeFormsAndNestAdditionalData,
  parseOnlyBase64ImageData,
} from "../../../common/utilities/helper";
import "./UserDetails.style.scss";
import {
  authPhonePatternMesssage,
  authPhonePatternRegex,
  namePatternMesssage,
  namePatternRegex,
  passwordPatternRegex,
  phonePatternRegex,
  spacePatternRegex,
} from "../../../common/utilities/validator";
import { FormInstance, Rule } from "antd/es/form";
import {
  useCheckEmailExistQuery,
  useCheckUserNameExistQuery,
  useCreateUserMutation,
  useDeleteUserMutation,
  useGetUserSchemaExtensionIdQuery,
  useUpdateUserAccountStatusMutation,
  useUpdateUserMutation,
} from "../../../services/users";
import { useGetUserRolesQuery } from "../../../services/roleGroups";
import {
  FormInputField,
  getInitialValue,
  getKey,
} from "../../../common/utilities/formUtils";
import { Categories } from "../../../common/constants/Categories";
import useRolesPermissionsAccess from "../../../hooks/useRolesPermissionsAccess";
import { UserData } from "../../../common/interfaces/UserData";
import { useGetUserDepartmentsQuery } from "../../../services/userDepartments";
import useResponsive from "../../../hooks/useResponsive";
import { randomPasswordGenerator } from "../../../common/utilities/passwordGenerator";
import copy from "copy-to-clipboard";
import { useGetCountryCodesQuery } from "../../../services/country";

const { Title, Paragraph, Text } = Typography;
const { TextArea } = Input;

type UserDetailsFormData = Partial<{
  primaryGroupName: string | string[];
  photo: string;
  additionalData: Partial<{
    SecondaryGroupName: string;
    SpokenLanguage: string;
    Certifications: string;
    Location: string;
  }>;
  identity: Partial<{
    signInType: SignInType;
    issuerAssignedId: string;
    issuer: string;
  }>;
  authPhoneCountryCode?: string;
  authPhone?: string;
}>;

interface UserDetailsProps {
  userData?: UserData;
  closeUserDetailModal: () => void;
  isCreate?: boolean;
  refreshUserList: () => void;
}

const ENTER_PERSONAL_INFO = "Enter Personal Information";
const ENTER_ADDRESS = "Enter Address";
const SEE_DETAILS = "See Details";

const RESET = "Reset";
const CLEAR = "Clear";

const UserDetails: FC<UserDetailsProps> = ({
  userData,
  closeUserDetailModal,
  isCreate = false,
  refreshUserList,
}) => {
  const [isGroupRoleModalOpen, setIsGroupRoleModalOpen] =
    useState<boolean>(false);
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [personalInfo, setPersonalInfo] = useState<PersonalInfoForm>({
    firstName: userData?.firstName,
    lastName: userData?.lastName,
    additionalData: {
      MiddleName: userData?.middleName,
      Category: userData?.category,
    },
  });
  const [addressInfo, setAddressInfo] = useState<AddressFormData>({
    Address: userData?.address,
    City: userData?.city,
    postalCode: userData?.postalCode,
    State: userData?.state,
    Country: userData?.country,
    additionalData: {
      Location: userData?.location,
    },
  });

  const [newGroups, setNewGroups] = useState<string[]>([]);
  const [removedGroups, setRemovedGroups] = useState<string[]>([]);

  const initialGroups = useMemo(
    () => (userData?.groupIds ? userData?.groupIds.split(",") : []),
    [userData?.groupIds]
  );

  const newSelectedGroups = useCallback(() => {
    setNewGroups((prevSelectedGroup) => {
      return prevSelectedGroup.filter((val) => !initialGroups.includes(val));
    });
  }, [initialGroups]);

  const [userForm] = Form.useForm<UserDetailsFormData>();

  const [createUserApi, { isLoading: createUserLoading }] =
    useCreateUserMutation();
  const [updateUserApi, { isLoading: updateUserLoading }] =
    useUpdateUserMutation();

  const { data: userSchemaExtensionData } = useGetUserSchemaExtensionIdQuery(
    undefined,
    { skip: !isCreate && !isEdit }
  );
  const userSchemaExtensionId = useMemo(
    () => userSchemaExtensionData?.data || "",
    [userSchemaExtensionData]
  );
  const { data: clinics } = useGetClinicsNameQuery(undefined, {
    skip: !isCreate && !isEdit,
  });

  const showGroupRoleModal = () => {
    setIsGroupRoleModalOpen(true);
  };

  const handleGroupRoleCancel = () => {
    setIsGroupRoleModalOpen(false);
  };

  const [resetCounter, resetForms] = useReducer<Reducer<number, void>>(
    (n) => n + 1,
    0
  );

  const getGroupData = (
    selectedGroups: string[],
    removedGroups: string[]
  ): void => {
    setNewGroups(selectedGroups);
    setRemovedGroups(removedGroups);
    newSelectedGroups();
  };

  const createUser = () => {
    userForm
      .validateFields()
      .then((values) => {
        const merged = mergeFormsAndNestAdditionalData(
          values,
          userSchemaExtensionId,
          addressInfo,
          personalInfo
        );
        const updatedUserData = {
          ...merged,
          primaryGroupName: Array.isArray(values.primaryGroupName)
            ? values.primaryGroupName.join(", ")
            : values.primaryGroupName,
          identity: { ...values.identity, signInType: SignInType.EmailAddress },
          photo: parseOnlyBase64ImageData(values.photo),
          userType: UserType.Member,
          accountEnabled: true,
          authPhone:
            values.authPhoneCountryCode && values.authPhone
              ? `${values.authPhoneCountryCode} ${values.authPhone}`
              : null,
          newGroups,
        };

        createUserApi(updatedUserData)
          .unwrap()
          .then((res) => {
            if (res.success) {
              message.success("User created successfully");
              closeUserDetailModal();
              refreshUserList();
              resetForms();
            } else {
              message.error(res.message);
            }
          })
          .catch((error) =>
            message.error(error.data.message || error.data.title)
          );
      })
      .catch((info) => {
        console.error("User Create Validate Failed:", info);
      });
  };

  const updateUser = (): void => {
    userForm
      .validateFields()
      .then((values: any) => {
        const merged = mergeFormsAndNestAdditionalData(
          values,
          userSchemaExtensionId,
          addressInfo,
          personalInfo
        );
        const updatedUserData = {
          ...merged,
          primaryGroupName: Array.isArray(values.primaryGroupName)
            ? values.primaryGroupName.join(", ")
            : values.primaryGroupName,
          userId: userData?.key,
          photo: parseOnlyBase64ImageData(values.photo),
          newGroups,
          removedGroups,
        };

        updateUserApi(updatedUserData)
          .unwrap()
          .then((res) => {
            if (res.success) {
              message.success("User updated successfully");
              closeUserDetailModal();
              refreshUserList();
            } else {
              message.error(res.message);
            }
          })
          .catch((error) =>
            message.error(error.data.message || error.data.title)
          );
      })
      .catch((info) => {
        console.error("User Update Validate Failed:", info);
      });
  };

  const { data: userRoles } = useGetUserRolesQuery(userData?.groupIds!, {
    skip: !userData?.groupIds,
  });
  const userRoleNames = useMemo(
    () => userRoles?.data.map((d) => d.name) || [],
    [userRoles]
  );

  const clinicOptions = useMemo<SelectProps["options"]>(
    () =>
      clinics?.data.map((clinic) => ({
        label: clinic.name,
        value: clinic.name,
      })),
    [clinics]
  );

  useEffect(() => {
    userForm.setFieldValue("primaryGroupName", undefined);
  }, [personalInfo.additionalData?.Category, userForm]);

  const { isAdmin, hasDeleteUser } = useRolesPermissionsAccess();

  return (
    <>
      <Form form={userForm} onFinish={isCreate ? createUser : updateUser}>
        <Row className="user_details">
          <Col span={24}>
            <UserDetailsHeader
              key={resetCounter}
              userData={userData}
              isCreate={isCreate}
              setIsEdit={setIsEdit}
              isEdit={isEdit}
              updateAddressInfoFormData={setAddressInfo}
              initialAddressInfo={addressInfo}
              updatePersonalInfoFormData={setPersonalInfo}
              initialPersonalInfo={personalInfo}
              isLoadingDataNecessaryForSubmit={
                createUserLoading || updateUserLoading
              }
            />
          </Col>
          <Col span={24}>
            <Row>
              <UserDetailsRow
                first={true}
                name="primaryGroupName"
                label="Clinic"
                Icon={ClinicIcon}
                textContent={userData?.primaryGroupName}
                isCreate={isCreate}
                isEdit={isEdit}
                rules={[
                  {
                    required: true,
                    message: "Please select Clinic",
                  },
                ]}
                options={
                  personalInfo.additionalData?.Category ===
                  Categories.CoipaStaff
                    ? [{ label: "COIPA", value: "COIPA" }]
                    : clinicOptions
                }
                selectMode={
                  personalInfo.additionalData?.Category === Categories.MSP
                    ? "multiple"
                    : undefined
                }
              />
              <UserDetailsRow
                label="Email"
                Icon={EmailIcon}
                customContent={
                  <EmailField
                    name="email"
                    userData={userData}
                    isEdit={isEdit}
                    isCreate={isCreate}
                  />
                }
              />
              <UserDetailsRow
                label="Phone"
                name="phone"
                Icon={TelephoneIcon}
                textContent={userData?.phone}
                isCreate={isCreate}
                isEdit={isEdit}
                mask="(000)-000-0000"
                rules={[
                  {
                    pattern: phonePatternRegex,
                    message: "Enter valid phone",
                    validateTrigger: "onClick",
                  },
                ]}
              />
              <UserDetailsRow
                label="Spoken Language"
                name={["additionalData", "SpokenLanguage"]}
                Icon={LanguageIcon}
                textContent={userData?.spokenLanguage}
                isCreate={isCreate}
                isEdit={isEdit}
                last={isCreate || isEdit || !isAdmin}
              />
              {isAdmin && !isCreate && !isEdit && (
                <UserDetailsRow
                  label="COIPA ID"
                  Icon={IdCardIcon}
                  textContent={userData?.coipaId}
                  last={true}
                />
              )}
            </Row>
          </Col>
          {isAdmin && (
            <CommentRow
              label="Comment"
              name={["additionalData", "Comment"]}
              textContent={userData?.comment}
              isCreate={isCreate}
              isEdit={isEdit}
            />
          )}
          {isAdmin && (
            <Col span={24} className="user_details">
              <Row className="user_details_secure">
                <SectionTitle title="System Preferences" />
                <UserDetailsRow
                  label="Username"
                  Icon={UserIcon}
                  customContent={
                    <UserNameField
                      text={userData?.userName}
                      isCreate={isCreate}
                      personalInfo={personalInfo}
                      userForm={userForm}
                    />
                  }
                />
                <UserDetailsRow
                  label="Role"
                  Icon={RolesIcon}
                  textContent={userRoleNames.join(", ")}
                />
                {isCreate && (
                  <UserDetailsRow
                    label="Password"
                    Icon={PadLockIcon}
                    customContent={<PasswordField userForm={userForm} />}
                  />
                )}
              </Row>
            </Col>
          )}
        </Row>
        {isAdmin && (
          <Row className="user_details">
            <Col span={24}>
              <Row className="user_details_secure">
                <SectionTitle title="Authentication Method" />
                <Col>
                  <Row className="user_details_content" gutter={[0, 20]}>
                    <Col className="user_details_auth_method">
                      <Tabs
                        defaultActiveKey={AuthMethod.Email}
                        items={authMethodTabs(isEdit, isCreate, userData)}
                        className="user_details_auth_method_tabs"
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
        )}
      </Form>
      {isAdmin && (!isCreate || isEdit) && (
        <Row className="user_details">
          <Col span={24}>
            <DisableUser userData={userData} isEdit={isEdit} />
          </Col>
        </Row>
      )}
      {isAdmin && (
        <Row className="user_details">
          <Col span={24}>
            <Row className="user_details_secure">
              <SectionTitle title="Groups" />
              <Col>
                <Row className="user_details_content" gutter={[0, 20]}>
                  <Col>
                    <Button
                      type="primary"
                      size="large"
                      onClick={showGroupRoleModal}
                      disabled={!isCreate && !isEdit}
                    >
                      Add/Edit Groups
                    </Button>
                    <Modal
                      className="user_modal"
                      open={isGroupRoleModalOpen}
                      width={300}
                      centered
                      footer={null}
                    >
                      <ModifyGroupAndRole
                        closeModal={handleGroupRoleCancel}
                        groupIds={userData?.groupIds}
                        getGroupsData={getGroupData}
                      />
                    </Modal>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      )}
      {isEdit && hasDeleteUser && (
        <Row className="user_details">
          <Col span={24}>
            <UserDetailDeleteRow
              userId={userData?.key!}
              refreshUserList={refreshUserList}
            />
          </Col>
        </Row>
      )}
    </>
  );
};

type UserDetailsHeaderProps = {
  userData?: UserData;
  isCreate: boolean;
  updatePersonalInfoFormData: (data: PersonalInfoForm) => void;
  initialPersonalInfo: PersonalInfoForm;
  updateAddressInfoFormData: (data: AddressFormData) => void;
  initialAddressInfo: AddressFormData;
  setIsEdit: (val: boolean) => void;
  isEdit: boolean;
  isLoadingDataNecessaryForSubmit: boolean;
};

const UserDetailsHeader: FC<UserDetailsHeaderProps> = ({
  userData,
  isCreate,
  updatePersonalInfoFormData,
  initialPersonalInfo,
  updateAddressInfoFormData,
  initialAddressInfo,
  setIsEdit,
  isEdit,
  isLoadingDataNecessaryForSubmit,
}: UserDetailsHeaderProps) => {
  const { isMobile } = useResponsive();
  const address = FormatAddress({
    address: userData?.address,
    city: userData?.city,
    state: userData?.state,
    postalCode: userData?.postalCode,
    country: userData?.country,
  });

  const handleSwitchChange = (value: boolean) => {
    setIsEdit(value);
  };

  const onDiscardChanges = () => {
    setIsEdit(false);
  };

  const { isAdmin, isCoipaFinance, hasEditUser } = useRolesPermissionsAccess();

  const { data: UserDepartments } = useGetUserDepartmentsQuery(undefined, {
    skip: !isCreate && !isEdit,
  });

  const userDepartmentsOptions = useMemo<SelectProps["options"]>(
    () =>
      UserDepartments?.data.map((userDepartment) => ({
        label: userDepartment.name,
        value: userDepartment.name,
      })),
    [UserDepartments]
  );

  return (
    <Row
      className="user_details_header"
      gutter={[18, { xs: 10, sm: 10 }]}
      align="middle"
      justify="center"
    >
      <Col xs={24} md={6} className="user_details_header_image_wrapper">
        {isCreate || isEdit ? (
          <Row justify="center">
            <Col xs={14} sm={8} md={24}>
              <Form.Item
                name="photo"
                initialValue={isEdit ? userData?.imageUrl : undefined}
              >
                <PictureInput />
              </Form.Item>
            </Col>
            <Col xs={24} md={24}>
              <Form.Item
                name="jobTitle"
                initialValue={isEdit ? userData?.jobTitle : undefined}
                rules={[
                  {
                    required: true,
                    message: `Please enter Job Title`,
                  },
                ]}
              >
                <Input size="large" placeholder="Job Title" />
              </Form.Item>
            </Col>
          </Row>
        ) : (
          <Row justify="center">
            <Col xs={14} sm={8} md={24} className="text-center">
              <Avatar
                shape="square"
                size="large"
                src={userData?.imageUrl}
                className="user_details_header_image_wrapper_image"
              >
                {getInitials(userData?.name)}
              </Avatar>
              {userData?.jobTitle && (
                <Tag
                  className="user_details_header_image_wrapper_tag me-0 text-truncate"
                  title={userData?.jobTitle}
                >
                  {userData?.jobTitle}
                </Tag>
              )}
            </Col>
          </Row>
        )}
      </Col>
      <Col xs={24} md={18} className="user_details_header_personal_info">
        <Row gutter={[10, 12]} align="middle">
          <Col xs={24} sm={24} md={16}>
            {isCreate || isEdit ? (
              <Form.Item
                name="primarySpeciality"
                initialValue={isEdit ? userData?.primarySpeciality : undefined}
              >
                <Select
                  size="large"
                  placeholder="Department"
                  options={userDepartmentsOptions}
                  allowClear
                />
              </Form.Item>
            ) : (
              <Title
                level={4}
                className="mb-0 user_details_header_personal_info_speciality"
              >
                {userData?.primarySpeciality}
              </Title>
            )}

            {isCreate || isEdit ? (
              <div className="mb-2 mt-2 ">
                <PersonalInfoPopover
                  updateFormData={updatePersonalInfoFormData}
                  initialData={initialPersonalInfo}
                  isEdit={isEdit}
                />
              </div>
            ) : (
              <Title
                level={isMobile ? 3 : 1}
                className="mb-2 mt-2 user_details_header_personal_info_name"
              >
                {userData?.name}
              </Title>
            )}
            {isCreate || isEdit ? (
              <AddressPopover
                updateFormData={updateAddressInfoFormData}
                initialData={initialAddressInfo}
                isEdit={isEdit}
              />
            ) : (
              address && (
                <Button
                  type="link"
                  className="d-flex align-items-center mb-2 gap-1 px-0"
                  onClick={() => {
                    window.open(
                      `https://www.google.com/maps/place/${address}`,
                      "_blank"
                    );
                  }}
                >
                  <LocationIcon className="user_details_header_personal_info_location_icon" />
                  <Paragraph className="mb-0 user_details_header_personal_info_address">
                    {address}
                  </Paragraph>
                </Button>
              )
            )}
          </Col>
          {(isAdmin || isCoipaFinance) && hasEditUser && (
            <Col xs={24} sm={24} md={7} className="user_details_header_buttons">
              <Row align="middle" gutter={[10, 12]}>
                {isCreate ? (
                  <Col xs={24} className="user_details_header_buttons_edit">
                    <Button
                      size="large"
                      type="primary"
                      htmlType="submit"
                      loading={isLoadingDataNecessaryForSubmit}
                      className="w-100"
                    >
                      Create User
                    </Button>
                  </Col>
                ) : (
                  <Col span={24}>
                    <Row
                      gutter={[{ sm: 10 }, 12]}
                      align="middle"
                      justify="center"
                      className="text-center"
                    >
                      <Col span={24}>
                        <Paragraph className="mb-0">
                          Edit Mode
                          <Switch
                            checked={isEdit}
                            className="ms-2"
                            onChange={handleSwitchChange}
                          />
                        </Paragraph>
                      </Col>
                      <Col xs={24} sm={12} md={24}>
                        <Button
                          size="large"
                          disabled={!isEdit}
                          loading={isLoadingDataNecessaryForSubmit}
                          type="primary"
                          className="w-100"
                          htmlType="submit"
                        >
                          Save Changes
                        </Button>
                      </Col>
                      <Col xs={24} sm={12} md={24}>
                        <Button
                          size="large"
                          disabled={!isEdit}
                          type="default"
                          htmlType="reset"
                          onClick={onDiscardChanges}
                          className="w-100"
                        >
                          Discard Changes
                        </Button>
                      </Col>
                    </Row>
                  </Col>
                )}
              </Row>
            </Col>
          )}
        </Row>
      </Col>
    </Row>
  );
};

type AddressFormData = Partial<{
  Address: string;
  City: string;
  postalCode: string;
  State: string;
  Country: string;
  additionalData: Partial<{
    Location: string;
  }>;
}>;

type AddressPopoverProps = {
  updateFormData: (data: AddressFormData) => void;
  isEdit?: boolean;
  initialData: AddressFormData;
};

const AddressPopover: FC<AddressPopoverProps> = ({
  updateFormData,
  isEdit,
  initialData,
}) => {
  const [addressForm] = Form.useForm<AddressFormData>();
  const [popoverVisible, setPopoverVisible] = useState<boolean>(false);
  const [addressInfoLabel, setPersonalAddressInfoLabel] = useState<string>(
    isEdit ? SEE_DETAILS : ENTER_ADDRESS
  );

  const inputFields = useMemo<FormInputField[]>(
    () => [
      {
        name: "Address",
        placeholder: "Street address",
      },
      {
        name: "City",
        placeholder: "City",
      },
      {
        name: "postalCode",
        placeholder: "ZIP or Postal code",
      },
      {
        name: "State",
        placeholder: "State or Province",
      },
      {
        name: "Country",
        placeholder: "Country or Region",
      },
      {
        name: ["additionalData", "Location"],
        placeholder: "Location (Google maps link)",
      },
    ],
    []
  );

  const handleVisibleChange = (visible: boolean) => {
    setPopoverVisible(visible);
  };

  const handleClear = () => {
    setPopoverVisible(false);
    addressForm.resetFields();
    setPersonalAddressInfoLabel(isEdit ? SEE_DETAILS : ENTER_ADDRESS);
  };

  const handleApply = () => {
    addressForm?.validateFields().then((values) => {
      updateFormData(values);
      setPopoverVisible(false);
      setPersonalAddressInfoLabel(SEE_DETAILS);
    });
  };

  return (
    <div className="d-flex">
      <LocationIcon className="user_details_header_personal_info_location_icon me-2" />
      <Popover
        trigger="click"
        content={
          <Form form={addressForm}>
            <Row gutter={[0, 12]}>
              {inputFields.map((field) => (
                <Col key={getKey(field)} span={24}>
                  <Title className="mb-0" level={5}>
                    {field.placeholder}
                  </Title>
                  <Form.Item
                    name={field.name}
                    initialValue={
                      isEdit ? getInitialValue(initialData, field) : undefined
                    }
                    rules={[
                      {
                        required: true,
                        message: `Please enter ${field.placeholder}`,
                      },
                    ]}
                  >
                    <Input size="large" placeholder={field.placeholder} />
                  </Form.Item>
                </Col>
              ))}
              <Col className="text-center" span={24}>
                <Button
                  size="large"
                  type="primary"
                  onClick={handleApply}
                  className="me-2"
                >
                  Apply
                </Button>
                <Button size="large" type="default" onClick={handleClear}>
                  {isEdit ? "Reset " : "Clear"}
                </Button>
              </Col>
            </Row>
          </Form>
        }
        overlayClassName="user_details_header_personal_info_popover"
        open={popoverVisible}
        arrow={false}
        onOpenChange={handleVisibleChange}
      >
        <Button
          size="large"
          type="text"
          className="user_details_header_personal_info_enter_address_btn"
          onClick={() => setPopoverVisible(!popoverVisible)}
        >
          {addressInfoLabel}
          <DownOutlined className="ms-auto ms-md-3" />
        </Button>
      </Popover>
    </div>
  );
};

type PersonalInfoForm = Partial<{
  firstName: string;
  lastName: string;
  additionalData: Partial<{
    Category: string;
    MiddleName: string;
  }>;
}>;

type PersonalInfoPopoverProps = {
  updateFormData: (data: PersonalInfoForm) => void;
  isEdit?: boolean;
  initialData: PersonalInfoForm;
};

const PersonalInfoPopover: FC<PersonalInfoPopoverProps> = ({
  updateFormData,
  isEdit,
  initialData,
}) => {
  const [personalInfoForm] = Form.useForm<PersonalInfoForm>();
  const [personalInfoLabel, setPersonalInfoLabel] = useState<string>(
    isEdit ? SEE_DETAILS : ENTER_PERSONAL_INFO
  );

  const inputFields = useMemo<FormInputField[]>(
    () => [
      {
        name: "firstName",
        placeholder: "First Name",
        rules: [
          {
            required: true,
            message: "Please enter Firstname",
          },
          {
            pattern: namePatternRegex,
            message: namePatternMesssage,
          },
        ],
      },
      {
        name: ["additionalData", "MiddleName"],
        placeholder: "Middle Name",
        rules: [
          {
            pattern: namePatternRegex,
            message: namePatternMesssage,
          },
        ],
      },
      {
        name: "lastName",
        placeholder: "Last Name",
        rules: [
          {
            required: true,
            message: "Please enter Lastname",
          },
          {
            pattern: namePatternRegex,
            message: namePatternMesssage,
          },
        ],
      },
      {
        name: ["additionalData", "Category"],
        placeholder: "Category",
        options: [
          { label: Categories.CoipaStaff, value: Categories.CoipaStaff },
          { label: Categories.ClinicStaff, value: Categories.ClinicStaff },
          { label: Categories.MSP, value: Categories.MSP },
        ],
        rules: [
          {
            required: true,
            message: "Please select Category",
          },
        ],
      },
    ],
    []
  );

  const [popoverVisible, setPopoverVisible] = useState<boolean>(false);

  const handleVisibleChange = (visible: boolean) => {
    setPopoverVisible(visible);
  };

  const handleClear = () => {
    updateFormData({});
    setPopoverVisible(false);
    personalInfoForm.resetFields();
    setPersonalInfoLabel(isEdit ? SEE_DETAILS : ENTER_PERSONAL_INFO);
  };

  const handleApply = () => {
    personalInfoForm.validateFields().then((values) => {
      updateFormData?.(values);
      setPopoverVisible(false);
      setPersonalInfoLabel(SEE_DETAILS);
    });
  };

  return (
    <div className="d-flex">
      <Popover
        trigger="click"
        content={
          <Form form={personalInfoForm}>
            <Row gutter={[0, 12]}>
              {inputFields.map((field) => (
                <Col key={getKey(field)} span={24}>
                  <Title className="mb-0" level={5}>
                    {field.placeholder}
                  </Title>
                  <Form.Item
                    name={field.name}
                    initialValue={
                      isEdit ? getInitialValue(initialData, field) : undefined
                    }
                    rules={field.rules}
                  >
                    {field.mask ? (
                      <MaskedInput size="large" mask={field.mask} />
                    ) : field.options ? (
                      <Select
                        size="large"
                        showSearch
                        placeholder={`Select ${field.placeholder}`}
                        options={field.options}
                      />
                    ) : (
                      <Input size="large" placeholder={field.placeholder} />
                    )}
                  </Form.Item>
                </Col>
              ))}
              <Col className="text-center" span={24}>
                <Button
                  id="applyPersonalInfo"
                  size="large"
                  type="primary"
                  onClick={handleApply}
                  className="me-2"
                >
                  Apply
                </Button>
                <Button size="large" type="default" onClick={handleClear}>
                  {isEdit ? RESET : CLEAR}
                </Button>
              </Col>
            </Row>
          </Form>
        }
        overlayClassName="user_details_header_personal_info_popover"
        open={popoverVisible}
        arrow={false}
        onOpenChange={handleVisibleChange}
      >
        <Button
          size="large"
          type="text"
          className="user_details_header_personal_info_enter_address_btn"
          onClick={() => setPopoverVisible(!popoverVisible)}
        >
          {personalInfoLabel}
          <DownOutlined className="ms-auto ms-md-3" />
        </Button>
      </Popover>
    </div>
  );
};

type UserDetailRowProps = {
  first?: boolean;
  name?: string | string[] | undefined;
  label: string;
  Icon: ComponentType<{ className: string }>;
  textContent?: string;
  customContent?: ReactNode;
  last?: boolean;
  isCreate?: boolean;
  isEdit?: boolean;
  rules?: Rule[];
  mask?: string;
  options?: SelectProps["options"] | undefined;
  disabled?: boolean;
  selectMode?: "multiple" | "tags";
};

const UserDetailsRow: FC<UserDetailRowProps> = ({
  name,
  label,
  textContent,
  customContent,
  Icon,
  first,
  last,
  isCreate,
  isEdit,
  rules,
  mask,
  options,
  disabled,
  selectMode,
}) => {
  return (
    <Col span={24}>
      <Row
        align="middle"
        className={`user_details_content ${
          first ? "border-top border-bottom" : last ? "" : "border-bottom"
        }`}
      >
        <Col xs={24} md={7} className="user_details_content_icon_wrapper">
          <Icon className="user_details_content_icon_wrapper_icon" />
          <Text className="user_details_content_icon_wrapper_icon_name">
            {label}
          </Text>
        </Col>
        <Col xs={24} md={17} className="user_details_content_info">
          {isCreate || isEdit ? (
            <Form.Item
              name={name}
              initialValue={
                isEdit
                  ? selectMode === "multiple"
                    ? textContent
                        ?.split(",")
                        .map((s: string) => s.trim())
                        .filter((s: string) => s !== "")
                    : textContent
                  : undefined
              }
              rules={rules}
            >
              {mask ? (
                <MaskedInput size="large" mask={mask} />
              ) : options ? (
                <Select
                  size="large"
                  allowClear
                  showSearch
                  placeholder={`Select ${label}`}
                  options={options}
                  disabled={disabled}
                  mode={selectMode}
                />
              ) : (
                <Input size="large" placeholder={`Enter ${label}`} />
              )}
            </Form.Item>
          ) : customContent ? (
            customContent
          ) : (
            <Text className="user_details_content_info_text">
              {textContent || "-"}
            </Text>
          )}
        </Col>
      </Row>
    </Col>
  );
};

type SectionTitleProps = {
  title: string;
};
const SectionTitle: FC<SectionTitleProps> = ({ title }) => {
  return (
    <Col span={24}>
      <Row className="user_details_title_wrapper">
        <Col xs={18} md={12}>
          <Title level={4} className="section-badge-title mb-0">
            {title}
          </Title>
        </Col>
      </Row>
    </Col>
  );
};

type EmailTabProps = {
  isEdit: boolean;
  isCreate: boolean;
  authEmail?: string;
};

const EmailTab: FC<EmailTabProps> = ({
  isEdit,
  isCreate,
  authEmail,
}: EmailTabProps) => {
  return (
    <Row>
      <Col span={24}>
        <p className="user_details_auth_method_description">
          Add an e-mail to allow the user to receive a one-time use code via
          email for authentication and self-service password reset.
        </p>
      </Col>
      <Col span={24}>
        <Form.Item
          className="w-100"
          name="authEmail"
          initialValue={authEmail}
          rules={[
            {
              type: "email",
              message: "Please enter a valid Email",
            },
          ]}
        >
          <Input
            className="w-100"
            size="large"
            placeholder="Enter Email"
            readOnly={!isEdit && !isCreate}
          />
        </Form.Item>
      </Col>
    </Row>
  );
};

type PhoneTabProps = {
  isEdit: boolean;
  isCreate: boolean;
  authPhone?: string;
};
const PhoneTab: FC<PhoneTabProps> = ({
  isEdit,
  isCreate,
  authPhone,
}: PhoneTabProps) => {
  const { data: countryCodes } = useGetCountryCodesQuery(undefined);
  const countryCodeOptions = useMemo<SelectProps["options"]>(
    () =>
      countryCodes?.data.map((country) => ({
        label: `${country.name} (${country.dialCode})`,
        value: country.dialCode,
      })),
    [countryCodes]
  );

  return (
    <Row gutter={[10, 10]} className="user_details_auth_method w-100">
      <Col span={24}>
        <Paragraph className="user_details_auth_method_description">
          Add an number to allow the user to recieve one-time use code via
          number to use authentication and self service password reset
        </Paragraph>
      </Col>
      <Col span={24}>
        <Form.Item
          className="w-100"
          name="authPhone"
          initialValue={authPhone}
          rules={[
            {
              pattern: authPhonePatternRegex,
              message: authPhonePatternMesssage,
            },
          ]}
        >
          <Input
            addonBefore={
              isCreate ? (
                <Form.Item name="authPhoneCountryCode">
                  <Select
                    size="large"
                    showSearch
                    popupMatchSelectWidth={300}
                    placeholder="Select Country Code"
                    options={countryCodeOptions}
                    disabled={!isCreate}
                  />
                </Form.Item>
              ) : undefined
            }
            size="large"
            placeholder={
              isCreate
                ? "Enter Mobile Number"
                : "Enter Mobile Number with Country Code"
            }
            readOnly={!isEdit && !isCreate}
            autoComplete="off"
          />
        </Form.Item>
      </Col>
    </Row>
  );
};

const authMethodTabs = (
  isEdit: boolean,
  isCreate: boolean,
  userData?: UserData
) => {
  return [
    {
      key: AuthMethod.Email,
      label: AuthMethod.Email,
      children: (
        <EmailTab
          isEdit={isEdit}
          isCreate={isCreate}
          authEmail={userData?.authEmail}
        />
      ),
    },
    {
      key: AuthMethod.Phone,
      label: AuthMethod.Phone,
      children: (
        <PhoneTab
          isEdit={isEdit}
          isCreate={isCreate}
          authPhone={userData?.authPhone}
        />
      ),
    },
  ];
};

type UserNameFieldProps = {
  text?: string;
  isCreate: boolean;
  personalInfo: PersonalInfoForm;
  userForm: FormInstance<UserDetailsFormData>;
};
const UserNameField: FC<UserNameFieldProps> = ({
  text,
  isCreate,
  personalInfo,
  userForm,
}: UserNameFieldProps) => {
  const { data: domains } = useGetDomainsQuery(undefined, { skip: !isCreate });
  const [checked, setIsChecked] = useState(true);
  const [issuerAssignedId, setIssuerAssignedId] = useState<string>();
  const [issuer, setIssuer] = useState<string>();
  const { data: checkUserNameData, isFetching } = useCheckUserNameExistQuery(
    [issuerAssignedId, issuer],
    {
      skip: !issuerAssignedId || !issuer,
    }
  );
  const isUserNameExists =
    issuerAssignedId && issuer && checkUserNameData?.data;

  const firstName = checked ? personalInfo.firstName?.[0] : undefined;
  const lastName = checked ? personalInfo.lastName : undefined;
  const middleName = checked
    ? personalInfo.additionalData?.MiddleName?.[0]
    : undefined;

  const userName =
    firstName && lastName ? `${firstName}${lastName}` : undefined;
  const userNameWithMiddleName =
    firstName && middleName && lastName
      ? `${firstName}${middleName}${lastName}`
      : userName;

  const [isReadOnly, setIsReadOnly] = useState(true);

  const handleCheckboxChange = useCallback(
    (checked: boolean) => {
      setIsChecked(checked);
      setIsReadOnly(checked);
      setIssuerAssignedId(
        userName || userNameWithMiddleName ? issuerAssignedId : undefined
      );
    },
    [issuerAssignedId, userName, userNameWithMiddleName]
  );

  useEffect(() => {
    userName && setIssuerAssignedId(userName);

    userName
      ? userForm.setFieldValue(
          ["identity", "issuerAssignedId"],
          RemoveSpaceFromString(userName.toLowerCase())
        )
      : checked &&
        userForm.setFieldValue(["identity", "issuerAssignedId"], undefined);
  }, [checked, userForm, userName]);

  useEffect(() => {
    const nameWithMiddleName = isUserNameExists
      ? userNameWithMiddleName
      : undefined;

    nameWithMiddleName && setIssuerAssignedId(nameWithMiddleName);

    nameWithMiddleName &&
      userForm.setFieldValue(
        ["identity", "issuerAssignedId"],
        RemoveSpaceFromString(nameWithMiddleName.toLowerCase())
      );
  }, [isUserNameExists, userForm, userNameWithMiddleName]);

  return (
    <Row align="middle" className="w-100">
      <Col className="user_details_content_info w-100">
        {isCreate ? (
          <Row
            align="middle"
            gutter={[10, 10]}
            className="user_details_content_info_text w-100"
          >
            <Col xs={24} sm={10}>
              <Form.Item
                name={["identity", "issuerAssignedId"]}
                rules={[
                  {
                    required: true,
                    message: "UserName is required",
                  },
                  {
                    pattern: spacePatternRegex,
                    message: "Spaces are not allowed",
                  },
                  {
                    pattern: namePatternRegex,
                    message: namePatternMesssage,
                  },
                ]}
                validateStatus={
                  issuerAssignedId
                    ? isUserNameExists
                      ? "error"
                      : "success"
                    : undefined
                }
                help={
                  issuerAssignedId
                    ? isUserNameExists && "Username already exists"
                    : undefined
                }
              >
                <Input
                  type="text"
                  autoComplete="off"
                  placeholder="Enter UserName"
                  size="large"
                  onBlur={(e) => setIssuerAssignedId(e.target.value)}
                  readOnly={isReadOnly}
                  suffix={
                    isFetching && issuerAssignedId && issuer ? (
                      <LoadingOutlined />
                    ) : issuerAssignedId && issuer ? (
                      isUserNameExists ? (
                        <CloseCircleFilled style={{ color: "red" }} />
                      ) : (
                        <CheckCircleFilled style={{ color: "#50844b" }} />
                      )
                    ) : undefined
                  }
                />
              </Form.Item>
            </Col>
            <Col xs={4} sm={2} className="text-center">
              <span className="user_details_content_info_text_at">@</span>
            </Col>
            <Col xs={20} sm={12}>
              <Form.Item
                name={["identity", "issuer"]}
                rules={[
                  {
                    required: true,
                    message: "Domain is required",
                  },
                ]}
              >
                <Select
                  size="large"
                  allowClear
                  placeholder="Select Domain"
                  onChange={(val) => setIssuer(val)}
                >
                  {domains?.data?.map((item: string) => (
                    <Select.Option key={item} value={item}>
                      {item}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={24}>
              <Checkbox
                defaultChecked
                onChange={(e) => handleCheckboxChange(e.target.checked)}
              >
                Auto-generate username
              </Checkbox>
            </Col>
            <Col span={24}>
              <Form.Item
                name="isSendEmail"
                initialValue={true}
                valuePropName="checked"
              >
                <Checkbox defaultChecked>Send onboarding e-mail</Checkbox>
              </Form.Item>
            </Col>
          </Row>
        ) : (
          <Text className="user_details_content_info_text">{text}</Text>
        )}
      </Col>
    </Row>
  );
};

type CommentRowProps = {
  name?: string | string[] | undefined;
  label: string;
  textContent?: string;
  isCreate?: boolean;
  isEdit?: boolean;
  rules?: Rule[];
};

const CommentRow: FC<CommentRowProps> = ({
  name,
  label,
  textContent,
  isCreate,
  isEdit,
  rules,
}) => {
  return (
    <Col span={24}>
      <Row className="user_details_content border-top">
        <Col xs={24} className="user_details_content_icon_wrapper">
          <Text className="user_details_content_icon_wrapper_icon_name">
            {label}
          </Text>
        </Col>
        <Col xs={24} className="user_details_content_info">
          {isCreate || isEdit ? (
            <Form.Item
              name={name}
              initialValue={isEdit ? textContent : undefined}
              rules={rules}
            >
              <TextArea size="large" rows={4} placeholder={`Enter ${label}`} />
            </Form.Item>
          ) : (
            <Text className="user_details_content_info_text">
              {textContent || "-"}
            </Text>
          )}
        </Col>
      </Row>
    </Col>
  );
};

interface PasswordFieldProps {
  userForm: FormInstance<UserDetailsFormData>;
}

const PasswordField: FC<PasswordFieldProps> = ({ userForm }) => {
  const [isReadOnly, setIsReadOnly] = useState(true);
  const pwdRef = useRef<any>(null);

  const handleCheckboxChange = useCallback(
    (checked: boolean) => {
      const password = checked ? randomPasswordGenerator() : undefined;
      setIsReadOnly(checked);
      userForm.setFieldValue(["passwordProfile", "password"], password);
    },
    [userForm]
  );

  const copyToClipboard = () => {
    const copyText = pwdRef.current?.input?.value;

    if (copyText) {
      if (copy(copyText)) {
        message.success("Copied!");
      } else {
        message.error("Copy to Clipboard failed");
      }
    }
  };
  return (
    <Row className="w-100" align="middle" gutter={[10, 10]}>
      <Col span={23}>
        <Form.Item
          name={["passwordProfile", "password"]}
          initialValue={randomPasswordGenerator()}
          rules={[
            {
              required: true,
              message: "Password is required",
            },
            {
              pattern: passwordPatternRegex,
              message:
                "Password must have a minimum of 8 characters, incorporating elements from at least three of the following categories: Lowercase letters, Uppercase letters, Numbers, and Symbols.",
              validateTrigger: ["onBlur", "onClick"],
            },
          ]}
        >
          <Input.Password
            readOnly={isReadOnly}
            size="large"
            placeholder="Enter Password"
            ref={pwdRef}
          />
        </Form.Item>
      </Col>
      <Col
        onClick={copyToClipboard}
        className="text-start"
        style={{ cursor: "pointer" }}
        span={1}
      >
        <CopyOutlined title="Copy to clipboard" />
      </Col>
      <Col span={24}>
        <Checkbox
          defaultChecked
          onChange={(e) => handleCheckboxChange(e.target.checked)}
        >
          Auto-generate password
        </Checkbox>
      </Col>
    </Row>
  );
};

type UserDetailDeleteRowProps = {
  userId: string;
  refreshUserList: () => void;
};

const UserDetailDeleteRow: FC<UserDetailDeleteRowProps> = ({
  userId,
  refreshUserList,
}) => {
  const [confirmDelete, setConfirmDelete] = useState(false);

  const handleDeleteUser = () => {
    setConfirmDelete(true);
  };

  const handleDiscardDelete = () => {
    setConfirmDelete(false);
  };

  const [deleteUser, { isLoading }] = useDeleteUserMutation();

  const handleConfirmDelete = () => {
    try {
      deleteUser(userId)
        .unwrap()
        .then((res) => {
          if (res.success) {
            message.success("User deleted successfully!");
            refreshUserList();
          } else {
            message.error(res.message);
          }
        })
        .catch((error) => {
          message.error(error.data.message || error.data.title);
        });
    } catch (error) {
      console.error("An unexpected error occurred:", error);
    }
  };
  return (
    <Row
      align="middle"
      justify="center"
      className="user_details_delete_section"
    >
      <Col xs={24} md={confirmDelete ? 14 : 20}>
        <Paragraph className="mb-0">
          {confirmDelete ? (
            <span>
              Are you sure you want to delete this user? Please press{" "}
              <strong>Confirm Delete</strong> to Proceed.
            </span>
          ) : (
            <span>
              Please be aware that after deletion, all providers under this user
              will need to be associated with a new user and primary group name.
            </span>
          )}
        </Paragraph>
      </Col>
      {!confirmDelete ? (
        <Col xs={24} md={4} className="text-end">
          <Button
            size="large"
            className="delete-button"
            onClick={handleDeleteUser}
          >
            Delete User
          </Button>
        </Col>
      ) : (
        <>
          <Col xs={12} md={5} className="text-center">
            <Button
              size="large"
              className="discard-delete-button"
              onClick={handleDiscardDelete}
            >
              Discard Delete
            </Button>
          </Col>
          <Col xs={12} md={5} className="text-end">
            <Button
              size="large"
              className="delete-button"
              onClick={handleConfirmDelete}
              loading={isLoading}
            >
              Confirm Delete
            </Button>
          </Col>
        </>
      )}
    </Row>
  );
};

type PictureInputProps = {
  value?: string;
  onChange?: (value: string) => void;
};

const PictureInput: FC<PictureInputProps> = ({ value, onChange }) => {
  const beforeUpload = async (file: RcFile): Promise<boolean> => {
    try {
      const imageUrl = await handleImageUpload(file);
      onChange?.(imageUrl);
    } catch (error) {
      console.error("File read error:", error);
    }
    return false;
  };

  return (
    <Upload
      listType="picture-card"
      className="user_details_header_upload"
      showUploadList={false}
      beforeUpload={beforeUpload}
    >
      {value ? (
        <Avatar
          shape="square"
          size="large"
          src={value}
          className="user_details_header_image_wrapper_image"
        ></Avatar>
      ) : (
        <OutBoxIcon className="user_details_header_image_wrapper_icon" />
      )}
    </Upload>
  );
};

type EmailFieldProps = {
  name: string;
  userData?: UserData;
  isEdit: boolean;
  isCreate: boolean;
};

const EmailField: FC<EmailFieldProps> = ({
  name,
  userData,
  isEdit,
  isCreate,
}) => {
  const [email, setEmail] = useState<string>();

  let parameters: [email: string | undefined, userId?: string | undefined] = [
    email,
  ];

  if (isEdit && userData?.key) {
    parameters = [email, userData?.key];
  }

  const { data: checkEmailData, isLoading: checkingEmail } =
    useCheckEmailExistQuery(parameters, {
      skip: !email,
    });

  const isEmailExists = email && checkEmailData?.data;

  return (
    <Form.Item
      name={name}
      initialValue={!isEdit ? userData?.email : undefined}
      rules={[
        {
          required: true,
          type: "email",
          message: "Please enter a valid Email",
        },
      ]}
      validateStatus={email ? (isEmailExists ? "error" : "success") : undefined}
      help={email ? isEmailExists && "Email already exists" : undefined}
    >
      {isCreate || isEdit ? (
        <Input
          size="large"
          placeholder={`Enter Email`}
          onBlur={(e) => setEmail(e.target.value)}
          suffix={
            checkingEmail ? (
              <LoadingOutlined />
            ) : email ? (
              isEmailExists ? (
                <CloseCircleFilled style={{ color: "red" }} />
              ) : (
                <CheckCircleFilled style={{ color: "#50844b" }} />
              )
            ) : undefined
          }
        />
      ) : (
        <Text className="user_details_content_info_text">
          {userData?.email}
        </Text>
      )}
    </Form.Item>
  );
};

type DisableUserProps = {
  userData?: UserData;
  isEdit: boolean;
};

const DisableUser: FC<DisableUserProps> = ({
  userData,
  isEdit,
}: DisableUserProps) => {
  const { isAdmin } = useRolesPermissionsAccess();
  const [disableUserForm] = Form.useForm();
  const [isAccountEnabled, setIsAccountEnabled] = useState(
    userData?.accountEnabled
  );
  const [accountBlockedAt, setAccountBlockedAt] = useState(
    userData?.accountBlockedAt
  );
  const [updateUserAccountStatusApi, { isLoading: updateUserLoading }] =
    useUpdateUserAccountStatusMutation();

  const handleSave = (accountEnabled: boolean) => {
    disableUserForm
      .validateFields()
      .then((values) => {
        const updatedUserRequest = {
          userId: userData?.key,
          accountEnabled: accountEnabled,
          reason: values.reason,
        };

        updateUserAccountStatusApi(updatedUserRequest)
          .unwrap()
          .then((res) => {
            if (res.success) {
              message.success("User account status updated successfully");
              setIsAccountEnabled(accountEnabled);
              setAccountBlockedAt(undefined);
              disableUserForm.resetFields();
            } else {
              message.error(res.message);
            }
          })
          .catch((error) =>
            message.error(error.data.message || error.data.title)
          );
      })
      .catch((info) => {
        console.error("User Update Account Status Validate Failed:", info);
      });
  };

  return (
    <Row className="user_details_secure">
      <Col xs={24}>
        <Row className="user_details_content" gutter={[0, 12]}>
          <Col span={24} className="user_details_content_block_user">
            {!isAccountEnabled ? (
              <CloseCircleFilled
                className="user_details_content_block_user_icon"
                style={{ color: "red" }}
              />
            ) : (
              <CheckCircleFilled
                className="user_details_content_block_user_icon"
                style={{ color: "#50844b" }}
              />
            )}
            <Title level={4} className="mb-0 ms-2">
              {!isAccountEnabled
                ? "This user is disabled"
                : "This user is enabled"}
            </Title>
          </Col>
          {isAdmin && isEdit && (
            <>
              <Col>
                <Text className="mb-0 user_details_content_block_user_description">
                  {!isAccountEnabled
                    ? `Account has been blocked${
                        accountBlockedAt ? " on " : ""
                      }${
                        accountBlockedAt
                          ? FormatDisplayDateTime(accountBlockedAt, true)
                          : ""
                      }`
                    : "Disable this user from login by Blocking User Account (can be unblocked any time)"}
                </Text>
              </Col>
              <Col className="w-100">
                <Form form={disableUserForm}>
                  <Row gutter={[{ sm: 10 }, 10]}>
                    <Col xs={24} sm={18} md={18}>
                      <Form.Item
                        name="reason"
                        rules={[
                          {
                            required: true,
                            message: "Reason is required",
                          },
                        ]}
                      >
                        <Input
                          className="w-100"
                          size="large"
                          placeholder={
                            !isAccountEnabled
                              ? "Reason for unblocking..."
                              : "Add reason code to block user from login"
                          }
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={6} md={6}>
                      {!isAccountEnabled ? (
                        <Button
                          size="large"
                          className="w-100"
                          type="primary"
                          onClick={() => handleSave(true)}
                          loading={updateUserLoading}
                        >
                          Unblock
                        </Button>
                      ) : (
                        <Button
                          size="large"
                          className="w-100 delete-button"
                          onClick={() => handleSave(false)}
                          loading={updateUserLoading}
                        >
                          Block User
                        </Button>
                      )}
                    </Col>
                  </Row>
                </Form>
              </Col>
            </>
          )}
        </Row>
      </Col>
    </Row>
  );
};

export default UserDetails;
