import { Spin } from "antd";
import { FC } from "react";
import { LoadingOutlined } from "@ant-design/icons";
import { Navigate, Outlet } from "react-router";
import { useGetUserRolesAndPermissionsQuery } from "../../../services/roleGroups";
import { PageRoutesConstant } from "../../router/components/PageRoutes";
import { useIsAuthenticated } from "@azure/msal-react";

type RoleAccessProps = {
  userRoles: string[];
};

const RoleAccess: FC<RoleAccessProps> = ({ userRoles }) => {
  const isAuthenticated = useIsAuthenticated();
  const { data, isLoading } = useGetUserRolesAndPermissionsQuery(undefined, {
    skip: !isAuthenticated,
  });

  if (isLoading) {
    return (
      <Spin
        spinning={isLoading}
        size="large"
        indicator={<LoadingOutlined />}
        tip="Loading..."
      />
    );
  }

  return userRoles?.some((role) => data?.data?.roles?.includes(role)) ? (
    <Outlet />
  ) : (
    <Navigate to={PageRoutesConstant.Page.AccessDenied.path} replace />
  );
};

export default RoleAccess;
