import React, { FC, createContext } from "react";
import { InitialTheme } from "../theme/InitialTheme";
import { InitialContent } from "../content/InitialContent";
import { Theme } from "../../interfaces/Theme";
import { Content } from "../../interfaces/Content";

interface IContext {
  theme: Theme;
  content: Content;
}

export const AppContext = createContext<IContext>({
  theme: InitialTheme,
  content: InitialContent,
});

export const AppProvider: FC<IContext & { children?: React.ReactNode }> = ({
  theme = InitialTheme,
  content = InitialContent,
  children,
}) => {
  const value = { theme, content };
  return <AppContext.Provider value={value}>{children}</AppContext.Provider>;
};
