import Icon from "@ant-design/icons";
import { CustomIconComponentProps } from "@ant-design/icons/lib/components/Icon";


const ClinicSvg = () => (
  <svg
    viewBox="-16 0 512 512"
    width="35px"
    height="35px"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="m240 426c5.519531 0 10-4.480469 10-10s-4.480469-10-10-10-10 4.480469-10 10 4.480469 10 10 10zm0 0" />
    <path d="m390 366h-60c-5.523438 0-10 4.476562-10 10v60c0 5.523438 4.476562 10 10 10h60c5.523438 0 10-4.476562 10-10v-60c0-5.523438-4.476562-10-10-10zm-10 60h-40v-40h40zm0 0" />
    <path d="m150 126h-60c-5.523438 0-10 4.476562-10 10v60c0 5.523438 4.476562 10 10 10h60c5.523438 0 10-4.476562 10-10v-60c0-5.523438-4.476562-10-10-10zm-10 60h-40v-40h40zm0 0" />
    <path d="m270 126h-60c-5.523438 0-10 4.476562-10 10v60c0 5.523438 4.476562 10 10 10h60c5.523438 0 10-4.476562 10-10v-60c0-5.523438-4.476562-10-10-10zm-10 60h-40v-40h40zm0 0" />
    <path d="m320 136v60c0 5.523438 4.476562 10 10 10h60c5.523438 0 10-4.476562 10-10v-60c0-5.523438-4.476562-10-10-10h-60c-5.523438 0-10 4.476562-10 10zm20 10h40v40h-40zm0 0" />
    <path d="m150 246h-60c-5.523438 0-10 4.476562-10 10v60c0 5.523438 4.476562 10 10 10h60c5.523438 0 10-4.476562 10-10v-60c0-5.523438-4.476562-10-10-10zm-10 60h-40v-40h40zm0 0" />
    <path d="m270 246h-60c-5.523438 0-10 4.476562-10 10v60c0 5.523438 4.476562 10 10 10h60c5.523438 0 10-4.476562 10-10v-60c0-5.523438-4.476562-10-10-10zm-10 60h-40v-40h40zm0 0" />
    <path d="m390 246h-60c-5.523438 0-10 4.476562-10 10v60c0 5.523438 4.476562 10 10 10h60c5.523438 0 10-4.476562 10-10v-60c0-5.523438-4.476562-10-10-10zm-10 60h-40v-40h40zm0 0" />
    <path d="m150 366h-60c-5.523438 0-10 4.476562-10 10v60c0 5.523438 4.476562 10 10 10h60c5.523438 0 10-4.476562 10-10v-60c0-5.523438-4.476562-10-10-10zm-10 60h-40v-40h40zm0 0" />
    <path d="m370 0h-260c-5.523438 0-10 4.476562-10 10v56h-50c-5.523438 0-10 4.476562-10 10v416h-30c-5.523438 0-10 4.476562-10 10s4.476562 10 10 10h460c5.523438 0 10-4.476562 10-10s-4.476562-10-10-10h-30v-416c0-5.523438-4.476562-10-10-10h-50v-56c0-5.523438-4.476562-10-10-10zm-250 20h240v46h-240zm160 472h-30v-36c0-5.523438-4.476562-10-10-10s-10 4.476562-10 10v36h-30v-106h80zm140 0h-120v-116c0-5.523438-4.476562-10-10-10h-100c-5.523438 0-10 4.476562-10 10v116h-120v-406h360zm0 0" />
  </svg>
);

export const ClinicIcon = (props: Partial<CustomIconComponentProps>) => (
  <Icon component={ClinicSvg} {...props} />
);