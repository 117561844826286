import { msalInstance } from "./../../../index";
import {
  BrowserCacheLocation,
  Configuration,
  EndSessionRequest,
  LogLevel,
} from "@azure/msal-browser";

const host = window.location.hostname;

type AzureADConfig = {
  scopes: string[];
  clientId: string;
  authority: string;
  knownAuthorities: string[];
};

const aadConfigKey = "aadConfig";
const getCachedAzureADConfig = (): AzureADConfig | undefined => {
  const aadConfig = localStorage.getItem(aadConfigKey);
  if (!aadConfig) return undefined;
  return JSON.parse(aadConfig) as AzureADConfig;
};
const setCachedAzureADConfig = (config: AzureADConfig) => {
  localStorage.setItem(aadConfigKey, JSON.stringify(config));
};
const deleteCachedAzureADConfig = () => {
  localStorage.removeItem(aadConfigKey);
};
export const getAndDeleteCachedAzureADConfig = ():
  | AzureADConfig
  | undefined => {
  const aadConfig = getCachedAzureADConfig();
  deleteCachedAzureADConfig();
  return aadConfig;
};

const fetchAzureADConfig = () =>
  fetch(`/config/${host}.json`, {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  }).then((res) => res.json());

export const fetchAndCacheAzureADConfig = async () =>
  fetchAzureADConfig().then((res) => {
    setCachedAzureADConfig(res);
    return res;
  });

export const msalConfig = () => {
  const { clientId, authority, knownAuthorities } =
    getCachedAzureADConfig() ?? {};
  if (!clientId) throw new Error("clientID is required");

  const config: Configuration = {
    auth: {
      clientId: clientId, //App ID of your application.
      authority: authority, //URI of the tenant to authenticate and authorize with. Usually takes the form of https://{uri}/{tenantid} e.g. "common" or your tenantId (guid)
      knownAuthorities: knownAuthorities, //An array of URIs that are known to be valid. Used in B2C scenarios.
      redirectUri: window.location.origin, //URI where the authorization code response is sent back to. Whatever location is specified here must have the MSAL library available to handle the response.
      postLogoutRedirectUri: window.location.origin, //URI that is redirected to after a logout() call is made.
      navigateToLoginRequestUrl: false, //If true, will navigate back to the original request location before processing the authorization code response. If the redirectUri is the same as the original request location, this flag should be set to false.
    },
    cache: {
      cacheLocation: BrowserCacheLocation.SessionStorage, //Location of token cache in browser.
      storeAuthStateInCookie: false, //If true, stores cache items in cookies as well as browser cache. Should be set to true for use cases using IE.
      secureCookies: false, //If true and storeAuthStateInCookies is also enabled, MSAL adds the Secure flag to the browser cookie so it can only be sent over HTTPS.
    },
    system: {
      //Config object for logger.
      loggerOptions: {
        //Callback function which handles the logging of MSAL statements.
        loggerCallback: (
          level: LogLevel,
          message: string,
          containsPii: boolean
        ): void => {
          if (containsPii) {
            return;
          }
          switch (level) {
            case LogLevel.Error:
              //console.error(message);
              return;
            case LogLevel.Info:
              //console.info(message);
              return;
            case LogLevel.Verbose:
              //console.debug(message);
              return;
            case LogLevel.Warning:
              //console.warn(message);
              return;
          }
        },
        piiLoggingEnabled: false, //If true, personally identifiable information (PII) is included in logs.
      },
    },
    telemetry: {
      application: {
        appName: "COIPA", //Unique string name of an application
        appVersion: "", //Version of the application using MSAL
      },
    },
  };
  return config;
};

export const getCachedScopes = () => {
  return getCachedAzureADConfig()?.scopes ?? [];
};

type TokenDecoded = {
  aud: string;
  exp: number;
  iss: string;
  tid: string;
};

const decodeToken = (token: string): TokenDecoded | undefined => {
  try {
    return token ? JSON.parse(atob(token.split(".")[1])) : undefined;
  } catch (error) {
    console.warn(error);
    return undefined;
  }
};

const loginRequest = () => {
  return {
    scopes: getCachedAzureADConfig()?.scopes ?? [],
  };
};

export const getAuthToken = async () => {
  const account = msalInstance.getActiveAccount();
  const login = loginRequest();
  const { clientId, authority } = getCachedAzureADConfig() ?? {};

  if (!account) {
    // throw Error(
    //   "No active account! Verify a user has been signed in and setActiveAccount has been called."
    // );
    return null;
  }
  try {
    const response = await msalInstance.acquireTokenSilent({
      account,
      ...login,
    });
    const decodedJwt = decodeToken(response.idToken);
    const expired = decodedJwt && Date.now() >= decodedJwt.exp * 1000;
    if (expired === undefined || expired) {
      localStorage.clear();
      const logOutRequest: EndSessionRequest = {
        authority: authority,
        account: account,
        postLogoutRedirectUri: "/",
      };
      msalInstance.logoutRedirect(logOutRequest);
      msalInstance
        .loginRedirect({
          ...login,
          redirectStartPage: window.location.origin,
        })
        .catch((err) => {
          console.error(err);
        });
      msalInstance.handleRedirectPromise().catch((err) => {
        console.error(err);
      });
    } else {
      return `${response.idToken}&${clientId}`;
    }
  } catch (error) {
    // if (
    //   InteractionRequiredAuthError.isInteractionRequiredError(
    //     (error as any).errorCode
    //   )
    // )

    if ((error as any).name === "InteractionRequiredAuthError") {
      const response = await msalInstance.acquireTokenPopup(login);
      return `${response.idToken}&${clientId}`;
    }
  }
};
