import React from "react";
import { createRoot } from "react-dom/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import {
  fetchAndCacheAzureADConfig,
  msalConfig,
} from "./common/settings/config/AzureAuthenticationConfig";
import {
  AuthenticationResult,
  EventType,
  PublicClientApplication,
} from "@azure/msal-browser";
import { BrowserRouter } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.css";
import "antd/dist/reset.css";
import "./index.css";

export let msalInstance: PublicClientApplication;

const loadAzureConfig = async () => {
  await fetchAndCacheAzureADConfig();

  const configuration = msalConfig();
  msalInstance = new PublicClientApplication(configuration);
  await msalInstance.initialize();
  
  const accounts = msalInstance.getAllAccounts();

  if (accounts.length > 0) {
    msalInstance.setActiveAccount(accounts[0]);
  }

  msalInstance.addEventCallback((event) => {
    if (event.eventType === EventType.LOGIN_SUCCESS) {
      const { account } = event.payload as AuthenticationResult;
      if (account) {
        msalInstance.setActiveAccount(account);
      }
    }

    if (event.eventType === EventType.LOGIN_FAILURE) {
      console.log(EventType.LOGIN_FAILURE, JSON.stringify(event));
    }
  });

  return msalInstance;
};

const rootElement = document.getElementById("root")!;
const root = createRoot(rootElement);

loadAzureConfig().then((msalInstance) => {
  root.render(
      <React.StrictMode>
      <BrowserRouter>
        <App msalInstance={msalInstance} />
      </BrowserRouter>
    </React.StrictMode>
  );
});

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
