import { FC } from "react";
import { Col, Row, Typography } from "antd";
import UseTheme from "../../common/settings/theme/UseTheme";
import useResponsive from "../../hooks/useResponsive";
import "./AccessDenied.style.scss";

const { Paragraph, Title } = Typography;

const AccessDenied: FC = () => {
  const { logo, mobileLogo } = UseTheme();
  const { isMobile } = useResponsive();

  return (
    <div className="access_denied">
      <Row>
        <Col span={24}>
          <div className="access_denied_wrapper">
            <Row justify="center">
              <Col xs={24} className="access_denied_img">
                <img src={isMobile ? mobileLogo : logo} alt="logo" />
              </Col>
              <Col xs={24} className="access_denied_title">
                <Title level={1} className="access_denied_title_text mb-0">
                  Access Denied
                </Title>
              </Col>
              <Col xs={24} md={13}>
                <Paragraph className="access_denied_text">
                  Please contact your administrator to get assigned the proper
                  permissions/role in order to continue.
                </Paragraph>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default AccessDenied;
