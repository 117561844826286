import { LoadingOutlined } from "@ant-design/icons";
import {
  Avatar,
  Button,
  Col,
  Form,
  Input,
  Row,
  Spin,
  Switch,
  Typography,
  Upload,
  message,
} from "antd";
import { MaskedInput } from "antd-mask-input";
import { Rule } from "antd/es/form";
import type { RcFile } from "antd/es/upload/interface";
import { ComponentType, FC, ReactNode, useMemo, useState } from "react";
import { useMsal } from "@azure/msal-react";
import {
  EmailIcon,
  TelephoneIcon,
  OutBoxIcon,
  ClinicIcon,
  LanguageIcon,
  CertificateIcon,
  UserIcon,
  IdCardIcon,
} from "../../../../assets/images/icons";
import { FormatDisplayDate } from "../../../utilities/format";
import {
  getInitials,
  handleImageUpload,
  mergeFormsAndNestAdditionalData,
  parseOnlyBase64ImageData,
} from "../../../utilities/helper";
import { phonePatternRegex } from "../../../utilities/validator";
import { ProfileData } from "../../../interfaces/ProfileData";
import {
  useGetUserSchemaExtensionIdQuery,
  useGetUserQuery,
  useUpdateProfileMutation,
} from "../../../../services/users";
import { Categories } from "../../../constants/Categories";
import "./UserProfile.style.scss";

const { Title, Paragraph, Text } = Typography;

interface UserProfileProps {
  closeUserProfileDetailModal: () => void;
}

const UserProfile: FC<UserProfileProps> = ({ closeUserProfileDetailModal }) => {
  const { accounts } = useMsal();
  const userId = accounts[0].idTokenClaims?.oid;

  const [userProfileForm] = Form.useForm();
  const [isEdit, setIsEdit] = useState<boolean>(false);

  const { data, isLoading, isFetching } = useGetUserQuery(userId, {
    refetchOnMountOrArgChange: true,
  });
  const profileData = data?.data;

  const [updateProfileApi, { isLoading: updateProfileLoading }] =
    useUpdateProfileMutation();

  const { data: userSchemaExtensionData } = useGetUserSchemaExtensionIdQuery(
    undefined,
    { skip: !isEdit }
  );
  const userSchemaExtensionId = useMemo(
    () => userSchemaExtensionData?.data || "",
    [userSchemaExtensionData]
  );

  const onUpdateUser = () => {
    userProfileForm.validateFields().then((values) => {
      const merged = mergeFormsAndNestAdditionalData(
        values,
        userSchemaExtensionId
      );

      const updatedUserData = {
        ...merged,
        userId,
        photo: parseOnlyBase64ImageData(values.photo),
        additionalData: {
          ...merged.additionalData,
          [userSchemaExtensionId]: {
            ...merged.additionalData[userSchemaExtensionId],
            Category: profileData?.category,
          },
        },
      };

      updateProfileApi(updatedUserData)
        .unwrap()
        .then(() => {
          message.success("User Updated Successfully");
          closeUserProfileDetailModal();
        })
        .catch((error) => {
          message.error(error.data.message);
        });
    });
  };

  return (
    <div className="user_profile">
      <Spin
        size="large"
        tip="Loading..."
        spinning={isLoading || isFetching}
        indicator={<LoadingOutlined />}
      >
        <Form form={userProfileForm} onFinish={onUpdateUser}>
          <Row>
            <Col span={24}>
              <UserProfileDetailsHeader
                profileData={profileData}
                setIsEdit={setIsEdit}
                isEdit={isEdit}
                isLoadingDataNecessaryForSubmit={updateProfileLoading}
              />
            </Col>
            <Col span={24}>
              <Row>
                <UserProfileDetailsRow
                  first={true}
                  name="primaryGroupName"
                  label="Primary Group Name"
                  Icon={ClinicIcon}
                  textContent={profileData?.primaryGroupName}
                />
                {profileData?.category === Categories.Provider && (
                  <UserProfileDetailsRow
                    label="Secondary Group Name"
                    name={["additionalData", "SecondaryGroupName"]}
                    Icon={ClinicIcon}
                    textContent={profileData?.secondaryGroupName}
                    isEdit={isEdit}
                  />
                )}
                <UserProfileDetailsRow
                  name="Email"
                  label="Official E-mail"
                  Icon={EmailIcon}
                  textContent={profileData?.email}
                  isEdit={isEdit}
                  rules={[
                    {
                      type: "email",
                      message: "Please enter a valid email",
                    },
                  ]}
                />
                <UserProfileDetailsRow
                  name="Phone"
                  label="Phone"
                  Icon={TelephoneIcon}
                  textContent={profileData?.phone}
                  isEdit={isEdit}
                  mask="(000)-000-0000"
                  rules={[
                    {
                      pattern: phonePatternRegex,
                      message: "Enter valid phone",
                      validateTrigger: "onClick",
                    },
                  ]}
                />
                <UserProfileDetailsRow
                  label="Spoken Language"
                  name={["additionalData", "SpokenLanguage"]}
                  Icon={LanguageIcon}
                  textContent={profileData?.spokenLanguage}
                  isEdit={isEdit}
                />
                {profileData?.category === Categories.Provider && (
                  <UserProfileDetailsRow
                    label="Credentials"
                    Icon={CertificateIcon}
                    name={["additionalData", "Credentials"]}
                    textContent={profileData?.credentials}
                  />
                )}
                {profileData?.category === Categories.Provider && (
                  <UserProfileDetailsRow
                    label="NPI"
                    Icon={IdCardIcon}
                    name={["additionalData", "NPI"]}
                    textContent={profileData?.npi}
                  />
                )}
              </Row>
            </Col>
            <Col span={24} className="user_profile_details">
              <Row className="user_profile_details_secure">
                <SectionTitle title="System Preferences" />
                <UserProfileDetailsRow
                  label="Username"
                  Icon={UserIcon}
                  customContent={profileData?.userName}
                />
              </Row>
            </Col>
            <Col className="user_profile_details_bottom">
              <Paragraph className="mb-0 user_profile_details_bottom_text">
                Some details, such as your name and job title, may be provided
                by your IT or credential department. If you want to update those
                details, contact them or your administrator
              </Paragraph>
            </Col>
          </Row>
        </Form>
      </Spin>
    </div>
  );
};

type SectionTitleProps = {
  title: string;
};
const SectionTitle: FC<SectionTitleProps> = ({ title }) => {
  return (
    <Col span={24}>
      <Row className="user_profile_details_title_wrapper">
        <Col xs={18} md={12}>
          <Title level={4} className="section-badge-title mb-0">
            {title}
          </Title>
        </Col>
      </Row>
    </Col>
  );
};

type UserProfileDetailsHeaderProps = {
  profileData?: ProfileData;
  setIsEdit: (val: boolean) => void;
  isEdit: boolean;
  isLoadingDataNecessaryForSubmit: boolean;
};

const UserProfileDetailsHeader: FC<UserProfileDetailsHeaderProps> = ({
  profileData,
  setIsEdit,
  isEdit,
  isLoadingDataNecessaryForSubmit,
}) => {
  const handleSwitchChange = (value: boolean) => {
    setIsEdit(value);
  };

  const onDiscardChanges = () => {
    setIsEdit(false);
  };
  return (
    <Row
      className="user_profile_details_header"
      align="middle"
      gutter={[12, 0]}
    >
      <Col
        xs={24}
        md={6}
        className="user_profile_details_header_image_wrapper text-center"
      >
        <>
          {isEdit ? (
            <Form.Item
              name="photo"
              initialValue={isEdit ? profileData?.imageUrl : undefined}
            >
              <PictureInput />
            </Form.Item>
          ) : (
            <Avatar
              shape="square"
              size="large"
              src={profileData?.imageUrl}
              className="user_profile_details_header_image_wrapper_image"
            >
              {getInitials(profileData?.name)}
            </Avatar>
          )}
        </>
        {profileData?.category === Categories.Provider &&
          profileData?.since && (
            <Text className="user_profile_details_header_image_wrapper_date w-100 text-center">
              Since {FormatDisplayDate(profileData.since)}
            </Text>
          )}
      </Col>
      <Col
        xs={24}
        md={12}
        className="user_profile_details_header_personal_info"
      >
        <Title
          level={2}
          className="mb-4 mt-0 user_profile_details_header_personal_info_name"
        >
          {profileData?.name}
        </Title>
      </Col>

      <Col md={6}>
        <Row gutter={[0, 12]} className="text-center">
          <Col span={24}>
            <Paragraph className="mb-0">
              Edit Mode
              <Switch
                checked={isEdit}
                className="ms-2"
                onChange={handleSwitchChange}
              />
            </Paragraph>
          </Col>
          <Col span={24}>
            <Button
              size="large"
              disabled={!isEdit}
              type="primary"
              className="w-100"
              htmlType="submit"
              loading={isLoadingDataNecessaryForSubmit}
            >
              Save Changes
            </Button>
          </Col>
          <Col span={24}>
            <Button
              size="large"
              disabled={!isEdit}
              type="default"
              htmlType="reset"
              onClick={onDiscardChanges}
              className="w-100"
            >
              Discard Changes
            </Button>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

type ClincDetailRowProps = {
  first?: boolean;
  name?: string | string[] | undefined;
  label: string;
  Icon: ComponentType<{ className: string }>;
  textContent?: string;
  customContent?: ReactNode;
  isEdit?: boolean;
  mask?: string;
  rules?: Rule[];
};

const UserProfileDetailsRow: FC<ClincDetailRowProps> = ({
  name,
  label,
  textContent,
  customContent,
  Icon,
  first,
  isEdit,
  mask,
  rules,
}) => {
  return (
    <Col span={24}>
      <Row
        align="middle"
        className={`user_profile_details_content ${
          first ? "border-top border-bottom" : "border-bottom"
        }`}
      >
        <Col
          xs={24}
          md={7}
          className="user_profile_details_content_icon_wrapper"
        >
          <Icon className="user_profile_details_content_icon_wrapper_icon" />
          <Text className="user_profile_details_content_icon_wrapper_icon_name">
            {label}
          </Text>
        </Col>
        <Col xs={24} md={17} className="user_profile_details_content_info">
          {isEdit ? (
            <Form.Item
              name={name}
              initialValue={isEdit ? textContent : undefined}
              rules={rules}
            >
              {mask ? (
                <MaskedInput size="large" mask={mask} />
              ) : (
                <Input size="large" placeholder={`Enter ${label}`} />
              )}
            </Form.Item>
          ) : customContent ? (
            customContent
          ) : (
            <Text className="user_profile_details_content_info_text">
              {textContent || "-"}
            </Text>
          )}
        </Col>
      </Row>
    </Col>
  );
};

type PictureInputProps = {
  value?: string;
  onChange?: (value: string) => void;
};

const PictureInput: FC<PictureInputProps> = ({ value, onChange }) => {
  const beforeUpload = async (file: RcFile): Promise<boolean> => {
    try {
      const imageUrl = await handleImageUpload(file);
      onChange?.(imageUrl);
    } catch (error) {
      console.error("File read error:", error);
    }
    return false;
  };

  return (
    <Upload
      listType="picture-card"
      className="avatar-uploader user_profile_details_header_upload"
      showUploadList={false}
      beforeUpload={beforeUpload}
    >
      {value ? (
        <Avatar
          shape="square"
          size="large"
          src={value}
          className="user_profile_details_header_image_wrapper_image"
        ></Avatar>
      ) : (
        <OutBoxIcon className="user_profile_details_header_image_wrapper_icon" />
      )}
    </Upload>
  );
};

export default UserProfile;
