import { Button, Col, Form, Input, Row, Typography, message } from "antd";
import { useForm } from "antd/es/form/Form";
import { FC } from "react";
import {
  useCreateGroupMutation,
  useUpdateGroupMutation,
} from "../../../../services/groups";
import { namePatternMesssage, namePatternRegex } from "../../../../common/utilities/validator";

type UserGroupDetailsProps = {
  closeUserGroupDetailModal: () => void;
  userGroupEditData?: any;
  refetchUserGroups: () => void;
};

const { Title } = Typography;

const UserGroupDetails: FC<UserGroupDetailsProps> = ({
  closeUserGroupDetailModal,
  userGroupEditData,
  refetchUserGroups,
}) => {
  const [userGroupForm] = useForm();

  const [createUserGroup, { isLoading: createLoading }] =
    useCreateGroupMutation();
  const [updateUserGroup, { isLoading: updateLoading }] =
    useUpdateGroupMutation();

  const handleCreate = () => {
    userGroupForm
      .validateFields()
      .then((values) => {
        createUserGroup({ ...values, securityEnabled: true, })
          .unwrap()
          .then((res) => {
            if (res?.success) {
              message.success("User Group created successfully");
              refetchUserGroups();
              closeUserGroupDetailModal();
            } else {
              message.error(res?.message);
            }
          })
          .catch((error) => {
            message.error(error.data.message);
          });
      })
      .catch(() => {
        console.error("Validation failed. Please check your input.");
      });
  };

  const handleEdit = () => {
    userGroupForm
      .validateFields()
      .then((values) => {
        updateUserGroup({ ...values, GroupId: userGroupEditData.id })
          .unwrap()
          .then((res) => {
            if (res?.success) {
              message.success("User Group updated successfully");
              refetchUserGroups();
              closeUserGroupDetailModal();
            } else {
              message.error(res?.message);
            }
          })
          .catch((error) => {
            message.error(error.data.message);
          });
      })
      .catch(() => {
        console.error("Validation failed. Please check your input.");
      });
  };

  return (
    <div>
      <Form
        form={userGroupForm}
        onFinish={userGroupEditData ? handleEdit : handleCreate}
      >
        <Title level={3}>Name</Title>
        <Form.Item
          name="displayName"
          rules={[
            {
              required: true,
              message: "Name is required!",
              validateTrigger: "onClick",
            },
            {
              max: 256,
              message: "The name should not exceed 256 characters",
            },
            {
              pattern: namePatternRegex,
              message: namePatternMesssage,
            },
          ]}
          initialValue={userGroupEditData?.name}
        >
          <Input size="large" placeholder="Enter Name" />
        </Form.Item>
        <Row gutter={[12, 12]} align="middle" justify="end">
          <Col>
            <Button
              size="large"
              type="primary"
              htmlType="submit"
              loading={userGroupEditData ? updateLoading : createLoading}
            >
              {userGroupEditData ? "Update" : "Create"}
            </Button>
          </Col>
          <Col>
            <Button
              size="large"
              type="default"
              onClick={closeUserGroupDetailModal}
            >
              Cancel
            </Button>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default UserGroupDetails;
