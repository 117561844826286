import { FC, useEffect, useMemo, useState } from "react";
import { Col, Row, Select, Typography } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { useDebounced } from "../../../hooks/useDebounced";
import { useSearchUsersNameQuery } from "../../../services/reports";
import "./ReportUsersNameSearch.style.scss";

const invalidCharacterRegex = /[!@#$%^&*(){}?/|]/;
const alphanumericWhitespaceRegex = /[a-zA-Z0-9\s]/g;

const { Title } = Typography;

type ReportUsersNameSearchProps = {
  handleSelectedValue: (val: string) => void;
};

const ReportUsersNameSearch: FC<ReportUsersNameSearchProps> = ({
  handleSelectedValue,
}) => {
  const [searchValue, setSearchValue] = useState<string>();
  const [searchedValue, setSearchedValue] = useState<string>();

  const searchReportsdebounced = useDebounced(200, setSearchValue);

  const { data, isFetching } = useSearchUsersNameQuery(searchValue, {
    skip: !searchValue,
  });

  const handleSearch = (newValue: string) => {
    const cleaned = newValue.trim();
    if (cleaned) {
      searchReportsdebounced(cleaned);
    }
  };

  const handleChange = (newValue: string) => {
    setSearchedValue(newValue);
    handleSelectedValue(newValue);
    const searchInput = document.getElementById("reports_users_name_search");
    if (!newValue && searchInput) {
      searchInput.blur();
    }
  };

  const renderOptions = !searchValue
    ? null
    : data?.data.map((item: string) => (
        <Select.Option key={item} value={item}>
          <div
            className="text-truncate"
            style={{
              color: "#818181",
              fontWeight: 400,
            }}
          >
            <BoldSearchMatch searchResult={item} searchValue={searchValue} />
          </div>
        </Select.Option>
      ));

  const handleRemoveFirstOption = () => {
    const selectElement = document.querySelector(".rndesktop") as HTMLElement;
    if (selectElement) {
      const firstOptionElement = selectElement.querySelector(
        ".ant-select-item-option"
      ) as HTMLOptionElement;
      if (
        firstOptionElement &&
        (firstOptionElement.value === "" ||
          firstOptionElement.value === undefined)
      ) {
        const hasSelectedClass = firstOptionElement.classList.contains(
          "ant-select-item-option-selected"
        );
        if (!hasSelectedClass) {
          firstOptionElement.style.display = "none";
        }
      }
    }
  };

  const notFoundContent = useMemo(() => {
    if (searchValue && !data?.data.length && !isFetching) {
      if (invalidCharacterRegex.test(searchValue)) {
        return `${searchValue.replace(
          alphanumericWhitespaceRegex,
          ""
        )} is not a valid character`;
      } else if (renderOptions?.length === 0) {
        return "No Results Found";
      } else {
        return "Loading...";
      }
    } else {
      if (isFetching) {
        return "Loading...";
      } else {
        return !isFetching
          ? "Enter more characters to narrow down results."
          : undefined;
      }
    }
  }, [searchValue, data?.data?.length, isFetching, renderOptions?.length]);

  useEffect(() => {
    if (searchValue?.trim() === "") {
      setSearchValue(undefined);
    }
  }, [searchValue]);

  useEffect(() => {
    handleRemoveFirstOption();
  }, [searchValue === undefined]);

  return (
    <Row className="reports_filter">
      <Col span={24}>
        <Title level={4} className="reports_filter_title">
          Name
        </Title>
        <Row>
          <Col span={24}>
            <Select
              id="reports_users_name_search"
              className={`reports_users_name_search ${
                searchedValue ? "has-accent-border" : ""
              }`}
              popupClassName="reports_users_name_search_item rndesktop"
              value={searchedValue}
              showSearch
              size="large"
              suffixIcon={<SearchOutlined />}
              allowClear={searchedValue ? true : false}
              placeholder="Search By Name"
              defaultActiveFirstOption={false}
              filterOption={false}
              onSearch={handleSearch}
              onChange={handleChange}
              onFocus={handleRemoveFirstOption}
              onBlur={() => !isFetching && setSearchValue(searchedValue)}
              optionFilterProp="children"
              notFoundContent={notFoundContent}
              dropdownRender={(menu) =>
                isFetching ? (
                  <div className="narrow_down">Loading...</div>
                ) : (
                  <div>
                    {menu}
                    {(!searchValue ||
                      !data?.data?.length ||
                      (data && data?.data?.length > 5)) &&
                      renderOptions?.length !== 0 && (
                        <div className="narrow_down">
                          Enter more characters to narrow down results.
                        </div>
                      )}
                  </div>
                )
              }
            >
              {searchValue !== "" &&
              searchValue !== null &&
              searchValue !== undefined &&
              data
                ? renderOptions
                : (searchValue === "" ||
                    searchValue === null ||
                    searchValue === undefined) && (
                    <Select.Option>{null}</Select.Option>
                  )}
            </Select>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

type BoldSearchMatchProps = {
  searchResult: string;
  searchValue: string;
};
const BoldSearchMatch: FC<BoldSearchMatchProps> = ({
  searchResult,
  searchValue,
}) => {
  const parts = useMemo(() => {
    if (!searchValue || searchValue.trim() === "") return null;
    const searchRegExp = new RegExp(`^(.*)(${searchValue})(.*)$`, "i");
    const matchResult = searchRegExp.exec(searchResult);
    if (!matchResult || matchResult.length < 4) return null;
    const [prefix, searchMatch, suffix] = matchResult.slice(1);
    return { prefix, searchMatch, suffix };
  }, [searchResult, searchValue]);

  if (!parts) return <>{searchResult}</>;

  const { prefix, searchMatch, suffix } = parts;

  return (
    <>
      {prefix}
      <span style={{ fontWeight: "bold" }}>{searchMatch}</span>
      {suffix}
    </>
  );
};

export default ReportUsersNameSearch;
